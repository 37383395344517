<div class="dc-subheader">
  <div class="dc-row" style="align-items: center; align-content: center; height: 100%;">
    <div class="dc-col md-2 sm-3 xs-3">
      <dc-button icon="plus" color="white" size="small" (click)="showCreateAppointmentSlot(true)"><span class="create-appointment-slot__button-content">{{ 'Add appointment slots' | translate }}</span></dc-button>
    </div>
    <div class="dc-col md-8 sm-6 xs-6 center">
      <div class="week-current position-relative">
        <div class="padding-horizontal" (click)="previous()">
          <div class="dc-icon arrow-left"></div>
        </div>
        <h2 *ngIf="mode === 'currentWeek' || mode === '3days'" class="dc-clickable" (click)="openWeekPicker()">
          <span>{{ startDate | amDateFormat:'DD.MM.YYYY.' }}</span>
          <span> – </span>
          <span>{{ endDate | amDateFormat:'DD.MM.YYYY.' }}</span>
        </h2>
        <h2 *ngIf="mode === 'today'" (click)="openWeekPicker()" class="dc-clickable">
          <span>{{ startDate | amDateFormat:'DD.MM.YYYY.' }}</span>
        </h2>
        <div class="padding-horizontal" (click)="next()">
          <div class="dc-icon arrow-right"></div>
        </div>
        <div>
          <dc-datepicker class="week-picker" [type]="'calendar'" [selectWeek]="mode !== 'today' && mode !== '3days'" [(ngModel)]="datepickerValue" (ngModelChange)="setWeek($event)" #weekPicker></dc-datepicker>
        </div>
      </div>
    </div>
    <div class="dc-col md-2 sm-3 xs-3">
      <dc-button class="justify-end-flex"  [color]="filterCounter > 0 ? 'primary' : 'white'" size="small" (click)="showScheduleFilterModal()">{{ 'Filters' | translate }} {{filterCounter !== 0 ? ': ' + filterCounter : ''}}</dc-button>
    </div>
  </div>
</div>

<div class="schedule-content" (swipeleft)="previous()" (swiperight)="next()">

  <div class="schedule-day" *ngFor="let day of days"
       [class.today]="day.today"
       [class.past]="day.past">
    <div class="day-header">
      <span class="add-slot dc-icon plus" (click)="showCreateAppointmentSlot(false, day.date)"></span>
      <span>{{ day.date | amDateFormat:'ddd DD.MM.' }}</span>
    </div>
    <div class="day-content" [perfectScrollbar]="{ suppressScrollX: true}">
      <div [dcLoading]="day.loading"></div>
      <div class="slot" 
           (click)="showAppointmentSlot(appointmentSlot)"
           *ngFor="let appointmentSlot of day.appointmentSlots"
           [class.free]="!appointmentSlot.appointment_id"
           [class.closed]="!appointmentSlot.open"
           [class.with-appointment]="!appointmentSlot.open && appointmentSlot.appointment">
        <div class="overlay" [style.background]="appointmentSlot.specialty.color"></div>
        <div class="specialty-color" [style.background]="appointmentSlot.specialty.color"></div>
        <div class="source">
          <span class="dc-icon indicator-telemedicine margin-right small" *ngIf="appointmentSlot?.type === 'telemedicine'"></span>
          <ng-container *ngIf="appointmentSlot.appointment">
            <span class="dc-icon no-patient margin-right small" *ngIf="appointmentSlot.appointment.no_show"></span>
            <span class="dc-icon indicator-{{ appointmentSlot.appointment.origin }}"></span>
          </ng-container>
        </div>
        <div class="time">
          <span>{{ appointmentSlot.starts_at | amDateFormat:'HH:mm' }}</span>
          <span> - </span>
          <span>{{ appointmentSlot.ends_at | amDateFormat:'HH:mm' }}</span>
        </div>
        <div class="patient" *ngIf="appointmentSlot.appointment"
             [title]="appointmentSlot.appointment?.patient?.first_name + ' ' + appointmentSlot.appointment?.patient?.last_name">
          <span>{{ appointmentSlot.appointment?.patient?.first_name }}&nbsp;{{ appointmentSlot.appointment?.patient?.last_name }}</span>
        </div>
        <div class="specialty margin-bottom m-small">
          <div *ngIf="appointmentSlot.doctor">
            <span>{{ appointmentSlot.doctor?.first_name }}</span>
            <span>&nbsp;</span>
            <span>{{ appointmentSlot.doctor?.last_name }}</span>
          </div>
          <div>{{ appointmentSlot.specialty.name }}</div>
        </div>
        <div class="service" *ngIf="appointmentSlot.appointment?.service">
          {{ appointmentSlot.appointment.service.name }}
        </div>
        <div class="telemedicine-action" *ngIf="appointmentSlot.appointment && appointmentSlot.type === 'telemedicine' && appointmentSlot.tele_show">
          <dc-button [size]="'small full'" [icon]="'video-full'" [disabled]="!appointmentSlot.tele_active" (click)="teleConnect($event, appointmentSlot)">{{'Connect' | translate}}</dc-button>
        </div>
        <div class="comment" *ngIf="appointmentSlot.appointment?.comment">
          <dc-button icon="notes" size="small" color="white" (click)="showAppointmentNote(appointmentSlot.appointment)">{{ 'Note' | translate }}</dc-button>
        </div>
      </div>
    </div>
  </div>

</div>

