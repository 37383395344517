<div class="dc-modal medium">
  <div class="dc-modal__header">
    <h2 class="dc-modal__title">{{ 'Telemedicine appointment' | translate }}</h2>
  </div>
  <div class="dc-modal__body">
    <div class="dc-row">
      <div class="dc-col">
        <label>{{appointmentSlot.starts_at | amDateFormat: 'DD.MM.YYYY.'}} | {{appointmentSlot.starts_at | amDateFormat: 'HH:mm'}}</label>
        <p>{{appointmentSlot.specialty.name}} | {{appointmentSlot.doctor?.title}} {{appointmentSlot.doctor?.first_name}} {{appointmentSlot.doctor?.last_name}}</p>
      </div>
      <hr>
    </div>
    <div class="dc-row">
      <div class="dc-col">
        <label>Pacijent</label>
        <p>{{appointmentSlot.appointment.patient.first_name}} {{appointmentSlot.appointment.patient.last_name}} {{appointmentSlot.appointment.patient?.parent_name ? '(' + appointmentSlot.appointment.patient?.parent_name +')' : ''}}</p>
      </div>
      <div class="dc-col md-6">
        <label>Datum rođenja</label>
        <p>{{appointmentSlot.appointment.patient.date_of_birth | amDateFormat: 'DD.MM.YYYY.'}}</p>
      </div>
      <div class="dc-col md-6">
        <label>Telefon 1</label>
        <p>{{appointmentSlot.appointment.patient.phone_1 ? appointmentSlot.appointment.patient.phone_1 : '-'}}</p>
      </div>
      <div class="dc-col md-6">
        <label>Email</label>
        <p>{{appointmentSlot.appointment.patient.email ? appointmentSlot.appointment.patient.email : '-'}}</p>
      </div>
      <div class="dc-col md-6">
        <label>Telefon 2</label>
        <p>{{appointmentSlot.appointment.patient.phone_2 ? appointmentSlot.appointment.patient.phone_2 : '-'}}</p>
      </div>
      <hr>
    </div>
    <div class="dc-row">
      <div class="dc-col">
        <span class="text-small dc-text-color__gray-medium">Započinjanje posete će kreirati posetu pacijenta i započeti video poziv.</span>
      </div>
    </div>
  </div>
  <div class="dc-modal__actions">
    <div class="dc-modal__action-left">
    </div>
    <div class="dc-modal__action-right">
      <dc-button color="outline" (click)="close()">{{'Cancel' | translate}}</dc-button>
      <dc-button (click)="startVisit()">{{'Start visit' | translate}}</dc-button>
    </div>
  </div>
</div>
