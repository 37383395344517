import { Component, OnInit, HostListener, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DoctorService } from '../../services/doctor.service';
import { SpecialtyService } from '../../services/specialty.service';
import { Doctor } from '../../classes/doctor';
import { Specialty } from '../../classes/specialty';
import { InvitationModalComponent } from '../../modals/invitation/invitation-modal.component';
import { Invitation } from '../../classes/invitation';
import { InvitationService } from '../../services/invitation.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DCToastService } from '@dc/ui/components/toast/toast.service';
import { ModalService } from 'libs/ui/src/public-api';
import { environment } from "../../../environments/environment";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'main [dc-doctor-details]',
  templateUrl: './doctor-details.component.html'
})
export class DoctorDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('invitationLinkInput') invitationLinkInput;

  doctor: Doctor = new Doctor();
  doctorId: number;
  doctorForm: FormGroup;
  specialties: Specialty[] = [];
  invitations: Invitation[] = [];
  invitationLink: string;
  sendInvitationAutomatically = true;
  environment: any = environment;

  showSubheader: boolean = false;

  loadingDoctor: boolean = false;
  savingDoctor: boolean = false;
  deletingDoctor: boolean = false;

  subscriptions: Subscription[] = [];

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 's') {
      this.save(false);
      event.preventDefault();
      return false;
    }
    if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 'd') {
      this.delete();
      event.preventDefault();
      return false;
    }
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private toasterService: DCToastService,
    private modalService: ModalService,
    private doctorService: DoctorService,
    private invitationService: InvitationService,
    private specialtyService: SpecialtyService,
    private translateService: TranslateService,
  ) { }

  ngOnInit() {
    this.initForm();
    this.getSpecialties();

    const routeSubscription = this.route.params.subscribe(
      (data) => {
        if (data.doctorId) {
          this.doctorId = +data.doctorId;

          this.getDoctor();
          this.getInvitations();
          this.sendInvitationAutomatically = false;
        }
      }
    );
    this.subscriptions.push(routeSubscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  toggleSubheader() {
    this.showSubheader = !this.showSubheader;
  }

  initForm() {
    this.doctorForm = this.formBuilder.group({
      title: [''],
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      phone_1: [''],
      phone_2: [''],
      photo_url: [''],
      biography: [''],
      specialty_ids: [[]],
      active: [true, [Validators.required]],
    });
  }

  getDoctor() {
    this.loadingDoctor = true;
    this.doctorService
      .getDoctor(this.doctorId, { include: 'user' })
      .subscribe(
        (response: { data: Doctor }) => {
          this.doctorForm.patchValue(response.data);
          this.doctor = response.data;
          this.loadingDoctor = false;
        },
        () => {
          this.toasterService.show({
            message: this.translateService.instant('Failed to load doctor.'),
            type: 'error'
          });
          this.loadingDoctor = false;
          this.close();
        }
      );
  }

  getInvitations() {
    this.doctorService
      .getDoctorInvitations(this.doctorId)
      .subscribe(
        (response) => {
          this.invitations = response.data;
          if (response.data.length > 0) {
            if (this.environment.production) {
              this.invitationLink = 'https://secure.doktorijum.com/invitation/' + response.data[0].token + '/registration';
            } else {
              this.invitationLink = 'https://dev.secure.doktorijum.com/invitation/' + response.data[0].token + '/registration';
            }
          }
        },
        () => {
          this.toasterService.show({
            message: this.translateService.instant('Failed to load doctor invitations.'),
            type: 'error'
          });
        }
      );
  }

  getSpecialties() {
    this.specialtyService
      .getSpecialties()
      .subscribe(
        (response) => {
          this.specialties = response.data;
        },
        () => {
          this.toasterService.show({
            message: this.translateService.instant('Failed to load specialties.'),
            type: 'error'
          });
        }
      );
  }

  save(close: boolean = false) {
    if (this.doctorForm.invalid) { return; }

    if (this.doctorId) {
      this.savingDoctor = true;
      this.doctorService
        .updateDoctor(this.doctorId, this.doctorForm.value)
        .subscribe(
          (response: { data: Doctor }) => {
            this.toasterService.show({
              message: this.translateService.instant('Doctor was successfully updated.'),
              type: 'success'
            });

            this.doctorForm.patchValue(response.data);
            this.savingDoctor = false;
            if (close) { this.close(); }
          },
          () => {
            this.toasterService.show({
              message: this.translateService.instant('There was an error while saving the doctor.'),
              type: 'error'
            });
            this.savingDoctor = false;
          }
        );
    } else {
      this.savingDoctor = true;
      this.doctorService
        .createDoctor(this.doctorForm.value)
        .subscribe(
          (response: { data: Doctor }) => {
            this.toasterService.show({
              message: this.translateService.instant('Doctor was successfully created.'),
              type: 'success'
            });

            this.doctorId = response.data.id;
            this.doctorForm.patchValue(response.data);
            this.savingDoctor = false;

            if (this.sendInvitationAutomatically) {
              const invitation = new Invitation();
              invitation.email = this.doctorForm.get('email').value;
              invitation.profile_id = this.doctorId;
              invitation.profile_type = 'doctor';

              this.invitationService.invite(invitation)
                .subscribe(
                  () => {
                    if (!close) {
                      this.getInvitations();
                    }
                  },
                  () => { }
                );
            }

            if (close) {
              this.close();
            } else {
              this.router.navigate([ './doctor/' + this.doctorId ]);
            }
          },
          () => {
            this.toasterService.show({
              message: this.translateService.instant('There was an error while saving the doctor.'),
              type: 'error'
            });
            this.savingDoctor = false;
          }
        );
    }
  }

  delete() {
    if (!this.doctorId) { return; }

    this.modalService.confirm({
      title: this.translateService.instant('Delete'),
      body: this.translateService.instant('Are you sure you want to delete this doctor?'),
      size: 'small',
      buttons: [
        {
          text: this.translateService.instant('No'),
          role: 'cancel'
        },
        {
          text: this.translateService.instant('Yes'),
          handler: () => {
            this.deletingDoctor = true;
            this.doctorService
              .deleteDoctor(this.doctorId)
              .subscribe(
                () => {
                  this.toasterService.show({
                    message: this.translateService.instant('Doctor successfully deleted.'),
                    type: 'success'
                  });
                  this.deletingDoctor = false;
                  this.close();
                },
                () => {
                  this.toasterService.show({
                    message: this.translateService.instant('Failed to delete the doctor.'),
                    type: 'error'
                  });
                  this.deletingDoctor = false;
                }
              );
          }
        }
      ]
    });
  }

  copyToClipboard() {
    this.invitationLinkInput.nativeElement.select();
    document.execCommand("copy");
    setTimeout(() => {
      this.invitationLinkInput.nativeElement.setSelectionRange(0, 0);
    }, 300);
  }

  inviteDoctor() {
    const modalRef = this.modalService.open(InvitationModalComponent);
    modalRef.componentInstance.doctor = {
      id: this.doctorId,
      email: this.doctorForm.get('email').value
    };
    modalRef.result.then(
      () => {
        this.getInvitations();
        this.toasterService.show({
          message: 'Invitation was sent to the doctor.',
          type: 'success'
        });
      },
      () => { }
    );
  }

  revokeInvitation(invitation: Invitation) {
    this.modalService.confirm({
      title: 'Revoke invitation',
      body: 'Are you sure you want to revoke this invitation?',
      size: 'small',
      buttons: [
        {
          text: 'No',
          role: 'cancel'
        },
        {
          text: 'Yes',
          handler: () => {
            this.invitationService
              .revoke(invitation.id)
              .subscribe(
                () => {
                  this.getInvitations();
                  this.toasterService.show({ message:
                    'Invitation was revoked.',
                    type: 'success'
                  });
                },
                () => {
                  this.toasterService.show({
                    message: 'Failed to revoke the invitation.',
                    type: 'error'
                  });
                }
              );
          }
        }
      ]
    });
  }

  close() {
    this.router.navigate(['/institution', 'doctor', 'list']);
  }

}
