<div class="dc-subheader" [class.show-nav]="showSubheader">
  <div class="dc-container">
    <div class="dc-row middle">
      <div class="dc-col xs-12">
        <ul class="navigation">
          <li>
            <a [routerLink]="['/institution', 'details']" translate>Settings</a>
          </li>
          <li>
            <a [routerLink]="['/institution', 'template', 'list']" translate>Templates</a>
          </li>
          <li>
            <a [routerLink]="['/institution', 'service', 'list']" translate>Services</a>
          </li>
          <li>
            <a [routerLink]="['/institution', 'promotion', 'list']" translate>Promotions</a>
          </li>
          <li>
            <a [routerLink]="['/institution', 'doctor', 'list']" translate>Doctors</a>
          </li>
          <li>
            <a [routerLink]="['/institution', 'user', 'list']" routerLinkActive="active" translate>Users</a>
          </li>
        </ul>
        <div class="subheader-responsive">
          <span translate>Information</span>
          <span class="nav-toggle" (click)="toggleSubheader()">...</span>
          <span class="dc-icon remove-close" (click)="toggleSubheader()"></span>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="dc-section">
  <div class="dc-container">
    <div class="dc-row middle">
      <div class="dc-col md-4">
        <dc-input
          [placeholder]="'Search' | translate"
          [showLabel]="false"
          [(ngModel)]="searchTerm"
          (ngModelChange)="getInstitutionUsers()"
        ></dc-input>
      </div>
<!--      <div class="dc-col md-8 flex end">-->
<!--        <dc-button  size="small" color="primary" icon="plus" [routerLink]="[ '..', 'new' ]">{{ 'Add' | translate }}</dc-button>-->
<!--      </div>-->
    </div>
  </div>
</div>
<div class="dc-section dc-content" [perfectScrollbar]="{ suppressScrollX: true}" dcInfiniteScroll (scrolled)="getInstitutionUsers(true)">
  <div [dcLoading]="loadingUsers"></div>
  <div class="dc-container">
    <div class="table-wrapper position-relative">
      <table class="dc-table break-phone padding p-small">
        <tr class="dc-clickable" *ngFor="let user of users" [routerLink]="['../' + user.id + '/details' ]">
          <td style="width: 90px">
            <label class="dc-table__label" translate>ID</label>
            <div class="dc-table__value">
              <span>{{user.id}}</span>
            </div>
          </td>
          <td>
            <label class="dc-table__label" translate>Name</label>
            <div class="dc-table__value">
              <span>{{user.first_name}} {{user.last_name}}</span>
            </div>
          </td>
          <td>
            <label class="dc-table__label" translate>Roles</label>
            <div class="dc-table__value">
              <span *ngFor="let role of user.roles; let i = index">{{role.name.split(' ')[1] | translate}}<span *ngIf="i !== user.roles.length - 1">, </span></span>
           </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>
