import { Component, Input, OnInit } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProgressBarService } from '../../../progress-bar/progress-bar.service';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

@Component({
  selector: 'dc-auto-upload',
  templateUrl: './auto-upload.component.html'
})
export class DCAutoUploadComponent implements OnInit {

  @Input() uploader: FileUploader;

  configScroll: PerfectScrollbarConfigInterface = { };
  percent: number;
  files: any[];

  constructor(
    public activeModal: NgbActiveModal,
    private progressService: ProgressBarService,
  ) { }

  ngOnInit(): void {
    this.files = this.uploader.queue;
    this.upload();
  }

  upload() {
    this.uploader.onProgressItem = (file, progress) => {
      this.progressService.start();
      this.percent = progress;
      if (this.percent === 100) { }
    };
    this.uploader.onCompleteItem = () => { };
    this.uploader.onCompleteAll = () => {
      this.uploader.clearQueue();
    };
  }

  finish() {
    this.activeModal.close();
  }

}
