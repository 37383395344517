<div class="dc-collapse__wrapper">
  <div class="dc-collapse__content">
    <header class="dc-collapse__header" (click)="toggleCollapse()">
      <div class="dc-collapse__actions">
        <div class="dc-collapse__action">
          <button class="dc-icon" [ngClass]="{'arrow-down': collapsed, 'arrow-up': !collapsed}"></button>
        </div>
      </div>
    </header>
    <section class="dc-collapse__body">
      <ng-content></ng-content>
    </section>
  </div>
</div>
