import { Component, EventEmitter, forwardRef, Host, Input, OnInit, Optional, Output, SkipSelf, ViewChild } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { FileUploader } from 'ng2-file-upload';
import { FileService } from './file.service';
import { ProgressBarService } from '../progress-bar/progress-bar.service';
import { UploaderConfig } from './_classes/uploader-config';
import { ModalService } from '../modal/modal.service';
import { ModalAlertConfig } from '../modal/_classes/modal-alert-config';
import { ToastrService } from "ngx-toastr";
import { DCToastService } from "../toast/toast.service";

@Component({
  selector: 'dc-file',
  templateUrl: './file.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DCFileComponent),
    multi: true
  }]
})
export class DCFileComponent implements OnInit, ControlValueAccessor {

  @ViewChild('inputFile') inputFile;

  uploader: FileUploader;
  readyToDrop: boolean = false;

  defaultConfig: UploaderConfig = {
    autoUpload: false,
    removeAfterUpload: false,
    headers: [],
    url: '',
    authToken: ''
  };
  defaultErrorModalConfig: ModalAlertConfig = {
    title: 'Error',
    body: 'Failed to upload resource',
    type: 'danger',
    button: {
      text: 'Ok'
    }
  };
  maxFiles: number = 10;
  maxFilesAlert = 'Maximum number of files for upload is ' + this.maxFiles;

  @Input() value: any = null;
  @Input() formControlName: string;
  @Input() type: string | 'default' = 'default';
  @Input() name: string;
  @Input() label: string;
  @Input() icon: string;
  @Input() disabled: boolean = false;
  @Input() required: boolean = false;
  @Input() readOnly: boolean = false;
  @Input() multiple: boolean = false;
  @Input() showProgressBar: boolean = true;
  @Input() uploaderConfig: UploaderConfig;
  @Input() completeModalConfig: ModalAlertConfig;
  @Input() errorModalConfig: ModalAlertConfig;
  @Input() uploaderModalContent: any;
  @Input() modalConfig: any;
  @Input() then: any;

  @Output() changeFile = new EventEmitter();
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onComplete = new EventEmitter();

  onChange: (fn: any) => void;
  onTouched: (fn: any) => void;

  constructor(
    private fileService: FileService,
    public sanitizer: DomSanitizer,
    private progressService: ProgressBarService,
    private modalService: ModalService,
    private toastService: DCToastService,
    @Optional() @Host() @SkipSelf() private controlContainer: ControlContainer
  ) { }

  ngOnInit() {
    if (this.multiple !== false) { this.multiple = true; }
    if (this.showProgressBar !== false) { this.showProgressBar = true; }
    if (this.disabled !== false) { this.disabled = true; }
    if (this.required !== false) { this.required = true; }
    if (this.readOnly !== false) { this.readOnly = true; }
    this.setUploader();
    this.setDefaultParams();
  }

  writeValue(value: any): void {
    this.value = value ? value : null;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setUploader() {
    this.uploader = FileService.createUploader(this.uploaderConfig);
    this.uploader.onAfterAddingFile = (file: any) => { file.withCredentials = false; };
    this.uploader.onErrorItem = () => { this.modalService.alert(this.errorModalConfig); };
  }

  setDefaultParams() {
    if (!this.uploaderConfig) { this.uploaderConfig = this.defaultConfig; }
    if (!this.errorModalConfig) { this.errorModalConfig = this.defaultErrorModalConfig; }
  }

  emitChange() {
    if (!this.uploaderConfig.autoUpload) {
      this.fileService.openProgressModal(this.uploader, this.uploaderModalContent, this.modalConfig)
        .finally(() => { this.uploader.clearQueue(); this.inputFile.nativeElement.value = ''; this.onComplete.emit() });
    } else {
    this.fileService.openAutoUploadModal(this.uploader)
      .finally(() => { this.uploader.clearQueue(); this.inputFile.nativeElement.value = ''; this.onComplete.emit() });
    }
    this.changeFile.emit({files: this.uploader.queue});
  }

  dragEnter(event: DragEvent) {
    this.readyToDrop = true;
    event.preventDefault();
    event.stopPropagation();
  }

  dragLeave(event: DragEvent) {
    this.readyToDrop = false;
    event.preventDefault();
    event.stopPropagation();
  }

  onDrop(event: DragEvent) {
    this.dragLeave(event);
    if (this.disabled) { return; }
    if (!this.multiple && event.dataTransfer.files.length > 1) {
      this.toastService.show({
        type: 'error',
        message: 'Maximum number of files for upload is 1'
      })
      return;
    }
    if (this.multiple && event.dataTransfer.files.length > this.maxFiles) {
      this.toastService.show({
        type: 'error',
        message: this.maxFilesAlert
      })
      return;
    }
    Array.from(event.dataTransfer.files).forEach((file: any) => {
      file.withCredentials = false;
      this.uploader.addToQueue([file]);
    });
    if (!this.uploaderConfig.autoUpload) {
      this.fileService.openProgressModal(this.uploader, this.uploaderModalContent, this.modalConfig)
        .finally(() => { this.uploader.clearQueue(); this.inputFile.nativeElement.value = ''; this.onComplete.emit() });
    } else {
      this.fileService.openAutoUploadModal(this.uploader)
        .finally(() => { this.uploader.clearQueue(); this.inputFile.nativeElement.value = ''; this.onComplete.emit() });
    }
  }

  delete() {
    this.value = null;
  }
}
