<section class="dc-section dc-content" [perfectScrollbar]="{ suppressScrollX: true}">
  <div></div>
  <div class="dc-container">
    <div class="dc-row middle">
      <div class="dc-col sm-12">
        <h1>
          <div class="super-heading" translate>User</div>
          <span *ngIf="user && userId">{{ userForm.value.first_name }} {{ userForm.value.last_name }}</span>
          <span *ngIf="!userId">{{ 'New user' | translate }}</span>
        </h1>
      </div>
    </div>
    <form class="dc-row space-16" [formGroup]="userForm">
      <div class="dc-col md-4">
        <div class="dc-row">
          <div class="dc-col md-12">
            <h2 class="dc-text-color-primary" translate>Information</h2>
          </div>
          <div class="dc-col md-12">
            <dc-input
              label="{{ 'Name' | translate }}"
              formControlName="first_name"
            ></dc-input>
          </div>
          <div class="dc-col md-12">
            <dc-input
              label="{{ 'Last name' | translate }}"
              formControlName="last_name"
            ></dc-input>
          </div>
          <div class="dc-col md-12">
            <dc-input
              label="{{ 'Email' | translate }}"
              formControlName="email"
            ></dc-input>
          </div>
<!--          <div class="dc-col md-12">-->
<!--            <h2 class="dc-text-color-primary" translate>Doktorijum user</h2>-->
<!--          </div>-->
<!--          <div class="dc-col md-12">-->
<!--            <div>-->
<!--              <div translate>Invitation to register sent.</div>-->
<!--              <div>-->
<!--                <div class="invitation">-->
<!--                  <div class="dot yellow"></div>-->
<!--                  <div style="flex: 1">-->
<!--                    <div>-->
<!--                      <span translate>Sent</span>:  | <span translate>Expires</span>:-->
<!--                    </div>-->
<!--                    <div>email</div>-->
<!--                  </div>-->
<!--                  <div>-->
<!--                    <span class="dc-icon delete dc-clickable" style="font-size: 28px; vertical-align: middle"></span>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <dc-button size="full">{{ 'Send new invitation to register' | translate }}</dc-button>-->
<!--            </div>-->
<!--          </div>-->
        </div>
        </div>
      <div class="dc-col md-2">
        <div class="dc-row">
          <div class="dc-col md-12">
            <h2 class="dc-text-color-primary" translate>Roles</h2>
          </div>
          <div class="dc-col md-12" *ngFor="let role of roles">
            <dc-checkbox
            *ngIf="user"
            label="{{role.name.split(' ')[1] | translate}}"
            (valueChange)="selectRoles(role.id)"
            [checked]="selectedRoles.includes(role.id)"
            ></dc-checkbox>
          </div>
        </div>
      </div>
      <div class="dc-col md-4 lg-offset-2">
        <div class="dc-row">
          <div class="dc-col md-12">
            <div class="user-details__container">
              <div class="dc-col md-12">
                <h2 class="dc-text-color-primary" translate>Roles in Doktorijum system</h2>
              </div>
              <div class="dc-col md-12">
                <h2 translate>Owner</h2>
                <p>Uloga vlasnika je rezervisana za vlasnika naloga institucije. Vlasnik je nosioc pretplate.</p>
                <p>Vlasnik ima pristup:</p>
                <ul>
                  <li>Svim podešavanjima u okviru institucije</li>
                  <li>Održavanju kalendara</li>
                  <li>Kartonima pacijenta</li>
                  <li>Kreiranju posete sa svim opcijama (unos mišljenja, dijagnoze, terapije)</li>
                </ul>
              </div>
              <div class="dc-col md-12">
                <h2 translate>Administrator</h2>
                <p>Korisnik sa ulogom administratora ima pristup:</p>
                <ul>
                  <li>Održavanju kalendara</li>
                  <li>Kartonima svih pacijenata</li>
                  <li>Kreiranju posete sa svim opcijama (unos mišljenja, dijagnoze, terapije)</li>
                </ul>
              </div>
              <div class="dc-col md-12">
                <h2 translate>Doctor</h2>
                <p>Korisnik sa ulogom doktora ima pristup:</p>
                <ul>
                  <li>Pregledu kalendara i otvaranju termina za sebe</li>
                  <li>Kartonima svojih pacijenata</li>
                  <li>Kreiranju posete sa svim opcijama (unos mišljenja, dijagnoze, terapije)</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</section>
<div class="dc-bottom-bar">
  <div class="dc-container flex" style="height: 100%; align-items: center;">
    <dc-button size="small" color="secondary" (click)="close()">{{ 'Close' | translate }}</dc-button>
    &nbsp;&nbsp;
    <dc-button size="small" (click)="save()">{{ 'Save' | translate }}</dc-button>
    &nbsp;&nbsp;
    <dc-button size="small" (click)="save(true)">{{ 'Save & Close' | translate }}</dc-button>
  </div>
</div>
