import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DCRadioComponent } from './radio.component';



@NgModule({
  declarations: [
    DCRadioComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    DCRadioComponent
  ]
})
export class DCRadioModule { }
