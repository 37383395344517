import { Component, OnInit, OnDestroy, Input, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { InstitutionService } from '../../services/institution.service';
import { Institution } from '../../classes/institution';
import { Specialty } from '../../classes/specialty';
import { ToastrService } from "ngx-toastr";
import { DCToastService } from "../../../../../../libs/ui/src/lib/components/toast/toast.service";
import { ModalService } from "../../../../../../libs/ui/src/lib/components/modal/modal.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: 'app-institution-service-details-modal',
    templateUrl: './institution-service-details-modal.component.html'
})
export class InstitutionServiceDetailsModalComponent implements OnInit, OnDestroy {
    @Input() specialtyId: number;
    @Input() institutionServiceId: number;
    institutionServiceForm: FormGroup;
    institution: Institution;
    specialties: Specialty[] = [];

    loadingService: boolean = true;
    savingInstitutionService: boolean = false;

    subscriptions: Subscription[] = [];

    constructor(
        private formBuilder: FormBuilder,
        private institutionService: InstitutionService,
        private activeModal: NgbActiveModal,
        private toastService: DCToastService,
        private modalService: ModalService,
        private translateService: TranslateService
    ) { }

    ngOnInit() {
        this.initForm();
        this.getInstitution();
        this.getInstitutionService();
    }

    ngOnDestroy() {
        for (const subscription of this.subscriptions) {
          subscription.unsubscribe();
        }
    }

    initForm() {
        this.institutionServiceForm = this.formBuilder.group({
            name: ['', Validators.required],
            price: ['', Validators.required],
            specialty_id: [this.specialtyId]
        });
    }

    getInstitution() {
      this.institutionService
        .currentInstitution
        .subscribe(
          (institution: Institution) => {
            if (institution) {
              this.institution = institution;
              this.specialties = institution.specialties;
            }
          },
          () => { }
        );
    }

    getInstitutionService() {
        if (this.institutionServiceId) {
            this.loadingService = true;
            this.institutionService
                .getInstitutionService(this.institutionServiceId)
                .subscribe(
                    (response) => {
                        this.institutionServiceForm.patchValue(response.data);
                        this.loadingService = false;
                    },
                    () => {
                        this.loadingService = false;
                    }
                );
        } else {
            this.loadingService = false;
        }
    }

    save() {
        this.savingInstitutionService = true;
        if (this.institutionServiceId) {
            this.institutionService
            .updateInstitutionService(this.institutionServiceId, this.institutionServiceForm.value)
            .subscribe(
                () => {
                    this.activeModal.close();
                    this.savingInstitutionService = false;
                },
                () => {
                    this.savingInstitutionService = false;
                }
            );
        } else {
        this.institutionService
            .createInstitutionService(this.institutionServiceForm.value)
            .subscribe(
                () => {
                    this.activeModal.close();
                    this.savingInstitutionService = false;
                },
                () => {
                    this.savingInstitutionService = false;
                }
            );
        }

    }

    delete() {
      this.modalService.confirm(
        {
          title: this.translateService.instant('Delete'),
          body: this.translateService.instant('Are you sure you want to delete this service?'),
          buttons: [
            {
              role: "cancel",
              text: this.translateService.instant('No')
            },
            {
              text: this.translateService.instant('Yes'),
              handler: () => {
                this.institutionService.deleteInstitutionService(this.institutionServiceId)
                  .subscribe(
                    () => {
                      this.activeModal.close();
                      this.toastService.show(
                        {
                          type: "success",
                          message: this.translateService.instant('Service successfully deleted')
                        }
                      );
                      this.activeModal.close();
                    },
                    () => {
                      this.toastService.show(
                        {
                          type: "error",
                          message: this.translateService.instant('Deleting service error')
                        }
                      );
                    },
                  )
              }
            }
          ]
        }
      );
    }

    close() {
        this.activeModal.dismiss();
    }

}
