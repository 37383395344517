import { User } from './user';

export class Patient {
  id: number;
  first_name: string;
  last_name: string;
  parent_name: string;
  photo_url: string;
  jmbg: string;
  blood_type: string;
  gender: string;
  date_of_birth: string;
  height: string;
  weight: string;
  anamnesis_personal: string;
  anamnesis_doctor: string;
  country_id: number;
  city: string;
  address: string;
  phone: string;
  phone_1: string;
  phone_2: string;
  email: string;
  patient_number: string;
  last_visit: string;
  user_id: number;

  user: User;

  constructor(values: object = {}) {
    Object.assign(this, values);
  }

  full_name() {
    return this.first_name + ' ' + this.last_name;
  }
}
