<div class="dc-modal small">
    <div class="dc-modal__header">
        <h2 class="dc-modal__title">{{ 'New patient' | translate }}</h2>
        <span class="dc-modal__close" (click)="close()"></span>
    </div>
    <div class="dc-modal__body">
        <form [formGroup]="patientForm">
            <div class="dc-row">
                <div class="dc-col">
                    <dc-input
                        label="{{ 'First name' | translate }}"
                        formControlName="first_name"
                    ></dc-input>
                </div>
            </div>
            <div class="dc-row">
                <div class="dc-col">
                    <dc-input
                        label="{{ 'Last name' | translate }}"
                        formControlName="last_name"
                    ></dc-input>
                </div>
            </div>
            <div class="dc-row">
                <div class="dc-col">
                    <dc-input
                        label="{{ 'Parent name' | translate }}"
                        formControlName="parent_name"
                    ></dc-input>
                </div>
            </div>
            <div class="dc-row">
                <div class="dc-col">
                    <dc-datepicker
                        label="{{ 'Birth date' | translate }}"
                        formControlName="date_of_birth"
                        type="calendar"
                        startView="multi-years"
                        inputMask
                    ></dc-datepicker>
                </div>
            </div>
            <div class="dc-row">
                <div class="dc-col">
                    <dc-input
                        label="{{ 'Phone 1' | translate }}"
                        formControlName="phone_1"
                    ></dc-input>
                </div>
            </div>
            <div class="dc-row">
                <div class="dc-col">
                    <dc-input
                        label="{{ 'Phone 2' | translate }}"
                        formControlName="phone_2"
                    ></dc-input>
                </div>
            </div>
            <div class="dc-row">
                <div class="dc-col">
                    <dc-input
                        label="{{ 'Email' | translate }}"
                        formControlName="email"
                    ></dc-input>
                </div>
            </div>
        </form>
    </div>
    <div class="dc-modal__actions">
        <div class="dc-modal__action-left"></div>
        <div class="dc-modal__action-right">
            <dc-button color="outline" (click)="close()">{{ 'Cancel' | translate }}</dc-button>
            &nbsp;
            <dc-button [disabled]="patientForm.invalid" (click)="save()">{{ 'Save' | translate }}</dc-button>
        </div>
    </div>
</div>
