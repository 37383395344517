import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { Promotion } from '../../classes/promotion';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { PromotionService } from '../../services/promotion.service';
import { DCToastService } from '@dc/ui/components/toast/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from '@dc/ui/components/modal/modal.service';

@Component({
  selector: 'main[dc-promotion-details]',
  templateUrl: './promotion-details.component.html'
})
export class PromotionDetailsComponent implements OnInit, OnDestroy {
  @ViewChild(PerfectScrollbarDirective, { static: false }) perfectScrollbarDirectiveRef?: PerfectScrollbarDirective;
  promotion: Promotion;
  promotionForm: FormGroup;
  promotionId: number;

  loadingPromotion: boolean = false;
  savingPromotion: boolean = false;
  deletingPromotion: boolean = false;

  showSubheader: boolean = false;

  subscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private promotionService: PromotionService,
    private toasterService: DCToastService,
    private translateService: TranslateService,
    private modalService: ModalService
  ) { }

  ngOnInit() {
    this.initForm();
    const routeSubscription = this.route.params.subscribe(
      (data) => {
        if (data.promotionId) {
          this.promotionId = data.promotionId;
          this.getPromotion();
        }
      }
    );
    this.subscriptions.push(routeSubscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  toggleSubheader() {
    this.showSubheader = !this.showSubheader;
  }

  initForm() {
    this.promotionForm = this.formBuilder.group({
      title: [null, [Validators.required]],
      image_big: [null],
      description: [null],
      date_from: [null],
      date_to: [null]
    });
  }

  getPromotion() {
    this.loadingPromotion = true;
    this.promotionService
      .getPromotion(this.promotionId)
      .subscribe(
        (response: { data: Promotion }) => {
          this.promotionForm.patchValue(response.data);
          this.promotion = response.data;
          this.loadingPromotion = false;
        },
        () => {
          this.toasterService.show({
            message: this.translateService.instant('Failed to load promotion.'),
            type: 'error'
          });
          this.loadingPromotion = false;
          this.close();
        }
      );
  }

  save(close: boolean = false) {
    if (this.promotionForm.invalid) { return; }

    if (this.promotionId) {
      this.savingPromotion = true;
      this.promotionService
        .updatePromotion(this.promotionId, this.promotionForm.value)
        .subscribe(
          (response: { data: Promotion }) => {
            this.toasterService.show({
              message: this.translateService.instant('Promotion was successfully updated.'),
              type: 'success'
            });

            this.promotionForm.patchValue(response.data);
            this.savingPromotion = false;
            if (close) { this.close(); }
          },
          () => {
            this.toasterService.show({
              message: this.translateService.instant('There was an error while saving the promotion.'),
              type: 'error'
            });
            this.savingPromotion = false;
          }
        );
    } else {
      this.savingPromotion = true;
      this.promotionService
        .createPromotion(this.promotionForm.value)
        .subscribe(
          (response: { data: Promotion }) => {
            this.toasterService.show({
              message: this.translateService.instant('Promotion was successfully created.'),
              type: 'success'
            });

            this.promotionId = response.data.id;
            this.promotionForm.patchValue(response.data);
            this.savingPromotion = false;

            if (close) {
              this.close();
            } else {
              this.router.navigate([ './promotion/' + this.promotionId ]);
            }
          },
          () => {
            this.toasterService.show({
              message: this.translateService.instant('There was an error while saving the promotion.'),
              type: 'error'
            });
            this.savingPromotion = false;
          }
        );
    }
  }

  delete() {
    if (!this.promotionId) { return; }

    this.modalService.confirm({
      title: this.translateService.instant('Delete'),
      body: this.translateService.instant('Are you sure you want to delete this promotion?'),
      size: 'small',
      buttons: [
        {
          text: this.translateService.instant('No'),
          role: 'cancel'
        },
        {
          text: this.translateService.instant('Yes'),
          handler: () => {
            this.deletingPromotion = true;
            this.promotionService
              .deletePromotion(this.promotionId)
              .subscribe(
                () => {
                  this.toasterService.show({
                    message: this.translateService.instant('Promotion successfully deleted.'),
                    type: 'success'
                  });
                  this.deletingPromotion = false;
                  this.close();
                },
                () => {
                  this.toasterService.show({
                    message: this.translateService.instant('Failed to delete the promotion.'),
                    type: 'error'
                  });
                  this.deletingPromotion = false;
                }
              );
          }
        }
      ]
    });
  }

  close() {
    this.router.navigate(['/institution', 'promotion', 'list']);
  }

}

