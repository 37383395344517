import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { DiseaseService } from "../../services/disease.service";
import { Disease } from "../../classes/disease";

@Component({
  selector: 'doc-add-diagnosis',
  templateUrl: './add-diagnosis.component.html'
})
export class AddDiagnosisComponent implements OnInit {
  @Input() selectedDiagnosisIds: number[] = [];
  diseasesSearchTerm: any;
  diseasesSearchResults: Disease[] = [];
  selectedDiseases: any;

  constructor(
    private activeModal: NgbActiveModal,
    private diseaseService: DiseaseService,
  ) { }

  ngOnInit() {
    this.selectedDiseases = null;
    this.diseasesSearchTerm = null;
  }

  searchDiseases() {
    if (this.diseasesSearchTerm.length > 2) {
      this.diseaseService.getDiseases({ search_term: this.diseasesSearchTerm })
        .subscribe(
          (response) => {
            const checkAddedDiagnosis = response.data.filter((item) =>  !this.selectedDiagnosisIds.includes(item.id) )
            this.diseasesSearchResults = checkAddedDiagnosis;
          },
          () => { }
        );
    } else {
      this.diseasesSearchResults = [];
    }
  }

  selectDiagnosis(disease) {
    this.selectedDiseases = disease;
  }

  save() {
    this.activeModal.close(this.selectedDiseases);
  }

  close() {
    this.activeModal.dismiss();
  }

}
