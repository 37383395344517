import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OnInit, Component, Input } from '@angular/core';
import { Appointment } from '../../classes/appointment';
import { AppointmentService } from '../../services/appointment.service';
import { ModalService } from 'libs/ui/src/public-api';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-appointment-cancel-modal',
  templateUrl: './appointment-cancel-modal.component.html',
})
export class AppointmentCancelModalComponent implements OnInit {
  @Input() appointment: Appointment;
  appointmentCancelFrom: FormGroup;

  constructor(
    private appointmentService: AppointmentService,
    private modalService: ModalService,
    private formBuilder: FormBuilder,
    private activeModal: NgbActiveModal
  ) {}

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.appointmentCancelFrom = this.formBuilder.group({
      reason: ['', [Validators.required]]
    });
  }

  cancelAppointment() {
    this.appointmentService
        .cancelAppointment(this.appointment.id, this.appointmentCancelFrom.value)
        .subscribe(
            () => {
              this.activeModal.close();
            },
            (error) => {
              this.modalService.error({ errors: error });
            }
        );
  }

  close() {
    this.activeModal.dismiss();
  }
}
