import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { InstitutionService } from '../../services/institution.service';
import { Institution } from '../../classes/institution';
import { ModalService } from 'libs/ui/src/public-api';
import { DCToastService } from '@dc/ui/components/toast/toast.service';
import { Subscription } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'main [dc-institution-template-details]',
  templateUrl: './institution-template-details.component.html'
})
export class InstitutionTemplateDetailsComponent implements OnInit, OnDestroy {
  @ViewChild(PerfectScrollbarDirective, { static: false }) perfectScrollbarDirectiveRef?: PerfectScrollbarDirective;
  institution: Institution;
  institutionTemplateForm: FormGroup;
  institutionTemplateId: number;
  institutionTemplate: any;

  loadingTemplate: boolean = false;
  savingTemplate: boolean = false;
  deletingTemplate: boolean = false;

  showSubheader: boolean = false;

  subscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private institutionService: InstitutionService,
    private modalService: ModalService,
    private toasterService: DCToastService
  ) { }

  ngOnInit() {
    this.initForm();
    this.getInstitution();

    const routeSubscription = this.route.params.subscribe(
      (data) => {
        if (data.institutionTemplateId) {
          this.institutionTemplateId = +data.institutionTemplateId;

          this.getInstitutionTemplate();
        }
      }
    );
    this.subscriptions.push(routeSubscription);
  }

  toggleSubheader() {
    this.showSubheader = !this.showSubheader;
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  initForm() {
    this.institutionTemplateForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      content: ['', [Validators.required]],
    });
  }

  getInstitution() {
    this.institutionService
      .currentInstitution
      .subscribe(
        (institution: Institution) => {
          if (institution) {
            this.institution = institution;
          }
        },
        () => { }
      );
  }

  getInstitutionTemplate() {
    this.loadingTemplate = true;
    if (this.institutionTemplateId) {
      this.institutionService
        .getInstitutionTemplate(this.institutionTemplateId)
        .subscribe(
          (response: any) => {
            this.institutionTemplateForm.patchValue(response);
            this.loadingTemplate = false;
          },
          () => {
            this.loadingTemplate = false;
          }
        );
    } else {
      this.loadingTemplate = false;
    }
  }

  save(close: boolean = false) {
    if (this.institutionTemplateForm.invalid) { return; }

    if (this.institutionTemplateId) {
      this.savingTemplate = true;
      this.institutionService
        .updateInstitutionTemplates(this.institutionTemplateId, this.institutionTemplateForm.value)
        .subscribe(
          (response: any) => {
            this.toasterService.show({
              message: 'Template was successfully updated.',
              type: 'success'
            });

            this.institutionTemplateForm.patchValue(response);
            this.savingTemplate = false;
            if (close) { this.close(); }
          },
          () => {
            this.toasterService.show({
              message: 'There was an error while saving the template.',
              type: 'error'
            });
            this.savingTemplate = false;
          }
        );
    } else {
      this.savingTemplate = true;
      this.institutionService
        .createInstitutionTemplates(this.institutionTemplateForm.value)
        .subscribe(
          (response: any) => {
            this.toasterService.show({
              message: 'Template was successfully created.',
              type: 'success'
            });

            this.institutionTemplateId = response.id;
            this.institutionTemplateForm.patchValue(response);
            this.savingTemplate = false;

            if (close) {
              this.close();
            } else {
              this.router.navigate(['/institution', 'template', this.institutionTemplateId, 'details']);
            }
          },
          () => {
            this.toasterService.show({
              message: 'There was an error while saving the template.',
              type: 'error'
            });
            this.savingTemplate = false;
          }
        );
    }
  }

  delete() {
    if (!this.institutionTemplateId) { return; }

    this.modalService.confirm({
      title: 'Delete',
      body: 'Are you sure you want to delete this template?',
      size: 'small',
      buttons: [
        {
          text: 'No',
          role: 'cancel'
        },
        {
          text: 'Yes',
          handler: () => {
            this.deletingTemplate = true;
            this.institutionService
              .deleteInstitutionTemplates(this.institutionTemplateId)
              .subscribe(
                () => {
                  this.toasterService.show({
                    message: 'Template succesfully deleted.',
                    type: 'success'
                  });
                  this.deletingTemplate = false;
                  close();
                },
                () => {
                  this.toasterService.show({
                    message: 'Failed to delete the template.',
                    type: 'error'
                  });
                  this.deletingTemplate = false;
                }
              );
          }
        }
      ]
    });
  }

  close() {
    this.router.navigate(['/institution', 'template', 'list']);
  }

}
