import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DCTextEditorComponent } from './text-editor.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    DCTextEditorComponent
  ],
  imports: [
    CommonModule,
    CKEditorModule,
    FormsModule
  ],
  exports: [
    DCTextEditorComponent
  ]
})
export class DCTextEditorModule { }
