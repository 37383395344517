<div class="dc-subheader" [class.show-nav]="showSubheader">
  <div class="dc-container">
    <div class="dc-row middle">
      <div class="dc-col xs-12">
        <ul class="navigation">
          <li>
            <a [routerLink]="['/institution', 'details']" translate>Settings</a>
          </li>
          <li>
            <a [routerLink]="['/institution', 'template', 'list']" translate>Templates</a>
          </li>
          <li>
            <a [routerLink]="['/institution', 'service', 'list']" translate>Services</a>
          </li>
          <li>
            <a [routerLink]="['/institution', 'promotion', 'list']" routerLinkActive="active" translate>Promotions</a>
          </li>
          <li>
            <a [routerLink]="['/institution', 'doctor', 'list']" translate>Doctors</a>
          </li>
          <li *ngIf="user.roles.includes('Global Super Administrator') || user.roles.includes('Institution Owner')">
            <a [routerLink]="['/institution', 'user', 'list']" translate>Users</a>
          </li>
        </ul>
        <div class="subheader-responsive">
          <span translate>Information</span>
          <span class="nav-toggle" (click)="toggleSubheader()">...</span>
          <span class="dc-icon remove-close" (click)="toggleSubheader()"></span>
        </div>
      </div>
    </div>
  </div>
</div>
<!--<dc-empty-state [type]="'promotion'">-->
<!--  <h3 translate>No actions created</h3>-->
<!--  <dc-button size="small" color="primary" icon="plus">{{ 'Add action' | translate }}</dc-button>-->
<!--</dc-empty-state>-->
<div class="dc-section">
  <div class="dc-container">
    <div class="dc-row middle">
      <div class="dc-col md-4">
        <dc-input
          [placeholder]="'Search' | translate"
          [showLabel]="false"
          [(ngModel)]="searchTerm"
          (ngModelChange)="getPromotions()"
        ></dc-input>
      </div>
      <div class="dc-col md-8 flex end">
        <dc-button  size="small" color="primary" icon="plus" [routerLink]="[ '..', 'new' ]">{{ 'Add' | translate }}</dc-button>
      </div>
    </div>
  </div>
</div>
<div class="dc-section dc-content" [perfectScrollbar]="{ suppressScrollX: true}" dcInfiniteScroll (scrolled)="getPromotions(true)">
  <div [dcLoading]="loadingPromotions"></div>
  <div class="dc-container">
    <table class="dc-table">
      <tr class="dc-clickable" *ngFor="let promotion of promotions" [routerLink]="['../' + promotion.id + '/details' ]">
        <td style="width: 140px;">
          <div class="dc-table__value">
            <img [width] = "110" [height]="66" [src]="promotion.image"  alt="" *ngIf="promotion.image || promotion.image.length > 0">
            <div style="width: 110px; height: 66px; background: #C4C4C4" *ngIf="!promotion.image || promotion.image.length === 0"></div>
          </div>
        </td>
        <td>
          <label class="dc-table__label" translate></label>
          <div class="dc-table__value">
            <label class="dc-text-color-primary" translate>{{promotion.title}}</label>
           </div>
            <span class="dc-table__value" [innerHTML]="promotion.description"></span>
        </td>
      </tr>
    </table>
  </div>
</div>
