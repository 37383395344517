<label hidden>
  <input
    type="text"
    [(ngModel)]="value">
</label>
<mgl-map
  *ngIf="showMap"
  [ngStyle]="{'height': height}"
  [style]="'mapbox://styles/mapbox/streets-v9'"
  [zoom]="[zoom]"
  [center]="center"
  [interactive]="interactive"
  (click)="changeCoordinates($event)"
  (wheel)="onScroll()"
>
  <mgl-marker *ngIf="marker" [lngLat]="markerCenter"></mgl-marker>
</mgl-map>
