<div class="dc-root">
    <header class="dc-header" [class.show-nav]="showNav">
        <div class="application">
          <div class="application-logo"></div>
          <nav class="application-navigation">
            <ul>
              <li>
                <a [routerLink]="['./schedule']" routerLinkActive="active" translate (click)="toggleNav()">Schedule</a>
              </li>
              <li>
                <a [routerLink]="['./patient/list']" routerLinkActive="active" translate (click)="toggleNav()">Patients</a>
              </li>
              <li>
                <a [routerLink]="['./details']" routerLinkActive="active" translate (click)="toggleNav()">Settings</a>
              </li>
            </ul>
          </nav>
          <div class="nav-toggle" (click)="toggleNav()"></div>
        </div>
        <div class="profile">
          <div class="institution">
            <img class="institution-logo" *ngIf="institution"
                 [class.dc-clickable]="user?.institutions.length > 1"
                 [src]="institution?.logo_url ? institution.logo_url : 'assets/images/institution-placeholder.svg'"
                 (click)="goToInstitutionSelect()" alt="">
          </div>
            <div class="user" (click)="toggleProfileNav()">
                <img [src]="user?.photo_url ? user.photo_url : 'assets/images/placeholder.svg'" alt="">
                <div class="full-name">{{ user?.first_name }} {{ user?.last_name }}</div>

                <ul class="user-navigation" [class.show]="showProfileNav">
                    <li>
                        <a [routerLink]="['./profile']">
                            <span class="icon settings"></span>
                            <span translate>Settings</span>
                        </a>
                    </li>
                    <li>
                        <a (click)="logout()">
                            <span class="icon logout"></span>
                            <span translate>Log out</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </header>
    <router-outlet></router-outlet>
    <!-- <footer class="dc-footer">
        <div [innerHtml]="'SUPPORT' | translate"></div>
        <div [innerHtml]="'POWERED_BY' | translate"></div>
    </footer> -->
</div>
