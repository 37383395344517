<div class="dc-radio">
  <label [ngClass]="{'disabled': disabled}">
    <input
      type="radio"
      [ngClass]="{'mobile': platform === 'mobile' }"
      (change)="onChangeStatus($event);"
      [name]="name"
      [value]="value"
      [checked]="checked"
      [disabled]="disabled">
    <span class="dc-radio__label">{{ label }}</span>
  </label>
</div>

