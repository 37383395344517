import { Disease } from './disease';

export class Diagnosis {
  id: number;
  disease_id: number;

  disease: Disease;

  constructor(values: object = {}) {
    Object.assign(this, values);
  }
}
