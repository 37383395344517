<div class="dc-subheader" [class.show-nav]="showSubheader">
    <div class="dc-container">
        <div class="dc-row middle">
            <div class="dc-col xs-12">
              <ul class="navigation">
                <li>
                  <a [routerLink]="['/institution', 'details']" translate>Settings</a>
                </li>
                <li>
                  <a [routerLink]="['/institution', 'template', 'list']" translate>Templates</a>
                </li>
                <li>
                  <a [routerLink]="['/institution', 'service', 'list']" translate>Services</a>
                </li>
                <li>
                  <a [routerLink]="['/institution', 'promotion', 'list']" translate>Promotions</a>
                </li>
                <li>
                  <a [routerLink]="['/institution', 'doctor', 'list']" routerLinkActive="active" translate>Doctors</a>
                </li>
                <li *ngIf="user.roles.includes('Global Super Administrator') || user.roles.includes('Institution Owner')">
                  <a [routerLink]="['/institution', 'user', 'list']" translate>Users</a>
                </li>
              </ul>
              <div class="subheader-responsive">
                <span translate>Information</span>
                <span class="nav-toggle" (click)="toggleSubheader()">...</span>
                <span class="dc-icon remove-close" (click)="toggleSubheader()"></span>
              </div>
            </div>
        </div>
    </div>
</div>
<div class="dc-section mb-8">
    <div class="dc-container">
      <div class="dc-row middle space-16">
        <div class="dc-col lg-4">
          <dc-input
            [(ngModel)]="searchTerm"
            (ngModelChange)="getDoctors()"
            [placeholder]="'Search' | translate"
            [showLabel]="false"
          ></dc-input>
        </div>
        <div class="dc-col lg-3">
          <div class="dc-row middle">
            <span class="dc-label__additional-horizontal dc-text-color__gray-medium mr-8">{{'Sort by' | translate}}:</span>
            <div class="dc-col lg-7 mt-0">
              <dc-select [hideLabel]="true" [options]="sortOptions" [showClearButton]="false" (valueChange)="sortDoctors($event)"></dc-select>
            </div>
          </div>
        </div>
        <div class="dc-col lg-5 end">
          <dc-button icon="plus" size="small" [routerLink]="[ '..', 'new' ]">{{ 'Add doctor' | translate }}</dc-button>
        </div>
        <!-- <div class="dc-col md-2">
          <dc-button color="secondary">{{ 'Filter' | translate }}</dc-button>
        </div> -->
      </div>
    </div>
</div>
<div class="dc-section dc-content" [perfectScrollbar]="{ suppressScrollX: true}" dcInfiniteScroll (scrolled)="getDoctors(true)">
    <div [dcLoading]="loadingDoctors"></div>
    <div class="dc-container">
        <table class="dc-table">
            <tbody>
                <tr class="dc-clickable" *ngFor="let doctor of doctors" [routerLink]="[ '..', doctor.id, 'details' ]">
                    <td style="width: 100px;">
                        <label class="dc-table__label" translate>ID</label>
                        <div class="dc-table__value">
                            <span>{{ doctor.id }}</span>
                        </div>
                    </td>
                    <td>
                        <label class="dc-table__label" translate>Doctor name</label>
                        <div class="dc-table__value">
                            <span>{{ doctor.title }} {{ doctor.first_name }} {{ doctor.last_name }}</span>
                        </div>
                    </td>
                    <td style="width: 120px;">
                        <label class="dc-table__label" translate>Active</label>
                        <div class="dc-table__value">
                            <span translate>{{ doctor.active ? 'Yes' : 'No' }}</span>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

