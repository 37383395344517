import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'dc-collapse',
  templateUrl: './collapse.component.html'
})
export class DCCollapseComponent implements OnInit {

  @Input() disabled: boolean = false;
  @Input() collapsed: boolean = true;

  private collapsedSource = new BehaviorSubject(this.collapsed);
  collapsedSubscription = this.collapsedSource.asObservable();

  constructor() { }

  ngOnInit(): void {
    if (this.disabled !== false) { this.disabled = true; }
    this.collapsedSource.next(this.collapsed);
  }

  toggleCollapse() {
    this.collapsed = !this.collapsed;
    this.collapsedSource.next(this.collapsed);
  }

}
