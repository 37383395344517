import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DCImageComponent } from "./image.component";
import { FormsModule } from "@angular/forms";
import { ImageCropperModule } from "ngx-image-cropper";
import { DCButtonModule } from "@dc/ui/components/button/button.module";



@NgModule({
  declarations: [
    DCImageComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ImageCropperModule,
    DCButtonModule
  ],
  exports: [
    DCImageComponent
  ]
})
export class DCImageModule { }
