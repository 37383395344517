import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: 'dc-map',
  templateUrl: './map.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DCMapComponent),
      multi: true
    }
  ]
})
export class DCMapComponent implements OnInit, ControlValueAccessor {

  @Input() value: any = null;
  @Input() formControlName: string;
  @Input() zoom: number = 11;
  @Input() center: any = [20.4489, 44.7866];
  @Input() interactive: boolean = true;
  @Input() interactiveMarker: boolean = true;
  @Input() markerCenter: any = null;
  @Input() height: any = '250px';

  @Output() coordinatesChange = new EventEmitter();

  onChange: (fn: any) => void;
  onTouched: (fn: any) => void;

  marker: boolean = false;
  showMap: boolean = false;

  constructor() { }

  ngOnInit() {
    setTimeout(() => {
      this.showMap = true;
      if (this.markerCenter) {
        this.marker = true;
        this.center = this.markerCenter;
      }
    }, 700);
  }

  writeValue(value: any) {
    this.value = value ? value : null;
    if (this.value) { this.markerCenter = this.value; }
  }

  registerOnChange(fn: any) { this.onChange = fn; }

  registerOnTouched(fn: any) { this.onTouched = fn; }

  changeCoordinates(event) {
    if (!this.interactive || !this.interactiveMarker) { return; }
    if (event.lngLat) {
      this.marker = true;
      this.center = [ event.lngLat.lng, event.lngLat.lat ];
      this.markerCenter = [ event.lngLat.lng, event.lngLat.lat ];
      this.coordinatesChange.emit({coordinates: [ event.lngLat.lng, event.lngLat.lat ]});
      if (this.onChange) { this.onChange(this.value = this.markerCenter)}
    }
  }

  onScroll() {
    event.stopPropagation();
    event.preventDefault();
  }

}
