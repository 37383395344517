<div class="dc-modal medium">
  <div class="dc-modal__header">
    <h2 class="dc-modal__title">{{ 'Add diagnosis' | translate }}</h2>
    <span class="dc-modal__close" (click)="close()"></span>
  </div>
  <div class="dc-modal__body" style="padding-bottom: 0">
      <dc-input
        type="search"
        [label]="'Diagnosis' | translate"
        [(ngModel)]="diseasesSearchTerm"
        [ngModelOptions]="{standalone: true}"
        (ngModelChange)="searchDiseases()"
      ></dc-input>
      <div style="position: relative;">
        <div class="disease-search-results" [class.empty]="diseasesSearchResults.length === 0">
          <div class="disease" *ngFor="let disease of diseasesSearchResults" [class.selected]="disease.id === selectedDiseases?.id" (click)="selectDiagnosis(disease)">
            <div>{{ disease.code }} {{ disease.name_serbian }}</div>
            <div class="name-latin">{{ disease.name_latin }}</div>
          </div>
        </div>
      </div>
    <hr style="margin: 0">
  </div>
  <div class="dc-modal__actions">
    <div class="dc-modal__action-left"></div>
    <div class="dc-modal__action-right">
      <dc-button color="outline" (click)="close()">{{ 'Cancel' | translate }}</dc-button>
      <dc-button [disabled]="!selectedDiseases" (click)="save()">{{ 'Apply' | translate }}</dc-button>
    </div>
  </div>
</div>
