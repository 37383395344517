import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { Institution } from '../../classes/institution';

@Component({
  selector: 'app-institution-select',
  templateUrl: './institution-select.component.html'
})
export class InstitutionSelectComponent {
  institutions: Institution[];

  constructor(
    private router: Router,
    private userService: UserService
  ) {
    this.getInstitutions();
  }

  getInstitutions() {
    this.userService.getCurrentUserInstitutions()
      .subscribe(
        (response) => {
          this.institutions = response.data;
        },
        () => { }
      );
  }

  selectInstitution(institution: Institution) {
    localStorage.setItem('currentInstitutionKey', institution.institution_guid);
    this.router.navigate(['/institution/schedule']);
  }

}
