<div class="dc-modal large">
  <div class="specialty-color" [style.background]="appointmentSlot.specialty.color"></div>
  <div class="dc-modal__header">
    <h2 class="dc-modal__title">{{ "Appointment slot" | translate }}</h2>
    <span class="dc-modal__close" (click)="close()"></span>
  </div>
  <div class="dc-modal__body" style="padding-top: 0">
    <div class="dc-row">
      <div class="dc-col md-12 no-margin">
        <div class="appointment-slot-time">
          <span>{{ appointmentSlot.starts_at | amDateFormat: "DD.MM.YYYY" }}</span>
          <span>&nbsp;|&nbsp;</span>
          <span>{{ appointmentSlot.starts_at | amDateFormat: "HH:mm" }} - {{ appointmentSlot.ends_at | amDateFormat: "HH:mm" }}</span>
          <span *ngIf="appointmentSlot.type === 'telemedicine' || appointmentSlot.appointment">&nbsp;|&nbsp;</span>
          <span *ngIf="appointmentSlot.type === 'telemedicine'" class="dc-icon indicator-telemedicine margin-right small"></span>
          <span *ngIf="appointmentSlot.appointment?.no_show" class="dc-icon no-patient margin-right small"></span>
          <span class="dc-icon indicator-{{ appointmentSlot.appointment?.origin }}"></span>
        </div>
      </div>
    </div>
    <div class="dc-row">
      <div class="dc-col md-6 margin-top m-small">
        <div class="appointment-slot-doctor">
          <span>{{ appointmentSlot.doctor.title }} {{ appointmentSlot.doctor.first_name }} {{ appointmentSlot.doctor.last_name }}</span>
          <span>&nbsp;|&nbsp;</span>
          <span>{{ appointmentSlot.specialty.name }}</span>
        </div>
      </div>
      <div class="dc-col md-6 no-margin end">
        <div class="appointment-slot-actions">
          <!-- <span class="dc-icon edit"></span> -->
          <span class="dc-icon delete dc-clickable" *ngIf="!appointmentSlot.appointment" (click)="delete()"></span>
        </div>
      </div>
    </div>
    <div class="dc-row">
      <div class="dc-col margin-vertical small">
        <hr />
      </div>
    </div>
    <ng-container *ngIf="!appointmentSlot.appointment">
      <ng-container *ngIf="!selectedPatient">
        <div class="dc-row">
          <div class="dc-col">
            <dc-select
              #search
              [options]="patients"
              [label]="'Patient' | translate"
              [(ngModel)]="patientId"
              [emptyStateText]="'The patient was not found' | translate"
              [notFoundText]="'The patient was not found' | translate"
              [addButtonText]="'Add patient' | translate"
              [showSearch]="true"
              [showAddButton]="true"
              [showPhoneNumber]="true"
              (addButtonClick)="addPatient($event)"
              (ngModelChange)="getSelectedPatient()"
              (searched)="getPatients($event)"
            ></dc-select>
          </div>
        </div>        
      </ng-container>
      <ng-container *ngIf="selectedPatient">
        <div class="dc-row">
          <div class="dc-col no-margin">
            <div class="appointment-patient-name">
              <span>{{ selectedPatient.first_name }} {{ selectedPatient.last_name }}</span>
              <span *ngIf="selectedPatient.parent_name"> ({{ selectedPatient.parent_name }})</span>
            </div>
            <div class="appointment-patient-date-of-birth" *ngIf="selectedPatient.date_of_birth">
              <span class="dc-icon date"></span>
              <span>{{ selectedPatient.date_of_birth | amDateFormat:'DD.MM.YYYY' }}</span>
            </div>
            <div class="appointment-patient-phone" *ngIf="selectedPatient.phone_1">
              <span class="dc-icon phone"></span>
              <span>{{ selectedPatient.phone_1 }}</span>
              <span *ngIf="selectedPatient.phone_2">|</span>
              <span *ngIf="selectedPatient.phone_2">{{ selectedPatient.phone_2 }}</span>
            </div>
          </div>
        </div>
        <div class="dc-row">
          <div class="dc-col md-6">
            <dc-select
              [options]="institutionServicesFiltered"
              [label]="'Service' | translate"
              [emptyStateText]="'The service was not found' | translate"
              [showSearch]="true"
              [loading]="loadingServices"
              [(ngModel)]="institutionServiceId"
              (searched)="filterInstitutionServices($event)"
            ></dc-select>
          </div>
        </div>
        <div class="dc-row">
          <div class="dc-col">
            <dc-input
              type="textarea"
              [label]="'Comment' | translate"
              [(ngModel)]="comment"
            ></dc-input>
          </div>
        </div>        
      </ng-container>
    </ng-container>
    <ng-container *ngIf="appointmentSlot.appointment">
      <div class="dc-row">
        <div class="dc-col no-margin">
          <div class="appointment-patient-name">
            <span>{{ appointmentSlot.appointment.patient.first_name }} {{ appointmentSlot.appointment.patient.last_name }}</span>
            <span *ngIf="appointmentSlot.appointment.patient.parent_name"> ({{ appointmentSlot.appointment.patient.parent_name }})</span>
          </div>
          <div class="appointment-patient-date-of-birth" *ngIf="appointmentSlot.appointment.patient.date_of_birth">
            <span class="dc-icon date"></span>
            <span>{{ appointmentSlot.appointment.patient.date_of_birth | amDateFormat:'DD.MM.YYYY' }}</span>
          </div>
          <div class="appointment-patient-phone" *ngIf="appointmentSlot.appointment.patient.phone_1">
            <span class="dc-icon phone"></span>
            <span>{{ appointmentSlot.appointment.patient.phone_1 }}</span>
            <span *ngIf="appointmentSlot.appointment.patient.phone_2">|</span>
            <span *ngIf="appointmentSlot.appointment.patient.phone_2">{{ appointmentSlot.appointment.patient.phone_2 }}</span>
          </div>
          <div class="appointment-service" *ngIf="appointmentSlot.appointment.service">
            <span>{{ appointmentSlot.appointment.service.name }}</span>
          </div>
          <div class="appointment-comment" *ngIf="appointmentSlot.appointment.comment">
            <h4>{{ 'Note' | translate }}</h4>
            <p>{{ appointmentSlot.appointment.comment }}</p>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="dc-modal__actions">
    <div class="dc-modal__action-left">
      <dc-button color="outline" (click)="close()">{{ "Close" | translate }}</dc-button>
    </div>
    <div class="dc-modal__action-right">
      <ng-container *ngIf="appointmentSlot.appointment">
        <dc-button (click)="cancel()" color="danger">{{ 'Cancel appointment' | translate }}</dc-button>
        <dc-button (click)="patientNotShow()">{{ (appointmentSlot.appointment.no_show ? 'Patient show' : 'Patient not show') | translate }}</dc-button>
        <dc-button (click)="showPatientRecord()">{{ 'Patient record' | translate }}</dc-button>
      </ng-container>
      <ng-container *ngIf="!appointmentSlot.appointment">
        <dc-button (click)="save()">{{ "Create appointment" | translate }}</dc-button>
      </ng-container>
    </div>
  </div>
</div>
