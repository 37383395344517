import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { DoctorService } from "../../services/doctor.service";
import { Doctor } from "../../classes/doctor";
import { SpecialtyService } from "../../services/specialty.service";
import { Specialty } from "../../classes/specialty";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'doc-filter-schedule',
  templateUrl: './filter-schedule.component.html'
})
export class FilterScheduleModalComponent implements OnInit {

  @Input() filters: {
    status: any,
    specialty: any,
    doctor: any
  }
  doctors: Doctor[];
  specialties: Specialty[];
  appointmentType: any[] = [
    {
      id: 'all',
      name: this.translateService.instant('All')
    },
    {
      id: 'busy',
      name: this.translateService.instant('Busy')
    },
    {
      id: 'open',
      name: this.translateService.instant('Open')
    }
  ];

  showLoading: boolean = false;

  constructor(
    private activeModal: NgbActiveModal,
    private doctorService: DoctorService,
    private specialtyService: SpecialtyService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.getSpecialities();
    this.getDoctors();
  }

  getSpecialities() {
    this.showLoading = true;
    const params = {
      pagination: false
    };
    this.specialtyService.getSpecialties(params)
      .subscribe(
        (response: any) => {
          this.specialties = response.data;
          this.showLoading = false;
        },
        () => {
          this.showLoading = false;
        }
      );
  }

  getDoctors() {
    this.showLoading = true;
    const params = {
      pagination: false
    };
    this.doctorService.getDoctors(params)
      .subscribe(
        (response: any) => {
          this.doctors = response.data;
          this.showLoading = false;
        },
        () => {
          this.showLoading = false;
        }
      );
  }

  apply() {
    this.activeModal.close(this.filters);
  }

  removeFilters() {
    this.filters = {
      status: null,
      specialty: null,
      doctor: null
    }
    this.activeModal.close(this.filters);
  }

  close() {
    this.activeModal.dismiss();
  }
}
