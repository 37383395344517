<div class="dc-modal medium">
    <div class="dc-modal__header">
        <h2 class="dc-modal__title">{{ 'Cancel appointment' | translate }}</h2>
        <span class="dc-modal__close" (click)="close()"></span>
    </div>
    <div class="dc-modal__body">
        <form [formGroup]="appointmentCancelFrom">
            <div class="dc-row">
                <div class="dc-col">
                    <dc-input
                        type="textarea"
                        formControlName="reason"
                        [label]="'Reason for cancellation' | translate"
                    ></dc-input>
                </div>
            </div>
        </form>
    </div>
    <div class="dc-modal__actions">
        <div class="dc-modal__action-left">
            <dc-button color="outline" (click)="close()">{{ 'Cancel' | translate }}</dc-button>
        </div>
        <div class="dc-modal__action-right">
            <dc-button (click)="cancelAppointment()" [disabled]="appointmentCancelFrom.invalid">{{ 'Cancel appointment' | translate }}</dc-button>
        </div>
    </div>
</div>
