<div class="dc-modal medium">
    <div class="dc-modal__header">
        <h2 class="dc-modal__title">{{ 'Create appointment' | translate }}</h2>
        <span class="dc-modal__close" (click)="close()"></span>
    </div>
    <div class="dc-modal__body">
        <div>
            <dc-progress-bar [currentStep]="currentStep">
                <dc-progress-content>
                    <div class="dc-row">
                        <div class="dc-col">
                            <div>
                                <span>{{ appointmentSlot.starts_at | amDateFormat:'DD.MM.YYYY' }}&nbsp;</span>
                                <span>{{ appointmentSlot.starts_at | amDateFormat:'HH:mm' }} - {{ appointmentSlot.ends_at | amDateFormat:'HH:mm' }}</span>
                            </div>
                            <div>
                                <span>{{ appointmentSlot.specialty.name }},&nbsp;</span>
                                <span>{{ appointmentSlot.doctor.title }} {{ appointmentSlot.doctor.first_name }} {{ appointmentSlot.doctor.last_name }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="dc-row">
                        <div class="dc-col">
                            <hr>
                        </div>
                    </div>
                    <div class="dc-row">
                        <div class="dc-col">
                            <dc-select #search
                                [options]="patients"
                                [label]="'Patient' | translate"
                                [(ngModel)]="patientId"
                                [emptyStateText]="'The patient was not found' | translate"
                                [notFoundText]="'The patient was not found' | translate"
                                [addButtonText]="'Add patient' | translate"
                                [showSearch]="true"
                                [showAddButton]="true"
                                [showPhoneNumber]="true"
                                (addButtonClick)="addPatient($event)"
                                (ngModelChange)="getSelectedPatient()"
                                (searched)="getPatients($event)"
                            ></dc-select>
                        </div>
                    </div>
                </dc-progress-content>
                <dc-progress-content>
                    <div class="dc-row">
                        <div class="dc-col">
                            <dc-select
                                [options]="institutionServices"
                                [label]="'Service' | translate"
                                [emptyStateText]="'The service was not found' | translate"
                                [showSearch]="true"
                                [(ngModel)]="institutionServiceId"
                                (searched)="getInstitutionServices($event)"
                            ></dc-select>
                        </div>
                    </div>
                    <div class="dc-row">
                        <div class="dc-col">
                            <dc-input
                                type="textarea"
                                [label]="'Comment' | translate"
                                [(ngModel)]="comment"
                            ></dc-input>
                        </div>
                    </div>
                </dc-progress-content>
                <dc-progress-content>
                    <div class="dc-row">
                        <div class="dc-col">
                            <div>
                                <span>{{ appointmentSlot.starts_at | amDateFormat:'DD.MM.YYYY' }}&nbsp;</span>
                                <span>{{ appointmentSlot.starts_at | amDateFormat:'HH:mm' }} - {{ appointmentSlot.ends_at | amDateFormat:'HH:mm' }}</span>
                            </div>
                            <div>
                                <span>{{ appointmentSlot.specialty.name }},&nbsp;</span>
                                <span>{{ appointmentSlot.doctor.title }} {{ appointmentSlot.doctor.first_name }} {{ appointmentSlot.doctor.last_name }}</span>
                            </div>
                            <div>
                                <span>{{ comment }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="dc-row">
                        <div class="dc-col">
                            <hr>
                        </div>
                    </div>
                    <div class="dc-row" *ngIf="selectedPatient">
                        <div class="dc-col md-12">
                            <label translate>Patient</label>
                            <div>{{ selectedPatient.first_name }} {{ selectedPatient.last_name }}</div>
                        </div>
                    </div>
                    <div class="dc-row" *ngIf="selectedPatient">
                        <div class="dc-col md-6">
                            <label translate>Birth date</label>
                            <div *ngIf="selectedPatient.date_of_birth">{{ selectedPatient.date_of_birth | amDateFormat:'DD.MM.YYYY' }}</div>
                            <div *ngIf="!selectedPatient.date_of_birth">-</div>
                            <div>&nbsp;</div>
                            <label translate>Email</label>
                            <div>{{ selectedPatient.email ? selectedPatient.email : '-' }}</div>
                        </div>
                        <div class="dc-col md-6">
                            <label translate>Phone 1</label>
                            <div>{{ selectedPatient.phone_1 ? selectedPatient.phone_1 : '-' }}</div>
                            <div>&nbsp;</div>
                            <label translate>Phone 2</label>
                            <div>{{ selectedPatient.phone_2 ? selectedPatient.phone_2 : '-' }}</div>
                        </div>
                    </div>
                </dc-progress-content>
            </dc-progress-bar>
        </div>
    </div>
    <div class="dc-modal__actions">
        <div class="dc-modal__action-left">
            <dc-button color="outline" (click)="close()">{{ 'Cancel' | translate }}</dc-button>
        </div>
        <div class="dc-modal__action-right">
            <dc-button *ngIf="currentStep > 1" (click)="back()">{{ 'Back' | translate }}</dc-button>
            &nbsp;
            <dc-button *ngIf="currentStep < 3" [disabled]="!patientId" (click)="next()">{{ 'Next' | translate }}</dc-button>
            <dc-button *ngIf="currentStep === 3" (click)="save()">{{ 'Add' | translate }}</dc-button>
        </div>
    </div>
</div>
