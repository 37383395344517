import { Specialty } from './specialty';
import { InstitutionSettings } from './institution-settings';

export class Institution {
  id: number;
  institution_guid: string;
  slug: string;
  name: string;
  logo_url: string;
  country_id: string;
  city: string;
  address: string;
  description: string;
  latitude: number;
  longitude: number;
  email: string;
  phone_1: string;
  phone_2: string;
  wheelchair_accessible: boolean;
  parking_available: boolean;
  transportation_bus: string;
  transportation_trolley: string;
  transportation_tram: string;

  specialties?: Specialty[];
  settings?: InstitutionSettings;

  constructor(values: object = {}) {
    Object.assign(this, values);
  }
}
