import { Component, OnInit, ViewChild } from '@angular/core';
import { InstitutionService } from '../../services/institution.service';
import { PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'main[dc-user-list]',
  templateUrl: './user-list.component.html'
})
export class UserListComponent implements OnInit {
  @ViewChild(PerfectScrollbarDirective, { static: false }) perfectScrollbarDirectiveRef?: PerfectScrollbarDirective;
  showSubheader: boolean = false;
  users: any;
  searchTerm: string = '';

  pagination: any = {
    total: 0,
    current_page: 1,
    per_page: 15
  };

  loadingUsers: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private institutionService: InstitutionService
  ) { }

  ngOnInit() {
    this.getInstitutionUsers();
  }

  toggleSubheader() {
    this.showSubheader = !this.showSubheader;
  }

  getInstitutionUsers(append: boolean = false) {
    if (append && this.pagination.total && this.users.length >= this.pagination.total) { return; }

    this.pagination.current_page = append ? this.pagination.current_page + 1 : 1;

    const params: any = {
      current_page: this.pagination.current_page,
      per_page: this.pagination.per_page,
      include: 'roles'
    };
    if (this.searchTerm.length > 0) {
      params.search_term = this.searchTerm;
    }
    this.loadingUsers = !append;
    this.institutionService
      .getInstitutionUsers(params)
      .subscribe(
        (response) => {
          if (append) {
            this.users = this.users.concat(response.data);
          } else {
            this.perfectScrollbarDirectiveRef.scrollToTop();
            this.users = response.data;
            this.pagination.total = response.total;
          }
          this.loadingUsers = false;
        },
        (error) => {
          this.loadingUsers = false;
        }
      );
  }

}
