import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { Institution } from '../../classes/institution';
import { InstitutionService } from '../../services/institution.service';
import { Country } from '../../classes/country';
import { CountryService } from '../../services/country.service';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DCToastService } from 'libs/ui/src/lib/components/toast/toast.service';
import { Specialty } from '../../classes/specialty';
import { SpecialtyService } from '../../services/specialty.service';
import { User } from '../../classes/user';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'main [dc-institution-details]',
  templateUrl: './institution-details.component.html'
})
export class InstitutionDetailsComponent implements OnInit, OnDestroy {
  user: User;
  institutionForm: FormGroup;
  specialties: Specialty[] = [];
  countries: Country[] = [];
  institution: Institution = new Institution();

  loadingInstitution: boolean = true;
  savingInstitution: boolean = false;

  showSubheader: boolean = false;

  subscriptions: Subscription[] = [];

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 's') {
      this.save(false);
      event.preventDefault();
      return false;
    }
  }

  constructor(
    private formBuilder: FormBuilder,
    private toasterService: DCToastService,
    private countryService: CountryService,
    private specialtyService: SpecialtyService,
    private institutionService: InstitutionService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.initForm();
    this.getCountries();
    this.getSpecialties();
    this.getUser();
    this.loadingInstitution = true;
    this.institutionService.currentInstitution
      .subscribe(
        (institution: Institution) => {
          if (institution) {
            this.institution = institution;
            this.institutionForm.patchValue(institution);
            this.loadingInstitution = false;
          }
        }
      );
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  initForm() {
    this.institutionForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      type: [''],
      country_id: [null],
      city: [''],
      address: [''],
      email: [''],
      phone_1: [''],
      phone_2: [''],
      description: [''],
      latitude: [''],
      longitude: [''],
      logo_url: [''],
      show_in_application: [false],
      show_on_website: [false],
      parking_available: [false],
      wheelchair_accessible: [false],
      transportation_bus: [''],
      transportation_tram: [''],
      transportation_trolley: [''],
      specialty_ids: [[]],
    });
  }

  getCountries() {
    this.countryService
      .getCountries()
      .subscribe(
        (response) => {
          this.countries = response.data;
        },
        () => {
          this.toasterService.show({
            message: 'Failed to load countries.',
            type: 'error'
          });
        }
      );
  }

  getSpecialties() {
    const params = {
      pagination: false
    }
    this.specialtyService
      .getSpecialties(params)
      .subscribe(
        (response) => {
          this.specialties = response.data;
        },
        () => {
          this.toasterService.show({
            message: 'Failed to load specialties.',
            type: 'error'
          });
        }
      );
  }

  getUser() {
    this.userService.currentUser
      .subscribe(
        (response) => {
          this.user = response;
        },
        () => {},
      );
  }

  dragEnd(marker) {
    const lngLat = marker.getLngLat();
    this.institution.latitude = lngLat.lat;
    this.institution.longitude = lngLat.lng;
  }

  toggleParkingAvailable() {
    this.institution.parking_available = !this.institution.parking_available;
  }

  toggleWheelchairAccessible() {
    this.institution.wheelchair_accessible = !this.institution.wheelchair_accessible;
  }

  toggleSubheader() {
    this.showSubheader = !this.showSubheader;
  }

  save(close: boolean = false) {
    this.savingInstitution = true;
    this.institutionService
      .updateCurrentInstitution(this.institutionForm.value)
      .subscribe(
        () => {
          this.toasterService.show({
            message: 'Institution was successfully updated.',
            type: 'success'
          });
          this.institutionService.getCurrentInstitution();
          this.savingInstitution = false;
        },
        () => {
          this.toasterService.show({
            message: 'Failed to save institution.',
            type: 'error'
          });
          this.savingInstitution = false;
        }
      );
  }

}
