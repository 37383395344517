<div class="dc-subheader"  [class.show-nav]="showSubheader">
  <div class="dc-container">
    <div class="dc-row middle">
      <div class="dc-col xs-12">
        <ul class="navigation">
          <li>
            <a [routerLink]="['/institution', 'details']" translate>Settings</a>
          </li>
          <li>
            <a [routerLink]="['/institution', 'template', 'list']" translate>Templates</a>
          </li>
          <li>
            <a [routerLink]="['/institution', 'service', 'list']" translate>Services</a>
          </li>
          <li>
            <a [routerLink]="['/institution', 'promotion', 'list']" routerLinkActive="active" translate>Promotions</a>
          </li>
          <li>
            <a [routerLink]="['/institution', 'doctor', 'list']" translate>Doctors</a>
          </li>
          <li>
            <a [routerLink]="['/institution', 'user', 'list']" translate>Users</a>
          </li>
        </ul>
        <div class="subheader-responsive">
          <span translate>Information</span>
          <span class="nav-toggle" (click)="toggleSubheader()">...</span>
          <span class="dc-icon remove-close" (click)="toggleSubheader()"></span>
        </div>
      </div>
    </div>
  </div>
</div>
<section class="dc-section dc-content" [perfectScrollbar]="{ suppressScrollX: true}">
  <div [dcLoading]="loadingPromotion"></div>
  <div class="dc-container" *ngIf="!loadingPromotion">
    <div class="dc-row">
      <div class="dc-col lg-6">
        <h1>
          <div class="super-heading" translate>Promotion</div>
          <span *ngIf="promotion && promotion.id">{{ promotion.title }}</span>
          <span *ngIf="!promotionId">{{ 'New promotion' | translate }}</span>
        </h1>
      </div>
      <div class="dc-col lg-6 flex padding-bottom" style="flex-direction: row; justify-content: flex-end;">
        <dc-button *ngIf="promotionId" color="outline" icon="delete" size="small" [loading]="deletingPromotion" (click)="delete()">{{ 'Delete promotion' | translate }}</dc-button>
      </div>
    </div>
    <form class="dc-row space-32" [formGroup]="promotionForm">
      <div class="dc-col md-6">
        <div class="dc-row space-16">
          <div class="dc-col md-12">
            <dc-input
              label="{{ 'Name' | translate }}"
              formControlName="title"
              [errorMessages]="{ required: 'Required field' | translate }"
            ></dc-input>
          </div>
          <div class="dc-col md-12">
            <dc-text-editor
              label="{{ 'Description' | translate }}"
              placeholder="Enter text here..."
              formControlName="description"
            ></dc-text-editor>
          </div>
          <div class="dc-col md-6">
            <dc-datepicker
              label="{{ 'Start date' | translate }}"
              type="calendar"
              formControlName="date_from"
            ></dc-datepicker>
          </div>
          <div class="dc-col md-6">
            <dc-datepicker
              label="{{ 'End date' | translate }}"
              type="calendar"
              formControlName="date_to"
            ></dc-datepicker>
          </div>
        </div>
      </div>
      <div class="dc-col md-6">
        <div class="dc-row space-16">
          <div class="dc-col md-8">
            <dc-image
              formControlName="image_big"
              label="{{'Add photo' | translate}}"
              maxSize="512000"
              [cropperAspectRation]="5/3"
              [cropperHeight]="288"
              [cropperWidth]="480"
              [type]="'large'"
            ></dc-image>
          </div>
          <div class="dc-col md-4">
        <span class="dc-text-color__gray-medium">Slika mora da bude: <br>
          •  dimenzija 480x288px <br>
          •  u formatu .JPG ili .PNG <br>
          •  veličine do 500kB
        </span>
          </div>
        </div>
      </div>
    </form>
  </div>
</section>
<div class="dc-bottom-bar">
  <div class="dc-container flex" style="height: 100%; align-items: center;">
    <dc-button size="small" color="secondary" (click)="close()">{{ 'Close' | translate }}</dc-button>
    &nbsp;&nbsp;
    <dc-button size="small" (click)="save()">{{ 'Save' | translate }}</dc-button>
    &nbsp;&nbsp;
    <dc-button size="small" (click)="save(true)">{{ 'Save & Close' | translate }}</dc-button>
  </div>
</div>
