import { Injectable } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfig } from './_classes/modal-config';
import { DCAlertComponent } from './alert/alert.component';
import { DCConfirmComponent } from './confirm/confirm.component';
import { DCErrorComponent } from './error/error.component';
import { ModalAlertConfig } from './_classes/modal-alert-config';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(
      private modalService: NgbModal,
      private activeModal: NgbActiveModal,
  ) { }

  open(content: any, size?: string) {
    return this.modalService.open( content );
  }

  close() {
    this.activeModal.dismiss();
  }

  dismiss() {
    this.activeModal.dismiss();
  }

  alert(config: ModalAlertConfig) {
    const params: any = {};
    if (config.disableOffclick) {
      params.windowClass = 'click-disabled'
    }
    const modalRef = this.modalService.open( DCAlertComponent , params );
    modalRef.componentInstance.type = config.type;
    modalRef.componentInstance.body = config.body;
    modalRef.componentInstance.button = config.button;
    if (config.title) {
      modalRef.componentInstance.title = config.title;
    }
    if (config.size) {
      modalRef.componentInstance.size = config.size;
    }
  }

  confirm(config: ModalConfig) {
    const params: any = {};
    if (config.disableOffclick) {
      params.windowClass = 'click-disabled'
    }
    const modalRef = this.modalService.open( DCConfirmComponent, params);
    modalRef.componentInstance.type = config.type;
    modalRef.componentInstance.body = config.body;
    modalRef.componentInstance.buttons = config.buttons;
    if (config.title) {
      modalRef.componentInstance.title = config.title;
    }
    if (config.size) {
      modalRef.componentInstance.size = config.size;
    }
  }

  error(config: ModalConfig) {
    const params: any = {};
    if (config.disableOffclick) {
      params.windowClass = 'click-disabled'
    }
    const modalRef = this.modalService.open( DCErrorComponent, params);
    modalRef.componentInstance.errors = config.errors;
    modalRef.componentInstance.buttons = config.buttons;
    if (config.title) {
      modalRef.componentInstance.title = config.title;
    }
    if (config.size) {
      modalRef.componentInstance.size = config.size;
    }
  }
}
