<div class="dc-modal small">
    <div class="dc-modal__header">
        <h2 class="dc-modal__title">{{ 'Add appointment slots' | translate }}</h2>
        <span class="dc-modal__close" (click)="close()"></span>
    </div>
    <div class="dc-modal__body" perfectScrollbar>
        <form [formGroup]="appointmentSlotFrom">
            <dc-progress-bar [currentStep]="currentStep">
                <dc-progress-content>
                    <ng-container formGroupName="step_1">
                        <div class="dc-row">
                            <div class="dc-col">
                                <dc-select
                                    [showColor]="true"
                                    [options]="specialtyOptions"
                                    [loading]="loadingSpecialties"
                                    formControlName="specialty_id"
                                    [label]="'Specialty' | translate"
                                    [emptyStateText]="'There are no specialties for selected doctor' | translate"
                                    [placeholder]="'Select specialty' | translate"
                                    [errorMessages]="{ required: 'You need to select a specialty' | translate }"
                                ></dc-select>
                            </div>
                        </div>
                        <div class="dc-row">
                            <div class="dc-col">
                                <dc-select
                                    [showImage]="true"
                                    [options]="doctorOptions"
                                    [loading]="loadingDoctors"
                                    formControlName="doctor_id"
                                    [label]="'Doctor' | translate"
                                    [emptyStateText]="'There are no doctors for selected specialty' | translate"
                                    [placeholder]="'Select doctor' | translate"
                                    [errorMessages]="{ required: 'You need to select a doctor' | translate }"
                                ></dc-select>
                            </div>
                        </div>
                      <div class="dc-row">
                        <div class="dc-col">
                          <label>{{'Type' | translate}}</label>
                        </div>
                        <div class="dc-col md-6">
                          <dc-radio [label]="'In the office' | translate" [formControlName]="'type'" [name]="'type'" [value]="'default'"></dc-radio>
                        </div>
                        <div class="dc-col md-6">
                          <dc-radio [label]="'Telemedicine' | translate " [formControlName]="'type'" [name]="'type'" [value]="'telemedicine'"></dc-radio>
                        </div>
                      </div>
                    </ng-container>
                </dc-progress-content>
                <dc-progress-content>
                    <div class="dc-row space-12 padding-top">
                        <div class="dc-col md-6">
                            <dc-radio
                                [label]="'One appoinment slot' | translate"
                                [value]="false"
                                [(ngModel)]="multi"
                                [ngModelOptions]="{ standalone: true }"
                            ></dc-radio>
                        </div>
                        <div class="dc-col md-6">
                            <dc-radio
                                [label]="'Multiple appoinment slots' | translate"
                                [value]="true"
                                [(ngModel)]="multi"
                                [ngModelOptions]="{ standalone: true }"
                            ></dc-radio>
                        </div>
                    </div>
                    <ng-container formGroupName="step_2_single" *ngIf="!multi">
                        <div class="dc-row space-12">
                            <div class="dc-col md-12">
                                <dc-datepicker
                                    [label]="'Date' | translate"
                                    [min]="now"
                                    formControlName="date"
                                    type="calendar"
                                    [errorMessages]="{required: 'Required field' | translate}"
                                ></dc-datepicker>
                            </div>
                        </div>
                        <div class="dc-row space-12">
                            <div class="dc-col md-12">
                                <dc-datepicker
                                    [label]="'Time' | translate"
                                    formControlName="time"
                                    type="timer"
                                    [errorMessages]="{required: 'Required field' | translate}"
                                ></dc-datepicker>
                            </div>
                        </div>
                        <div class="dc-row space-12">
                            <div class="dc-col md-12">
                                <dc-select
                                    [label]="'Duration' | translate"
                                    formControlName="duration"
                                    [options]="durationOptions"
                                    [errorMessages]="{required: 'Required field' | translate}"
                                ></dc-select>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container formGroupName="step_2_multiple" *ngIf="multi">
                        <div class="dc-row space-12">
                            <div class="dc-col md-6">
                                <dc-datepicker
                                    [label]="'Start date' | translate"
                                    [min]="now"
                                    formControlName="date_from"
                                    type="calendar"
                                ></dc-datepicker>
                            </div>
                            <div class="dc-col md-6">
                                <dc-datepicker
                                    [label]="'End date' | translate"
                                    formControlName="date_to"
                                    type="calendar"
                                ></dc-datepicker>
                            </div>
                        </div>
                        <div class="dc-row space-12">
                            <div class="dc-col md-6">
                                <dc-datepicker
                                    [label]="'Start time' | translate"
                                    formControlName="time_from"
                                    type="timer"
                                ></dc-datepicker>
                            </div>
                            <div class="dc-col md-6">
                                <dc-datepicker
                                    [label]="'End time' | translate"
                                    formControlName="time_to"
                                    type="timer"
                                ></dc-datepicker>
                            </div>
                        </div>
                        <div class="dc-row space-12">
                            <div class="dc-col md-12">
                                <dc-select
                                    [label]="'Duration' | translate"
                                    formControlName="duration"
                                    [options]="durationOptions"
                                ></dc-select>
                            </div>
                        </div>
                        <div class="dc-row space-12">
                            <div class="dc-col md-12">
                                <dc-select
                                    [multi]="true"
                                    [label]="'Apply to days' | translate"
                                    formControlName="week_days"
                                    [options]="dayOptions"
                                    [showSelectAll]="true"
                                ></dc-select>
                            </div>
                        </div>
                    </ng-container>
                </dc-progress-content>
                <dc-progress-content>
                    <div class="dc-row" *ngIf="!multi">
                        <div class="dc-col md-12 padding-top">
                            <label translate>Specialty and doctor</label>
                            <div>{{ selectedSpecialty?.name }}</div>
                            <div>{{ selectedDoctor?.title }} {{ selectedDoctor?.first_name }} {{ selectedDoctor?.last_name }}</div>
                        </div>
                      <div class="dc-col md-12 padding-top">
                        <label translate>Type</label>
                        <div>{{ appointmentSlotFrom.value.step_1.type === 'ordination' ? ('In the office' | translate) : ('Telemedicine' | translate)}}</div>
                      </div>
                        <div class="dc-col md-12 padding-top">
                            <label translate>Date and time</label>
                            <div>{{ appointmentSlotFrom.value.step_2_single.date | amDateFormat:'DD.MM.YYYY' }} {{ appointmentSlotFrom.value.step_2_single.time | slice:0:5 }}</div>
                        </div>
                        <div class="dc-col md-12 padding-top">
                            <label translate>Duration</label>
                            <div>{{ appointmentSlotFrom.value.step_2_single.duration }} min</div>
                        </div>
                      <div class="dc-col md-12 padding-top">
                        <dc-checkbox [label]="'Make an appointment' | translate" [(ngModel)]="showAppointmentCreate" [ngModelOptions]="{standalone: true}"></dc-checkbox>
                      </div>
                    </div>
                    <div class="dc-row" *ngIf="multi">
                        <div class="dc-col md-12 padding-top">
                            <label translate>Specialty and doctor</label>
                            <div>{{ selectedSpecialty?.name }}</div>
                            <div>{{ selectedDoctor?.title }} {{ selectedDoctor?.first_name }} {{ selectedDoctor?.last_name }}</div>
                        </div>
                        <div class="dc-col md-12 padding-top">
                            <label translate>Date and time</label>
                            <div>{{ appointmentSlotFrom.value.step_2_multiple.date_from | amDateFormat:'DD.MM.YYYY' }} - {{ appointmentSlotFrom.value.step_2_multiple.date_to | amDateFormat:'DD.MM.YYYY' }}</div>
                            <div>{{ appointmentSlotFrom.value.step_2_multiple.time_from | slice:0:5 }} - {{ appointmentSlotFrom.value.step_2_multiple.time_to | slice:0:5 }}</div>
                        </div>
                        <div class="dc-col md-12 padding-top">
                            <label translate>Duration</label>
                            <div>{{ appointmentSlotFrom.value.step_2_multiple.duration }} min</div>
                        </div>
                    </div>
                </dc-progress-content>
            </dc-progress-bar>
        </form>
    </div>
    <div class="dc-modal__actions">
        <div class="dc-modal__action-left">
            <dc-button color="outline" (click)="close()">{{ 'Cancel' | translate }}</dc-button>
        </div>
        <div class="dc-modal__action-right">
            <dc-button *ngIf="currentStep > 1" (click)="back()">{{ 'Back' | translate }}</dc-button>
            &nbsp;
            <dc-button *ngIf="currentStep === 1" [disabled]="step_1.invalid" (click)="next()">{{ 'Next' | translate }}</dc-button>
            <dc-button *ngIf="currentStep === 2" [disabled]="(step_2_single.invalid && !multi) || (step_2_multiple.invalid && multi)" (click)="next()">{{ 'Next' | translate }}</dc-button>
            <dc-button *ngIf="currentStep === 3" (click)="save()">{{ 'Add' | translate }}</dc-button>
        </div>
    </div>
</div>
