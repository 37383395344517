import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Institution } from '../classes/institution';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class InstitutionService {
  private currentInstitutionSubject = new BehaviorSubject<Institution>(null as Institution);
  currentInstitution = this.currentInstitutionSubject.asObservable();

  constructor(
    private apiService: ApiService
  ) { }

  getInstitution(institutionId: any, query: any = {}) {
    return this.apiService.get(`institution/${institutionId}`, query);
  }

  getCurrentInstitution() {
    this.getInstitution('current', { include: 'specialties,settings' })
      .subscribe(
        response => {
          this.currentInstitutionSubject.next(response.data);
        }
      );
  }

  updateCurrentInstitution(institution: Institution) {
    return this.apiService.put(`institution/current`, institution);
  }

  uploadLogo(formData: FormData) {
    return this.apiService.post(`institution/current/logo`, formData);
  }

  removeLogo() {
    return this.apiService.delete(`institution/current/logo`);
  }

  getCurrentInstitutionSettings(query: any = {}) {
    return this.apiService.get(`institution/current/settings`, query);
  }

  updateCurrentInstitutionSettings(institutionSettingsId: number, query: any = {}) {
    return this.apiService.post(`institution/current/settings`, query);
  }

  getInstitutionTemplates(query: any = {}) {
    return this.apiService.get(`institution/current/templates`, query);
  }

  getInstitutionTemplate(institutionTemplateId: number, query: any = {}) {
    return this.apiService.get(`institution/current/templates/${institutionTemplateId}`, query);
  }

  createInstitutionTemplates(query: any = {}) {
    return this.apiService.post(`institution/current/templates`, query);
  }

  updateInstitutionTemplates(institutionTemplateId: number, query: any = {}) {
    return this.apiService.put(`institution/current/templates/${institutionTemplateId}`, query);
  }

  deleteInstitutionTemplates(institutionTemplateId: number) {
    return this.apiService.delete(`institution/current/templates/${institutionTemplateId}`);
  }

  getInstitutionServices(query: any = {}) {
    return this.apiService.get(`institution/current/services`, query);
  }

  getInstitutionService(institutionServiceId: number) {
    return this.apiService.get(`institution/current/services/${institutionServiceId}`);
  }

  createInstitutionService(query: any = {}) {
    return this.apiService.post(`institution/current/services`, query);
  }

  updateInstitutionService(institutionServiceId: number, query: any = {}) {
    return this.apiService.put(`institution/current/services/${institutionServiceId}`, query);
  }

  deleteInstitutionService(institutionServiceId: number) {
    return this.apiService.delete(`institution/current/services/${institutionServiceId}`);
  }

  getInstitutionUsers(query: any = {}) {
    return this.apiService.get(`institution/current/users`, query);
  }

  getInstitutionUser(institutionUserId: number, query: any = {}) {
    return this.apiService.get(`institution/current/users/${institutionUserId}`, query);
  }

  createInstitutionUser(query: any = {}) {
    return this.apiService.post(`institution/current/users`, query);
  }

  updateInstitutionUser(institutionUserId: number, query: any = {}) {
    return this.apiService.put(`institution/current/users/${institutionUserId}`, query);
  }

  deleteInstitutionUser(institutionUserId: number) {
    return this.apiService.delete(`institution/current/users/${institutionUserId}`);
  }

  getInstitutionRoles(query: any = {}) {
    return this.apiService.get(`roles`, query);
  }
}
