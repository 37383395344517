<div class="dc-tabs">
    <nav class="dc-tabs__nav">
        <ul>
            <li *ngFor="let tab of tabs"
                [class.active]="tab.active"
                (click)="select(tab)">
                <a>{{ tab.title }}</a>
            </li>
        </ul>
    </nav>
</div>
<ng-content></ng-content>
