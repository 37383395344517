import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PatientService } from '../../services/patient.service';
import { CountryService } from '../../services/country.service';
import { Patient } from '../../classes/patient';
import { PatientDocument } from '../../classes/patient-document';
import { PatientVisit } from '../../classes/patient-visit';
import { Country } from '../../classes/country';
import { Appointment } from '../../classes/appointment';
import { AppointmentService } from '../../services/appointment.service';
import { InvitationModalComponent } from '../../modals/invitation/invitation-modal.component';
import { Invitation } from '../../classes/invitation';
import { InvitationService } from '../../services/invitation.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DCToastService } from 'libs/ui/src/lib/components/toast/toast.service';
import { ModalService } from 'libs/ui/src/public-api';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'main [dc-patient-details]',
  templateUrl: './patient-details.component.html'
})
export class PatientDetailsComponent implements OnInit, OnDestroy {
  patient: Patient = new Patient();
  patientForm: FormGroup;
  countries: Country[] = [];
  patientDocuments: PatientDocument[] = [];
  patientVisits: PatientVisit[] = [];
  patientAppointments: Appointment[] = [];
  invitations: Invitation[] = [];
  genderOptions: any[] = [
    { id: 'F', name: this.translate.instant('Female') },
    { id: 'M', name: this.translate.instant('Male') }
  ];
  bloodTypeOptions: any[] = [
    { id: 'AB-', name: 'AB-' },
    { id: 'AB+', name: 'AB+' },
    { id: 'A-', name: 'A-' },
    { id: 'A+', name: 'A+' },
    { id: 'B-', name: 'B-' },
    { id: 'B+', name: 'B+' },
    { id: 'O-', name: 'O-' },
    { id: 'O+', name: 'O+' }
  ];

  sendInvitationAutomatically = true;
  showPatientInformation = false;

  loadingPatient: boolean = false;
  savingPatient: boolean = false;
  deletingPatient: boolean = false;
  loadingCountries: boolean = false;
  loadingPatientVisits: boolean = false;
  loadingPatientDocuments: boolean = false;
  loadingPatientAppointments: boolean = false;

  subscriptions: Subscription[] = [];

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 's') {
      this.save(false);
      event.preventDefault();
      return false;
    }
    if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 'd') {
      this.delete();
      event.preventDefault();
      return false;
    }
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private toasterService: DCToastService,
    private modalService: ModalService,
    private countryService: CountryService,
    private patientService: PatientService,
    private invitationService: InvitationService,
    private appointmentService: AppointmentService
  ) { }

  ngOnInit() {
    this.initForm();
    this.getCountries();

    const routeSubscription = this.route.params.subscribe(
      (data) => {
        if (data.patientId) {
          this.patient.id = +data.patientId;

          this.getPatient();
          this.getPatientDocuments();
          this.getPatientVisits();
          this.getPatientAppointments();
          this.getInvitations();

          this.sendInvitationAutomatically = false;
        } else {
          this.showPatientInformation = true;
        }
      }
    );
    this.subscriptions.push(routeSubscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  initForm() {
    this.patientForm = this.formBuilder.group({
      id: [''],
      patient_number: [''],
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      parent_name: [''],
      blood_type: [''],
      country_id: [''],
      city: [''],
      address: [''],
      phone_1: [''],
      phone_2: [''],
      date_of_birth: [''],
      gender: [''],
      email: ['', [Validators.email]],
      height: [''],
      weight: [''],
      jmbg: ['', [Validators.minLength(13), Validators.maxLength(13)]],
      photo_url: [''],
      anamnesis_doctor: [''],
    });
  }

  getCountries() {
    this.loadingCountries = true;
    this.countryService.getCountries()
      .subscribe(
        (response) => {
          this.countries = response.data;
          this.loadingCountries = false;
        },
        () => {
          this.toasterService.show({
            message: 'Failed to load countries.',
            type: 'error'
          });
          this.loadingCountries = false;
        }
      );
  }

  getPatient() {
    this.loadingPatient = true;
    this.patientService
      .getPatient(this.patient.id, { include: 'user' })
      .subscribe(
        (response: { data: Patient }) => {
          this.patientForm.patchValue(response.data);
          this.patient = response.data;
          this.loadingPatient = false;
        },
        () => {
          this.toasterService.show({
            message: 'Failed to load patient.',
            type: 'error'
          });
          this.loadingPatient = false;
          this.close();
        }
      );
  }

  getPatientDocuments() {
    const params = {
      sort: 'date',
      direction: 'desc'
    };
    this.loadingPatientDocuments = true;
    this.patientService
      .getPatientDocuments(this.patient.id, params)
      .subscribe(
        (response) => {
          this.patientDocuments = response.data;
          this.loadingPatientDocuments = false;
        },
        () => {
          this.toasterService.show({
            message: 'Failed to load patient documents.',
            type: 'error'
          });
          this.loadingPatientDocuments = false;
        }
      );
  }

  getInvitations() {
    this.patientService
      .getPatientInvitations(this.patient.id)
      .subscribe(
        (response) => {
          this.invitations = response.data;
        },
        () => {
          this.toasterService.show({
            message: 'Failed to load patient invitations.',
            type: 'error'
          });
        }
      );
  }

  getPatientVisits() {
    const params = {
      sort: 'datetime',
      direction: 'desc',
      pagination: false,
      include: 'doctor,specialty,diagnoses,diagnoses.disease'
    };
    this.loadingPatientVisits = true;
    this.patientService
      .getPatientVisits(this.patient.id, params)
      .subscribe(
        (response) => {
          this.patientVisits = response.data;
          this.patientVisits.sort((a, b) => a.datetime > b.datetime ? -1 : (a.datetime < b.datetime ? 1 : 0));
          this.loadingPatientVisits = false;
        },
        () => {
          this.toasterService.show({
            message: 'Failed to load patient visits.',
            type: 'error'
          });
          this.loadingPatientVisits = false;
        }
      );
  }

  getPatientAppointments() {
    const params = {
      sort: 'starts_at',
      direction: 'desc',
      patient_id: this.patient.id,
      include: 'doctor,specialty'
    };
    this.loadingPatientAppointments = true;
    this.appointmentService
      .getAppointments(params)
      .subscribe(
        (response) => {
          this.patientAppointments = response.data;
          this.loadingPatientAppointments = false;
        },
        () => {
          this.toasterService.show({
            message: 'Failed to load patient appointments.',
            type: 'error'
          });
          this.loadingPatientAppointments = false;
        }
      );
  }

  save(close: boolean) {
    if (this.patientForm.invalid) { return; }


    if (this.patient.id) {
      this.savingPatient = true;
      this.patientService
        .updatePatient(this.patient.id, this.patientForm.value)
        .subscribe(
          (response: { data: Patient }) => {
            this.toasterService.show({
              message: 'Patient was successfully updated.',
              type: 'success'
            });

            this.patient = response.data;
            this.patientForm.patchValue(response.data);
            this.savingPatient = false;
            if (close) { this.close(); }
          },
          () => {
            this.toasterService.show({
              message: 'There was an error while saving the patient.',
              type: 'error'
            });
            this.savingPatient = false;
          }
        );
    } else {
      this.savingPatient = true;
      this.patientService
        .createPatient(this.patientForm.value)
        .subscribe(
          (response: { data: Patient }) => {
            this.toasterService.show({
              message: 'Patient was successfully created.',
              type: 'success'
            });

            this.patient = response.data;
            this.patientForm.patchValue(response.data);
            this.savingPatient = false;

            if (this.sendInvitationAutomatically) {
              const invitation = new Invitation();
              invitation.email = this.patient.email;
              invitation.profile_id = this.patient.id;
              invitation.profile_type = 'patient';

              this.invitationService.invite(invitation)
                .subscribe(
                  () => {
                    if (!close) {
                      this.getInvitations();
                    }
                  },
                  () => { }
                );
            }

            if (close) {
              this.close();
            } else {
              this.router.navigate([ '/institution', 'patient', this.patient.id, 'details' ]);
            }
          },
          () => {
            this.toasterService.show({
              message: 'There was an error while saving the patient.',
              type: 'error'
            });
            this.savingPatient = false;
          }
        );
    }
  }

  delete() {
    if (!this.patient.id) { return; }

    this.modalService.confirm({
      title: 'Delete',
      body: 'Are you sure you want to delete this patient?',
      size: 'small',
      buttons: [
        {
          text: 'No',
          role: 'cancel'
        },
        {
          text: 'Yes',
          handler: () => {
            this.deletingPatient = true;
            this.patientService
              .deletePatient(this.patient.id)
              .subscribe(
                () => {
                  this.toasterService.show({
                    message: 'Patient succesfully deleted.',
                    type: 'success'
                  });
                  this.deletingPatient = false;
                  this.close();
                },
                () => {
                  this.toasterService.show({
                    message: 'Failed to delete the patient.',
                    type: 'error'
                  });
                  this.deletingPatient = false;
                }
              );
          }
        }
      ]
    });
  }

  invitePatient() {
    const modalRef = this.modalService.open(InvitationModalComponent);
    modalRef.componentInstance.patient = {
      id: this.patient.id,
      email: this.patientForm.get('email').value
    };
    modalRef.result.then(
      () => {
        this.getInvitations();
        this.toasterService.show({
          message: 'Invitation was sent to the patient.',
          type: 'success'
        });
      },
      () => { }
    );
  }

  revokeInvitation(invitation: Invitation) {
    this.modalService.confirm({
      title: 'Revoke invitation',
      body: 'Are you sure you want to revoke this invitation?',
      size: 'small',
      buttons: [
        {
          text: 'No',
          role: 'cancel'
        },
        {
          text: 'Yes',
          handler: () => {
            this.invitationService
              .revoke(invitation.id)
              .subscribe(
                () => {
                  this.getInvitations();
                  this.toasterService.show({ message:
                    'Invitation was revoked.',
                    type: 'success'
                  });
                },
                () => {
                  this.toasterService.show({
                    message: 'Failed to revoke the invitation.',
                    type: 'error'
                  });
                }
              );
          }
        }
      ]
    });
  }

  downloadPatientDocument(patientDocument: PatientDocument) {
    this.patientService.downloadPatientDocument(patientDocument.file_url);
  }

  deletePatientDocument(patientDocument: PatientDocument) {
    this.modalService.confirm({
      title: 'Delete',
      body: 'Are you sure you want to delete this document?',
      size: 'small',
      buttons: [
        {
          text: 'No',
          role: 'cancel'
        },
        {
          text: 'Yes',
          handler: () => {
            this.patientService
              .deletePatientDocument(this.patient.id, patientDocument.id)
              .subscribe(
                () => {
                  this.toasterService.show({
                    message: 'Document was successfully deleted.',
                    type: 'success'
                  });
                  this.getPatientDocuments();
                },
                () => {
                  this.toasterService.show({
                    message: 'Failed to delete the document.',
                    type: 'error'
                  });
                }
              );
          }
        }
      ]
    });
  }

  togglePatientInformation() {
    this.showPatientInformation = !this.showPatientInformation;
  }

  close() {
    this.router.navigate(['/institution', 'patient', 'list']);
  }

}
