import { Patient } from './patient';

export class PatientDocument {
  id: number;
  patient_id: number;
  type: string;
  name: string;
  description: string;
  date: string;
  file_size: string;
  file_url: string;
  file: any;

  patient: Patient;

  constructor(values: object = {}) {
    Object.assign(this, values);
  }
}
