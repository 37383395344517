import { Component } from '@angular/core';
import { UserService } from '../../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { Invitation } from '../../classes/invitation';
import { environment } from '../../../environments/environment';
import { Institution } from '../../classes/institution';
import { InvitationService } from '../../services/invitation.service';

@Component({
  selector: 'app-invitation-login',
  templateUrl: './invitation-login.component.html'
})
export class InvitationLoginComponent {
  loading: boolean = true;
  email: string = '';
  password: string = '';
  token: string = null;
  error: string = null;
  invitation: Invitation = null;
  institution: Institution = null;
  assetsRoot: string = environment.assetsRoot;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    private invitationService: InvitationService,
  ) {
    this.token = this.route.snapshot.paramMap.get('token');
    if (localStorage.getItem('jwt')) {
      this.router.navigate([ '/invitation/' + this.token + '/connect' ]);
    }
    if (this.token) {
      this.getInvitation();
    }
  }

  getInvitation() {
    this.loading = true;
    this.authService.getInvitation(this.token, {include: 'profile,institution'})
      .subscribe(
        (response) => {
          this.invitation = response.data;
          this.email = this.invitation.email;

          if (this.invitation.institution) {
            this.institution = this.invitation.institution;
          }
          this.loading = false;
        },
        (error) => {
          this.error = error.error.message;
          this.loading = false;
        }
      );
  }

  login() {
    this.authService.login(this.email, this.password)
      .subscribe(
        () => {
          this.invitationService.connect(this.token)
            .subscribe(
              () => {
                this.userService.getCurrentUserInstitutions()
                  .subscribe(
                    (response) => {
                      const institutions: Institution[] = response.data;

                      if (institutions.length > 1) {
                        this.router.navigate(['/institution-select']);
                      } else if (institutions.length === 1) {
                        localStorage.setItem('currentInstitutionKey', institutions[0].institution_guid);
                        this.router.navigate(['/institution/schedule']);
                      } else if (institutions.length === 0) {
                        this.router.navigate(['/profile']);
                      }
                    },
                    () => { }
                  );
              },
              (error) => {
                this.error = error.error.message;
              }
            );
        },
        (error) => {
          this.error = error.error.message;
        }
      );
  }

  clearError() {
    this.error = null;
  }

}
