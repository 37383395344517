import { Component, OnInit, ViewChild } from '@angular/core';
import { PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { InstitutionService } from '../../services/institution.service';
import { Institution } from '../../classes/institution';
import { ModalService } from 'libs/ui/src/public-api';
// tslint:disable-next-line: max-line-length
import { InstitutionServiceDetailsModalComponent } from '../../modals/institution-service-details/institution-service-details-modal.component';
import { Specialty } from '../../classes/specialty';
import { DCToastService } from 'libs/ui/src/lib/components/toast/toast.service';
import { User } from "../../classes/user";
import { UserService } from "../../services/user.service";

@Component({
  selector: 'main [dc-institution-service-list]',
  templateUrl: './institution-service-list.component.html'
})
export class InstitutionServiceListComponent implements OnInit {
  @ViewChild(PerfectScrollbarDirective, { static: false }) perfectScrollbarDirectiveRef?: PerfectScrollbarDirective;
  institution: Institution;
  specialties: Specialty[] = [];
  user: User;

  showSubheader: boolean = false;

  loadingServices: boolean = true;
  deletingService: boolean = false;

  constructor(
    private institutionService: InstitutionService,
    private modalService: ModalService,
    private toasterService: DCToastService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.getInstitution();
    this.getUser();
  }


  toggleSubheader() {
    this.showSubheader = !this.showSubheader;
  }

  getInstitution() {
    this.institutionService
      .currentInstitution
      .subscribe(
        (institution: Institution) => {
          if (institution) {
            this.institution = institution;
            this.getInstitutionServices();
          }
        },
        () => { }
      );
  }

  getInstitutionServices() {
    const params = {
      pagination: false
    }
    this.loadingServices = true;
    this.institutionService
      .getInstitutionServices(params)
      .subscribe(
        (response: any) => {
          const institutionServices = response.data;
          this.specialties = [];

          for (const specialty of this.institution.specialties) {
            specialty.institutionServices = institutionServices
              .filter((institutionService) => institutionService.specialty_id === specialty.id);
            this.specialties.push(specialty);
          }
          this.loadingServices = false;
        },
        () => {
          this.loadingServices = false;
        }
      );
  }

  getUser() {
    this.userService.currentUser
      .subscribe(
        (response) => {
          this.user = response;
        },
        () => {},
      );
  }

  createInstitutionService(specialtyId: number) {
    const modalRef = this.modalService.open(InstitutionServiceDetailsModalComponent);
    modalRef.componentInstance.specialtyId = specialtyId;
    modalRef.result.then(
      () => {
        this.getInstitution();
      },
      () => { }
    );
  }

  editInstitutionService(institutionServiceId: number) {
    const modalRef = this.modalService.open(InstitutionServiceDetailsModalComponent);
    modalRef.componentInstance.institutionServiceId = institutionServiceId;
    modalRef.result.then(
      () => {
        this.getInstitution();
      },
      () => { }
    );
  }

  deleteInstitutionService(institutionServiceId: number) {
    this.modalService.confirm({
      title: 'Delete',
      body: 'Are you sure you want to delete this service?',
      size: 'small',
      buttons: [
        {
          text: 'No',
          role: 'cancel'
        },
        {
          text: 'Yes',
          handler: () => {
            this.deletingService = true;
            this.institutionService
              .deleteInstitutionService(institutionServiceId)
              .subscribe(
                () => {
                  this.toasterService.show({
                    message: 'Service succesfully deleted.',
                    type: 'success'
                  });
                  this.deletingService = false;
                  this.getInstitutionServices();
                },
                () => {
                  this.toasterService.show({
                    message: 'Failed to delete the service.',
                    type: 'error'
                  });
                  this.deletingService = false;
                }
              );
          }
        }
      ]
    });
  }

}
