<div class="dc-section dc-content mb-80" [perfectScrollbar]="{ suppressScrollX: true}">
    <div [dcLoading]="loadingPatient"></div>
    <div class="dc-container" *ngIf="!loadingPatient">
        <div class="dc-row middle">
          <div class="dc-col sm-6">
            <h1>
                <div class="super-heading" translate>Medical record</div>
                <span *ngIf="patient.id">{{ patient.first_name + ' ' + patient.last_name }}</span>
                <span *ngIf="!patient.id" translate>New patient</span>
            </h1>
          </div>
          <div class="dc-col sm-6 flex padding-bottom" style="flex-direction: row; justify-content: flex-end;">
            <dc-button *ngIf="patient.id" color="outline" icon="delete" size="small" [loading]="deletingPatient" (click)="delete()">{{ 'Delete patient' | translate }}</dc-button>
          </div>
        </div>
        <form [formGroup]="patientForm">
            <dc-collapse [collapsed]="patient.id">
                <div class="dc-row space-16 padding-bottom">
                    <div class="dc-col md-12" style="margin: 0;">
                        <h2 class="dc-text-color-primary" translate>Information</h2>
                    </div>
                    <div class="dc-col md-2">
                        <dc-input
                            label="{{ 'First name' | translate }}"
                            formControlName="first_name"
                        ></dc-input>
                    </div>
                    <div class="dc-col md-2">
                        <dc-input
                            label="{{ 'Last name' | translate }}"
                            formControlName="last_name"
                        ></dc-input>
                    </div>
                    <div class="dc-col md-2">
                        <dc-input
                            label="{{ 'Parent name' | translate }}"
                            formControlName="parent_name"
                        ></dc-input>
                    </div>
                    <div class="dc-col md-2">
                        <dc-input
                            label="{{ 'JMBG' | translate }}"
                            formControlName="jmbg"
                        ></dc-input>
                    </div>
                  <div class="dc-col md-2">
                    <dc-input
                      label="{{ 'ID' | translate }}"
                      formControlName="id"
                      [readOnly]="true"
                    ></dc-input>
                  </div>
                    <div class="dc-col md-2">
                        <dc-input
                            label="{{ 'Patient number' | translate }}"
                            formControlName="patient_number"
                        ></dc-input>
                    </div>
                </div>
                <div class="dc-row space-16" dcCollapse>
                  <div class="dc-col md-2">
                    <dc-select
                      label="{{ 'Gender' | translate }}"
                      formControlName="gender"
                      [options]="genderOptions"
                    ></dc-select>
                  </div>
                    <div class="dc-col md-2">
                        <dc-datepicker
                            label="{{ 'Birth date' | translate }}"
                            formControlName="date_of_birth"
                            type="calendar"
                            startView="multi-years"
                            inputMask
                        ></dc-datepicker>
                    </div>
                    <div class="dc-col md-2">
                        <dc-input
                            label="{{ 'Weight' | translate }}"
                            formControlName="weight"
                            type="number"
                        ></dc-input>
                    </div>
                    <div class="dc-col md-2">
                        <dc-input
                            label="{{ 'Height' | translate }}"
                            formControlName="height"
                            type="number"
                        ></dc-input>
                    </div>
                    <div class="dc-col md-2">
                        <dc-select
                            label="{{ 'Blood type' | translate }}"
                            formControlName="blood_type"
                            [options]="bloodTypeOptions"
                        ></dc-select>
                    </div>
                    <div class="dc-col md-8">
                        <div class="dc-row space-16">
                            <div class="dc-col md-12">
                                <h2 class="dc-text-color-primary" translate>Contact</h2>
                            </div>
                            <div class="dc-col md-3">
                                <dc-input
                                    label="{{ 'Phone 1' | translate }}"
                                    formControlName="phone_1"
                                ></dc-input>
                            </div>
                            <div class="dc-col md-3">
                                <dc-input
                                    label="{{ 'Phone 2' | translate }}"
                                    formControlName="phone_2"
                                ></dc-input>
                            </div>
                            <div class="dc-col md-6">
                                <dc-input
                                    label="{{ 'Email' | translate }}"
                                    formControlName="email"
                                    [required]="sendInvitationAutomatically"
                                ></dc-input>
                            </div>
                            <div class="dc-col md-3">
                                <dc-input
                                    label="{{ 'Address' | translate }}"
                                    formControlName="address"
                                ></dc-input>
                            </div>
                            <div class="dc-col md-3">
                                <dc-input
                                    label="{{ 'City' | translate }}"
                                    formControlName="city"
                                ></dc-input>
                            </div>
                            <div class="dc-col md-6">
                                <dc-select
                                    [options]="countries"
                                    [loading]="loadingCountries"
                                    label="{{ 'Country' | translate }}"
                                    formControlName="country_id"
                                ></dc-select>
                            </div>
                            <div class="dc-col md-12" *ngIf="!patient.id">
                                <dc-checkbox
                                    [label]="'Send invitation to Doktorijum' | translate"
                                    [(ngModel)]="sendInvitationAutomatically"
                                    [ngModelOptions]="{ standalone: true }"
                                ></dc-checkbox>
                            </div>
                        </div>
                    </div>
                    <div class="dc-col md-4">
                        <div class="dc-row">
                            <div class="dc-col md-12" *ngIf="patient.id">
                                <h2 class="dc-text-color-primary" translate>Doktorijum user</h2>
                            </div>
                            <div class="dc-col md-12" *ngIf="patient.id">
                                <div *ngIf="patient.user">
                                    <div translate>Patient is registered user of Doktorijum.</div>
                                    <div class="margin-top">
                                        <label translate>User email</label>
                                        <div><span class="dot green"></span>&nbsp;&nbsp;{{ patient.user.email }}</div>
                                    </div>
                                </div>
                                <div *ngIf="!patient.user">
                                    <div *ngIf="invitations.length === 0">
                                        <div class="margin-bottom" translate>Patient is not Doktorijum user.</div>
                                        <dc-button size="full" (click)="invitePatient()">{{ 'Invite patient to register' | translate }}</dc-button>
                                    </div>
                                    <div *ngIf="invitations.length > 0">
                                        <div translate>Invitation to register sent.</div>
                                        <div>
                                            <div class="invitation" *ngFor="let invitation of invitations">
                                                <div class="dot yellow"></div>
                                                <div style="flex: 1">
                                                    <div>
                                                        <span translate>Sent</span>: {{ invitation.created_at | amDateFormat:'DD.MM.YYYY.' }} | <span translate>Expires</span>: {{ invitation.expires_at | amDateFormat:'DD.MM.YYYY. HH:mm' }}
                                                    </div>
                                                    <div>{{ invitation.email }}</div>
                                                </div>
                                                <div>
                                                    <span class="icon delete" (click)="revokeInvitation(invitation)"></span>
                                                </div>
                                            </div>
                                        </div>
                                        <dc-button size="full" (click)="invitePatient()">{{ 'Send new invitation to register' | translate }}</dc-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="dc-col md-12 padding-bottom"></div>
                </div>
            </dc-collapse>
            <dc-collapse>
                <div class="dc-row">
                    <div class="dc-col md-12" style="margin: 0;">
                        <h2 class="dc-text-color-primary" translate>Anamnesis</h2>
                    </div>
                    <div class="dc-col padding-bottom" dcCollapse>
                        <dc-text-editor
                            label="{{ 'Anamnesis' | translate }}"
                            formControlName="anamnesis_doctor"
                        ></dc-text-editor>
                    </div>
                </div>
            </dc-collapse>
        </form>
        <div class="patient-items margin-top" *ngIf="patient.id">
            <dc-tabs>
                <dc-tab [title]="'Visits' | translate">
                    <div [dcLoading]="loadingPatientVisits"></div>

                    <div *ngIf="patientVisits.length > 0">
                        <dc-button size="small" icon="plus" [routerLink]="['/institution', 'patient', this.patient.id, 'visit']">{{ 'Add' | translate }}</dc-button>
                    </div>

                    <ng-container *ngIf="patientVisits.length === 0">
                        <dc-empty-state type="visit">
                            <div style="display: flex; flex-direction: column; align-items: center;">
                                <h3>{{ 'Here will be the list of patient visits.' | translate }}</h3>
                                <dc-button size="small" icon="plus" [routerLink]="['/institution', 'patient', this.patient.id, 'visit']">{{ 'Add' | translate }}</dc-button>
                            </div>
                        </dc-empty-state>
                    </ng-container>

                    <table class="dc-table">
                        <tbody>
                            <tr class="color dc-clickable" *ngFor="let visit of patientVisits">
                                <td [style.background-color]="visit.specialty?.color"></td>
                                <td [routerLink]="[ '/institution', 'patient', patient.id, 'visit', visit.id ]">
                                    <div class="dc-table__value">
                                        <div>
                                            <span>{{ visit.datetime | amDateFormat:'DD.MM.YYYY' }}</span>
                                            <span> | </span>
                                            <span>{{ visit.datetime | amDateFormat:'HH:mm' }}</span>
                                        </div>
                                        <h4 style="margin: 8px 0 0;">{{ visit.specialty?.name }} | {{ visit.doctor?.title }} {{ visit.doctor?.first_name }} {{ visit.doctor?.last_name }}</h4>
                                        <div>
                                            <ng-container *ngFor="let diagnosis of visit.diagnoses">
                                                <div class="dc-text-color-primary margin-vertical small">{{ diagnosis.disease.code }} - {{ diagnosis.disease.name_serbian }}</div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </dc-tab>
                <dc-tab [title]="'Attachments' | translate">
                    <div [dcLoading]="loadingPatientDocuments"></div>

                    <ng-container *ngIf="patientDocuments.length === 0">
                        <dc-empty-state type="document">
                            <h3>{{'There are no patient documents yet.' | translate }}</h3>
                        </dc-empty-state>
                    </ng-container>

                    <table class="dc-table">
                        <tbody>
                            <tr *ngFor="let document of patientDocuments">
                                <td>
                                    <div class="dc-table__value">
                                        <div>{{ document.date | amDateFormat:'DD.MM.YYYY' }} | {{ document.type }}</div>
                                        <h4 class="dc-text-color-primary" style="margin: 8px 0;">{{ document.name }}</h4>
                                        <div>{{ document.description }}</div>
                                    </div>
                                </td>
                                <td style="width: 80px;">
                                    <span
                                        class="dc-icon download dc-clickable"
                                        style="font-size: 24px; color:#757575; margin-right: 8px;"
                                        (click)="downloadPatientDocument(document)"
                                        title="{{ 'Download' | translate }}"
                                    ></span>
                                    <span
                                        class="dc-icon delete dc-clickable"
                                        style="font-size: 24px; color:#757575"
                                        (click)="deletePatientDocument(document)"
                                        title="{{ 'Delete' | translate }}"
                                    ></span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </dc-tab>
                <dc-tab [title]="'Appointments' | translate">
                    <div [dcLoading]="loadingPatientAppointments"></div>

                    <ng-container *ngIf="patientAppointments.length === 0">
                        <dc-empty-state type="appointment">
                            <h3>{{'There are no patient appointments yet.' | translate }}</h3>
                        </dc-empty-state>
                    </ng-container>


                    <table class="dc-table">
                        <tbody>
                            <tr class="color" *ngFor="let appointment of patientAppointments">
                                <td [style.background-color]="appointment.specialty?.color"></td>
                                <td>
                                    <div class="dc-table__value">
                                        <div class="dc-text-color__gray-medium">
                                            <span>{{ appointment.starts_at | amDateFormat:'DD.MM.YYYY' }}</span>
                                            <span> | </span>
                                            <span>{{ appointment.starts_at | amDateFormat:'HH:mm' }}</span>
                                            <span *ngIf="appointment.type === 'telemedicine'">
                                              <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                                              <span class="dc-icon video-full"></span>
                                            </span>
                                            <span *ngIf="appointment.no_show">
                                              <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                                              <span class="dc-text-color-secondary">Pacijent se nije pojavio.</span>
                                            </span>
                                            <span *ngIf="appointment.status === 'canceled by patient'">
                                              <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                                              <span class="dc-text-color-secondary">Otkazao pacijent.</span>
                                            </span>
                                            <span *ngIf="appointment.status === 'canceled by institution' && !appointment.no_show">
                                              <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                                              <span class="dc-text-color-secondary">Otkazala institucija.</span>
                                            </span>
                                        </div>
                                        <h4 style="margin: 8px 0 0;">{{ appointment.specialty?.name }} | {{ appointment.doctor?.title }} {{ appointment.doctor?.first_name }} {{ appointment.doctor?.last_name }}</h4>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </dc-tab>
            </dc-tabs>
        </div>
    </div>
</div>
<div class="dc-bottom-bar">
    <div class="dc-container flex" style="height: 100%; align-items: center;">
        <dc-button size="small" color="secondary" (click)="close()">{{ 'Close' | translate }}</dc-button>
        &nbsp;&nbsp;
        <dc-button size="small" (click)="save(false)" [loading]="savingPatient" [disabled]="patientForm.invalid">{{ 'Save' | translate }}</dc-button>
        &nbsp;&nbsp;
        <dc-button size="small" (click)="save(true)" [loading]="savingPatient" [disabled]="patientForm.invalid">{{ 'Save & Close' | translate }}</dc-button>
    </div>
</div>
