import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AppointmentService } from "../../services/appointment.service";
import { TranslateService } from "@ngx-translate/core";
import { DCToastService } from "@dc/ui/components/toast/toast.service";

@Component({
  selector: 'doc-appointment-slot-delete',
  templateUrl: './appointment-slot-delete.component.html'
})
export class AppointmentSlotDeleteComponent implements OnInit {

  @Input() appointmentSlotId: number;
  @Input() appointmentSlotGuid: any;

  deleteAllFromSeries: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    private appointmentService: AppointmentService,
    private translateService: TranslateService,
    private toastService: DCToastService
  ) { }

  ngOnInit() {
    console.log(this.appointmentSlotGuid)
  }

  close() {
    this.activeModal.dismiss();
  }

  delete() {
    if (this.deleteAllFromSeries) {
      this.appointmentService
        .deleteAppointmentSlotSeries(this.appointmentSlotGuid)
        .subscribe(
          () => {
            this.toastService.show({
              message: this.translateService.instant('Appointment slots successfully deleted.'),
              type: 'success'
            });
            this.activeModal.close(true);
          },
          () => {
            this.toastService.show({
              message: this.translateService.instant('Failed to delete the appointment slots.'),
              type: 'error'
            });
            this.close();
          }
        );
    } else {
      this.appointmentService
        .deleteAppointmentSlot(this.appointmentSlotId)
        .subscribe(
          () => {
            this.toastService.show({
              message: this.translateService.instant('Appointment slot successfully deleted.'),
              type: 'success'
            });
            this.activeModal.close(true);
          },
          () => {
            this.toastService.show({
              message: this.translateService.instant('Failed to delete the appointment slot.'),
              type: 'error'
            });
            this.close();
          }
        );
    }
  }

}
