import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PatientService } from '../../services/patient.service';
import { Patient } from '../../classes/patient';
import { PatientVisit } from '../../classes/patient-visit';
import { PatientDocument } from '../../classes/patient-document';
import { DiseaseService } from '../../services/disease.service';
import { PatientDiagnosis } from '../../classes/patient-diagnosis';
import { Subscription } from 'rxjs';
import { InstitutionService } from '../../services/institution.service';
import { InstitutionTemplate } from '../../classes/institution-template';
import { Diagnosis } from '../../classes/diagnosis';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DCToastService } from 'libs/ui/src/lib/components/toast/toast.service';
import { DoctorService } from '../../services/doctor.service';
import { SpecialtyService } from '../../services/specialty.service';
import { Specialty } from '../../classes/specialty';
import { Doctor } from '../../classes/doctor';
import { ModalService } from 'libs/ui/src/public-api';
import * as moment from 'moment';
import { UploaderConfig } from 'libs/ui/src/lib/components/file/_classes/uploader-config';
import { PatientDocumentUploadComponent } from '../../modals/patient-document-upload/patient-document-upload.component';
import { User } from '../../classes/user';
import { UserService } from '../../services/user.service';
import { AddDiagnosisComponent } from '../../modals/add-diagnosis/add-diagnosis.component';
import { AddTherapiesComponent } from '../../modals/add-therapies/add-therapies.component';
import { Therapies } from '../../classes/therapies';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'main [dc-patient-visit]',
  templateUrl: './patient-visit.component.html'
})
export class PatientVisitComponent implements OnInit, OnDestroy {
  patientId: number = null;
  visitId: number = null;
  patientVisitForm: FormGroup;
  patient: Patient = new Patient();
  patientVisit: PatientVisit = new PatientVisit();
  patientDiagnosis: PatientDiagnosis[] = [];
  patientDocuments: PatientDocument[] = [];
  institutionTemplates: InstitutionTemplate[] = [];
  selectedTemplateId: any = '';
  specialties: Specialty[] = [];
  doctors: Doctor[] = [];
  restrictedEdit: boolean = false;
  currentUser: User;

  loadingVisit: boolean = false;
  savingVisit: boolean = false;
  deletingVisit: boolean = false;

  uploaderConfig: UploaderConfig = {
    autoUpload: false,
    removeAfterUpload: true
  };
  uploadDocumentsModal = PatientDocumentUploadComponent;

  subscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private toasterService: DCToastService,
    private modalService: ModalService,
    private specialtyService: SpecialtyService,
    private doctorService: DoctorService,
    private patientService: PatientService,
    private diseaseService: DiseaseService,
    private institutionService: InstitutionService,
    private userService: UserService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.initForm();
    this.getInstitutionTemplates();
    this.getSpecialties();
    this.getDoctors();

    const routeSubscription = this.route.params.subscribe(
      (data) => {
        this.patientId = data.patientId;
        this.visitId = data.visitId;

        this.getPatient();

        if (this.visitId) {
          this.getPatientVisit();
          this.getPatientDocuments();
        }
      }
    );
    this.subscriptions.push(routeSubscription);

    const userSubscription = this.userService.currentUser.subscribe(
      (response) => {
        if (response) {
          this.currentUser = response;
          if (!this.visitId) {
            this.patientVisitForm
                .get('doctor_id')
                .setValue(this.currentUser.doctor_id);
          }
        }
      }
    );
    this.subscriptions.push(userSubscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  initForm() {
    this.patientVisitForm = this.formBuilder.group({
      datetime: [moment().format('YYYY-MM-DD HH:mm'), Validators.required],
      description: ['', Validators.required],
      specialty_id: [null, Validators.required],
      doctor_id: [null, Validators.required],
      diagnoses: [[]],
      therapies: [[]]
    });
  }

  applyTemplate() {
    const selectedTemplate = this.institutionTemplates.find(template => template.id === +this.selectedTemplateId);
    if (selectedTemplate) {
      this.patientVisitForm
        .get('description')
        .setValue(this.replaceTokens(selectedTemplate.content));
      this.restrictedEdit = false;
    } else {
      this.restrictedEdit = false;
    }
  }

  deleteTemplate() {
    if (this.selectedTemplateId) {
      this.institutionService.deleteInstitutionTemplates(this.selectedTemplateId)
        .subscribe(
          () => {
            this.selectedTemplateId  = null;
            this.getInstitutionTemplates();
          }
        );
    }
  }

  saveTemplate() {
    const name = window.prompt('Please enter the name of the template');

    if (name) {
      const template = {
        name,
        content: this.patientVisitForm.get('description').value
      };
      this.institutionService
        .createInstitutionTemplates(template)
        .subscribe(
          () => {
            this.getInstitutionTemplates();
          },
          () => {
            this.toasterService.show({
              message: 'Failed to load templates.',
              type: 'error'
            });
          }
        );
    }
  }

  getPatient() {
    this.patientService
      .getPatient(this.patientId)
      .subscribe(
        (response) => {
          this.patient = response.data;
        },
        () => {
          this.toasterService.show({
            message: 'Failed to load patient.',
            type: 'error'
          });
        }
      );
  }

  getSpecialties() {
      this.specialtyService
          .getInstitutionSpecialties({ pagination: false })
          .subscribe(
              (response: any) => {
                  this.specialties = response.data;
              },
              () => {
                this.toasterService.show({
                  message: 'Failed to load specialties.',
                  type: 'error'
                });
              }
          );
  }

  getDoctors() {
      this.doctorService
          .getDoctors({ pagination: false })
          .subscribe(
              (response: any) => {
                  this.doctors = response.data;
              },
              () => {
                this.toasterService.show({
                  message: 'Failed to load doctors.',
                  type: 'error'
                });
              }
          );
  }

  getPatientVisit() {
    this.loadingVisit = true;
    this.patientService
      .getPatientVisit(this.patientId, this.visitId, { include: 'diagnoses.disease,therapies.therapy' })
      .subscribe(
        (response) => {
          this.patientVisit = response.data;
          this.patientVisitForm.patchValue(response.data);
          this.loadingVisit = false;
        },
        () => {
          this.toasterService.show({
            message: 'Failed to load patient visit.',
            type: 'error'
          });
          this.loadingVisit = false;
        }
      );
  }

  save(close: boolean) {
    if (this.visitId) {
      this.savingVisit = true;
      this.patientService
        .updatePatientVisit(this.patientId, this.visitId, this.patientVisitForm.value)
        .subscribe(
          (response) => {
            this.toasterService.show({
              message: 'Patient visit was successfully updated.',
              type: 'success'
            });

            this.patientVisit = response.data;
            this.savingVisit = false;
            if (close) { this.close(); }
          },
          () => {
            this.toasterService.show({
              message: 'There was an error while saving the patient visit.',
              type: 'error'
            });
            this.savingVisit = false;
          }
        );
    } else {
      this.savingVisit = true;
      this.patientService
        .createPatientVisit(this.patientId, this.patientVisitForm.value)
        .subscribe(
          (response) => {
            this.toasterService.show({
              message: 'Patient visit was successfully created.',
              type: 'success'
            });


            this.patientVisit = response.data;
            this.savingVisit = false;
            this.router.navigate([ this.patientVisit.id ], { relativeTo: this.route });
            if (close) { this.close(); }
          },
          () => {
            this.toasterService.show({
              message: 'There was an error while saving the patient visit.',
              type: 'error'
            });
            this.savingVisit = false;
          }
        );
    }
  }

  delete() {
    if (!this.visitId) { return; }

    this.modalService.confirm({
      title: 'Delete',
      body: 'Are you sure you want to delete this patient visit?',
      size: 'small',
      buttons: [
        {
          text: 'No',
          role: 'cancel'
        },
        {
          text: 'Yes',
          handler: () => {
            this.deletingVisit = true;
            this.patientService
              .deletePatientVisit(this.patientId, this.visitId)
              .subscribe(
                () => {
                  this.toasterService.show({
                    message: 'Patient succesfully deleted visit.',
                    type: 'success'
                  });
                  this.deletingVisit = false;
                  this.close();
                },
                () => {
                  this.toasterService.show({
                    message: 'Failed to delete the patient visit.',
                    type: 'error'
                  });
                  this.deletingVisit = false;
                }
              );
          }
        }
      ]
    });
  }

  getInstitutionTemplates() {
    this.institutionService
      .getInstitutionTemplates()
      .subscribe(
        (response: { data: InstitutionTemplate[] }) => {
          this.institutionTemplates = response.data;
        },
        () => {
          this.toasterService.show({
            message: 'Failed to get institution templates.',
            type: 'error'
          });
        }
      );
  }

  // TODO get current doctor
  addPatientDiagnosis() {
    const selectedDiagnosis = this.patientVisitForm.get('diagnoses').value;
    const modalRef = this.modalService.open(AddDiagnosisComponent);
    modalRef.componentInstance.selectedDiagnosisIds = selectedDiagnosis.map(d => d.disease.id);
    modalRef.result
      .then((selectedDisease) => {
        const diagnosis = new Diagnosis();
        diagnosis.disease_id = selectedDisease.id;
        diagnosis.disease = selectedDisease;

        const diagnoses = this.patientVisitForm.get('diagnoses').value;
        diagnoses.push(diagnosis);
        this.patientVisitForm.get('diagnoses').setValue(diagnoses);
      })
      .catch(() => {});
  }

  addPatientTherapies() {
    this.modalService.open(AddTherapiesComponent).result
      .then((selectedTherapy) => {
        const therapy = new Therapies();
        therapy.therapy_id = selectedTherapy.therapy.id;
        therapy.therapy = selectedTherapy.therapy;
        therapy.description = selectedTherapy.description;

        const therapies = this.patientVisitForm.get('therapies').value;
        therapies.push(therapy);
        this.patientVisitForm.get('therapies').setValue(therapies);
      })
      .catch(() => {});
  }

  removePatientDiagnosis(diagnosis) {
    const diagnoses = this.patientVisitForm.get('diagnoses').value;
    for (let i = 0; i < diagnoses.length; i++) {
      if (diagnoses[i].disease_id === diagnosis.disease_id) {
        diagnoses.splice(i, 1);
      }
    }
    this.patientVisitForm.get('diagnoses').setValue(diagnoses);
  }

  removePatientTherapy(therapy) {
    const therapies = this.patientVisitForm.get('therapies').value;
    for (let i = 0; i < therapies.length; i++) {
      if (therapies[i].therapy_id === therapy.therapy_id) {
        therapies.splice(i, 1);
      }
    }
    this.patientVisitForm.get('therapies').setValue(therapies);
  }

  getPatientDocuments() {
    this.patientService
      .getPatientDocuments(this.patientId, { patient_visit_id: this.visitId })
      .subscribe(
        (response) => {
          this.patientDocuments = response.data;
        },
        () => { }
      );
  }

  downloadPatientDocument(patientDocument: PatientDocument) {
    this.patientService.downloadPatientDocument(patientDocument.file_url);
  }

  deletePatientDocument(patientDocument) {
    this.modalService.confirm({
      title: 'Delete',
      body: 'Are you sure you want to delete this document?',
      size: 'small',
      buttons: [
        {
          text: 'No',
          role: 'cancel'
        },
        {
          text: 'Yes',
          handler: () => {
            this.patientService
              .deletePatientDocument(this.patientId, patientDocument.id)
              .subscribe(
                () => {
                  this.toasterService.show({
                    message: 'Patient succesfully deleted.',
                    type: 'success'
                  });
                  this.getPatientDocuments();
                },
                () => {
                  this.toasterService.show({
                    message: 'Failed to delete the patient.',
                    type: 'error'
                  });
                }
              );
          }
        }
      ]
    });
  }

  close() {
    this.router.navigate([ '/institution', 'patient', this.patientId, 'details' ]);
  }

  print() {
    let popupWindow;
    popupWindow = window.open('', '_blank');
    popupWindow.document.open();
    popupWindow.document.write(
      `
        <html lang="en">
            <head>
                <link rel="stylesheet" type="text/css" href="/assets/sass/style-text-editor.css">
            </head>
            <body onload="window.print();window.close()" style="width: 21cm;height: 29.7cm">
                ` + this.replaceTokens(this.patientVisitForm.value.description) + `
            </body>
        </html>
      `
    );
    popupWindow.document.close();
  }

  replaceTokens(text: string) {
    const regex = /{{(.*?)}}/g;
    const tokens = text.match(regex);
    let processed = text;
    if (tokens) {
      tokens.map((token) => {
        if (token.includes('patient')) {
          if (token.includes('full_name')) {
            processed = processed
              .split(token)
              .join(this.patient.first_name + ' ' + this.patient.last_name);
          }
          if (token.includes('date_of_birth')) {
            if (this.patient.date_of_birth) {
              processed = processed
                .split('{{patient_date_of_birth}}')
                .join(this.patient.date_of_birth.split('-').reverse().join('.'));
            } else {
              processed = processed
                .split('{{patient_date_of_birth}}')
                .join(' ');
            }
          }
        }
        if (token.includes('doctor')) {
          const doctor_id = this.patientVisitForm.get('doctor_id').value;
          if (doctor_id) {
            const doctor = this.doctors.find(d => d.id === doctor_id);
            if (token.includes('name') && !token.includes('doctor.')) {
              processed = processed
                .split(token)
                .join(doctor ? doctor.title + ' ' + doctor.first_name + ' ' + doctor.last_name : ' ');
            }
          } else {
            processed = processed
              .split(token)
              .join(' ');
          }
        }
        if (token.includes('visit')) {
          const visitDateTime = this.patientVisitForm.get('datetime').value;
          if (visitDateTime) {
            if (token.includes('datetime')) {
              processed = processed
                .split(token)
                .join(moment(visitDateTime).format('DD.MM.YYYY'));
            }
          } else {
            processed = processed
              .split(token)
              .join(' ');
          }
        }
      });
    }
    return this.replaceNewTokens(processed);
  }

  replaceNewTokens(text: any) {
    const regex = /{{(.+?)\.(.+?)}}/g;
    const tokens = [...text.matchAll(regex)];
    let processed = text;
    if (tokens) {
      tokens.map((token) => {
        if (token[1] === 'patient') {
          if (token[2] === 'full_name') {
            processed = processed
              .split(token[0])
              .join(this.patient.first_name + ' ' + this.patient.last_name);
          } else if (token[2] === 'date_of_birth') {
            const date = moment(this.patient.date_of_birth);
            if (date.isValid()) {
              processed = processed
              .split(token[0])
              .join(date.format('DD.MM.YYYY'));
            } else {
              processed = processed
              .split(token[0])
              .join('')
            }
          } else if (token[2] === 'gender') {
            if (this.patient.gender === 'M') {
              processed = processed
                .split(token[0])
                .join(this.translate.instant('Male'));
            } else if(this.patient.gender === 'F') {
              processed = processed
                .split(token[0])
                .join(this.translate.instant('Female'));
            } else {
              processed = processed
                .split(token[0])
                .join('');
            }
          } else {
            if (!this.patient[token[2]]) {
              processed = processed
                .split(token[0])
                .join(' ');
            } else {
              processed = processed
                .split(token[0])
                .join(this.patient[token[2]]);
            }
          }
        }
        if (token[1] === 'doctor') {
          const doctor_id = this.patientVisitForm.get('doctor_id').value;
          if (doctor_id) {
            const doctor = this.doctors.find(d => d.id === doctor_id);
            if (token[2] === 'full_name') {
              processed = processed
                .split(token[0])
                .join(doctor ? doctor.first_name + ' ' + doctor.last_name : ' ');
            } else {
              if (!doctor[token[2]]) {
                processed = processed
                  .split(token[0])
                  .join(' ');
              } else {
                processed = processed
                  .split(token[0])
                  .join(doctor[token[2]]);
              }
            }
          } else {
            processed = processed
              .split(token[0])
              .join(' ');
          }
        }
        if (token[1] === 'visit') {
          if (token[2] === 'datetime') {
            const visitDateTime = this.patientVisitForm.get('datetime').value;
            if (visitDateTime) {
              processed = processed
                .split(token[0])
                .join(moment(visitDateTime).format('DD.MM.YYYY'));
            } else {
              processed = processed
                .split(token[0])
                .join(' ');
            }
          } else if (token[2] === 'diagnoses_latine') {
            const visitDiagnoses = this.patientVisitForm.get('diagnoses').value;
            if (visitDiagnoses) {
              const arrayOfDiagnosesName = [];
              visitDiagnoses.map((diagnose) => {
                arrayOfDiagnosesName.push(diagnose.disease.name_latin);
              });
              processed = processed
                .split(token[0])
                .join(arrayOfDiagnosesName.toString().split(',').join(', '));
            } else {
              processed = processed
                .split(token[0])
                .join(' ');
            }
          } else if (token[2] === 'diagnoses_rs') {
            const visitDiagnoses = this.patientVisitForm.get('diagnoses').value;
            if (visitDiagnoses) {
              const arrayOfDiagnosesName = [];
              visitDiagnoses.map((diagnose) => {
                arrayOfDiagnosesName.push(diagnose.disease.name_serbian);
              });
              processed = processed
                .split(token[0])
                .join(arrayOfDiagnosesName.toString().split(',').join(', '));
            } else {
              processed = processed
                .split(token[0])
                .join(' ');
            }
          } else {
            if (!this.patientVisit[token[2]]) {
              processed = processed
                .split(token[0])
                .join(' ');
            } else {
              processed = processed
                .split(token[0])
                .join(this.patientVisit[token[2]]);
            }
          }
        }
      });
    }
    return processed;
  }
}
