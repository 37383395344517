import { Injectable } from '@angular/core';
import { Patient } from '../classes/patient';
import { ApiService } from './api.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PatientService {

  constructor(
    private apiService: ApiService
  ) { }

  getPatient(patientId: number, query: any = {}) {
    return this.apiService.get(`patient/${patientId}`, query);
  }

  getPatients(query: any = {}) {
    return this.apiService.get(`patient`, query);
  }

  createPatient(patient: Patient) {
    return this.apiService.post(`patient`, patient);
  }

  updatePatient(patientId: number, patient: Patient) {
    return this.apiService.put(`patient/${patientId}`, patient);
  }

  deletePatient(patientId: number) {
    return this.apiService.delete(`patient/${patientId}`);
  }

  getPatientInvitations(patientId: number, query: any = {}) {
    return this.apiService.get(`patient/${patientId}/invitation`, query);
  }

  getPatientDocuments(patientId: number, query: any = {}) {
    return this.apiService.get(`patient/${patientId}/document`, query);
  }

  createPatientDocument(patientId: number, formData: any) {
    return this.apiService.post(`patient/${patientId}/document`, formData);
  }

  downloadPatientDocument(fileUrl: string) {
    window.open(environment.assetsRoot + fileUrl);
  }

  deletePatientDocument(patientId: number, documentId: number) {
    return this.apiService.delete(`patient/${patientId}/document/${documentId}`);
  }

  getPatientVisits(patientId: number, query: any = {}) {
    return this.apiService.get(`patient/${patientId}/visit`, query);
  }

  getPatientVisit(patientId: number, visitId: number, query: any = {}) {
    return this.apiService.get(`patient/${patientId}/visit/${visitId}`, query);
  }

  createPatientVisit(patientId: number, query: any = {}) {
    return this.apiService.post(`patient/${patientId}/visit`, query);
  }

  updatePatientVisit(patientId, visitId, visit) {
    return this.apiService.put(`patient/${patientId}/visit/${visitId}`, visit);
  }

  deletePatientVisit(patientId: number, visitId: number) {
    return this.apiService.delete(`patient/${patientId}/visit/${visitId}`);
  }

  createPatientDiagnosis(patientId: number, diseaseId: number, doctorId: number, visitId: number = null ) {
    const diagnosis = {
      disease_id: diseaseId,
      doctor_id: doctorId,
      patient_visit_id: visitId
    };

    return this.apiService.post(`patient/${patientId}/diagnosis`, diagnosis);
  }

  getPatientDiagnosis(patientId: number, query: any = {}) {
    return this.apiService.get(`patient/${patientId}/diagnosis`, query);
  }

  removePatientDiagnosis(patientId: number, diagnosisId: number) {
    return this.apiService.delete(`patient/${patientId}/diagnosis/${diagnosisId}`);
  }



}
