import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToastrModule } from 'ngx-toastr';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MomentModule } from 'ngx-moment';
import { ModalModule } from 'ngx-bootstrap/modal';

import { DCComponentModule } from '@dc/ui/components/component.module';
import { DcDatePipe } from '@dc/ui/pipes/date.pipe';

import { AppComponent } from './app.component';
import { TokenInterceptor } from './services/auth.interceptor';
import { AuthGuard } from './guards/auth.guard';
import { ScheduleComponent } from './pages/schedule/schedule.component';
import { PatientListComponent } from './pages/patient-list/patient-list.component';
import { PatientDetailsComponent } from './pages/patient-details/patient-details.component';
import { LoginComponent } from './pages/login/login.component';
import { DoctorListComponent } from './pages/doctor-list/doctor-list.component';
import { DoctorDetailsComponent } from './pages/doctor-details/doctor-details.component';
import { PatientVisitComponent } from './pages/patient-visit/patient-visit.component';
import { InstitutionServiceListComponent } from './pages/institution-service-list/institution-service-list.component';
import { RegistrationComponent } from './pages/registration/registration.component';
import { ChangePasswordRequestComponent } from './pages/change-password-request/change-password-request.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { InvitationModalComponent } from './modals/invitation/invitation-modal.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { InstitutionDetailsComponent } from './pages/institution-details/institution-details.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { InstitutionComponent } from './pages/institution/institution.component';
import { InstitutionSelectComponent } from './pages/institution-select/institution-select.component';
import { InvitationLoginComponent } from './pages/invitation-login/invitation-login.component';
import { InvitationRegistrationComponent } from './pages/invitation-registration/invitation-registration.component';
import { InvitationConnectComponent } from './pages/invitation-connect/invitation-connect.component';
import { AppointmentSlotCreateModalComponent } from './modals/appointment-slot-create/appointment-slot-create-modal.component';
import { PatientDocumentModalComponent } from './modals/patient-document/patient-document-modal.component';
import { AppointmentCreateModalComponent } from './modals/appointment-create/appointment-create-modal.component';
import { PatientCreateModalComponent } from './modals/patient-create/patient-create-modal.component';
import { AppointmentCancelModalComponent } from './modals/appointment-cancel/appointment-cancel-modal.component';
import { InstitutionServiceDetailsModalComponent } from './modals/institution-service-details/institution-service-details-modal.component';
import { PatientDocumentUploadComponent } from './modals/patient-document-upload/patient-document-upload.component';
import { InstitutionTemplateListComponent } from './pages/institution-template-list/institution-template-list.component';
import { InstitutionTemplateDetailsComponent } from './pages/institution-template-details/institution-template-details.component';
import { TelemedicineConnectComponent } from './modals/telemedicine-connect/telemedicine-connect.component';
import { AddDiagnosisComponent } from './modals/add-diagnosis/add-diagnosis.component';
import { AddTherapiesComponent } from './modals/add-therapies/add-therapies.component';
import { FilterScheduleModalComponent } from './modals/filter-schedule/filter-schedule.component';
import { AppointmentSlotCommentEditComponent } from './modals/appointment-slot-comment-edit/appointment-slot-comment-edit.component';
import { PromotionListComponent} from './pages/promotion-list/promotion-list.component';
import { PromotionDetailsComponent } from './pages/promotion-details/promotion-details.component';
import { UserListComponent } from './pages/user-list/user-list.component';
import { UserDetailsComponent } from './pages/user-details/user-details.component';
import { AppointmentSlotDeleteComponent } from './modals/appointment-slot-delete/appointment-slot-delete.component';
import { AppointmentSlotDetailsComponent } from './modals/appointment-slot-details/appointment-slot-details.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    ScheduleComponent,
    PatientListComponent,
    PatientDetailsComponent,
    LoginComponent,
    DoctorListComponent,
    DoctorDetailsComponent,
    PatientVisitComponent,
    RegistrationComponent,
    ChangePasswordRequestComponent,
    ChangePasswordComponent,
    ProfileComponent,
    InstitutionDetailsComponent,
    InstitutionComponent,
    InstitutionSelectComponent,
    InstitutionServiceListComponent,
    InstitutionTemplateListComponent,
    InstitutionTemplateDetailsComponent,
    InvitationLoginComponent,
    InvitationRegistrationComponent,
    InvitationConnectComponent,
    PromotionListComponent,

    InvitationModalComponent,
    AppointmentSlotCreateModalComponent,
    AppointmentCreateModalComponent,
    AppointmentCancelModalComponent,
    PatientCreateModalComponent,
    PatientDocumentModalComponent,
    InstitutionServiceDetailsModalComponent,
    PatientDocumentUploadComponent,
    TelemedicineConnectComponent,

    AddDiagnosisComponent,
    AddTherapiesComponent,
    FilterScheduleModalComponent,
    AppointmentSlotCommentEditComponent,
    PromotionDetailsComponent,
    UserListComponent,
    UserDetailsComponent,
    AppointmentSlotDeleteComponent,
    AppointmentSlotDetailsComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MomentModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    PerfectScrollbarModule,
    NgbModule,
    ModalModule.forRoot(),
    AppRoutingModule,
    DCComponentModule
  ],
  providers: [
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    DcDatePipe
  ],
  entryComponents: [
    InvitationModalComponent,
    AppointmentSlotCreateModalComponent,
    AppointmentCreateModalComponent,
    AppointmentCancelModalComponent,
    PatientCreateModalComponent,
    PatientDocumentModalComponent,
    InstitutionServiceDetailsModalComponent,
    PatientDocumentUploadComponent,
    TelemedicineConnectComponent,
    AddDiagnosisComponent,
    AddTherapiesComponent,
    FilterScheduleModalComponent,
    AppointmentSlotCommentEditComponent,
    AppointmentSlotDeleteComponent,
    AppointmentSlotDetailsComponent,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
