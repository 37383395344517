import { Component, OnInit, ViewChild } from '@angular/core';
import { PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { InstitutionService } from '../../services/institution.service';
import { Institution } from '../../classes/institution';
import { UserService } from '../../services/user.service';
import { User } from '../../classes/user';

@Component({
  selector: 'main [dc-institution-template-list]',
  templateUrl: './institution-template-list.component.html'
})
export class InstitutionTemplateListComponent implements OnInit {
  @ViewChild(PerfectScrollbarDirective, { static: false }) perfectScrollbarDirectiveRef?: PerfectScrollbarDirective;
  institution: Institution;
  institutionTemplates: any[] = [];
  user: User;

  showSubheader: boolean = false;

  sort: any = {
    field: 'id',
    direction: 'asc'
  };

  sortOptions = [
    {
      id: 'name/desc',
      name: 'Imenu opadajuće'
    },
    {
      id: 'name/asc',
      name: 'Imenu rastuće'
    }
  ];

  loadingTemplates: boolean = true;

  constructor(
    private institutionService: InstitutionService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.getInstitution();
    this.getUser();
  }


  toggleSubheader() {
    this.showSubheader = !this.showSubheader;
  }

  getInstitution() {
    this.institutionService
      .currentInstitution
      .subscribe(
        (institution: Institution) => {
          if (institution) {
            this.institution = institution;
            this.getInstitutionTemplates();
          }
        },
        () => { }
      );
  }

  getInstitutionTemplates() {
    this.loadingTemplates = true;
    const params: any = {
      sort: this.sort.field,
      direction: this.sort.direction
    };
    this.institutionService
      .getInstitutionTemplates(params)
      .subscribe(
        (response: any) => {
          this.institutionTemplates = response.data;
          this.loadingTemplates = false;
        },
        () => {
          this.loadingTemplates = false;
        }
      );
  }

  getUser() {
    this.userService.currentUser
      .subscribe(
        (response) => {
          this.user = response;
        },
        () => {},
      );
  }

  sortDoctors(sort: string) {
    this.sort.field = sort.split('/')[0];
    this.sort.direction = sort.split('/')[1];
    this.getInstitutionTemplates();
  }

}
