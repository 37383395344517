import { Component, OnInit, ViewChild } from '@angular/core';
import { PatientService } from '../../services/patient.service';
import { Patient } from '../../classes/patient';
import { PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';

@Component({
  selector: 'main [dc-patient-list]',
  templateUrl: './patient-list.component.html'
})
export class PatientListComponent implements OnInit {
  @ViewChild(PerfectScrollbarDirective, { static: false }) perfectScrollbarDirectiveRef?: PerfectScrollbarDirective;
  patients: Patient[] = [];
  searchTerm: string = '';
  sort: any = {
    field: 'created_at',
    direction: 'desc'
  };
  pagination: any = {
    total: 0,
    current_page: 1,
    per_page: 15
  };

  loadingPatients: boolean = false;

  constructor(
    private patientService: PatientService
  ) { }

  ngOnInit() {
    this.getPatients();
  }

  sortPatients(field: string) {
    if (field === this.sort.field) {
      this.sort.direction = this.sort.direction === 'asc' ? 'desc' : 'asc';
    } else {
      this.sort.field = field;
      this.sort.direction = 'asc';
    }

    this.getPatients();
  }

  getPatients(append: boolean = false) {
    if (append && this.pagination.total && this.patients.length >= this.pagination.total) { return; }

    this.pagination.current_page = append ? this.pagination.current_page + 1 : 1;

    const params: any = {
      current_page: this.pagination.current_page,
      per_page: this.pagination.per_page,
      sort: this.sort.field,
      direction: this.sort.direction
    };
    if (this.searchTerm.length > 0) {
      params.search_term = this.searchTerm;
    }

    this.loadingPatients = !append;
    this.patientService
      .getPatients(params)
      .subscribe(
        (response: { data: Patient[], total: number }) => {
          if (append) {
            this.patients = this.patients.concat(response.data);
          } else {
            this.perfectScrollbarDirectiveRef.scrollToTop();
            this.patients = response.data;
            this.pagination.total = response.total;
          }
          this.loadingPatients = false;
        },
        (error) => {
          this.loadingPatients = false;
        }
      );
  }

}
