import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './guards/auth.guard';
import { InstitutionGuard } from './guards/institution.guard';

import { ScheduleComponent } from './pages/schedule/schedule.component';
import { PatientListComponent } from './pages/patient-list/patient-list.component';
import { PatientDetailsComponent } from './pages/patient-details/patient-details.component';
import { LoginComponent } from './pages/login/login.component';
import { DoctorListComponent } from './pages/doctor-list/doctor-list.component';
import { DoctorDetailsComponent } from './pages/doctor-details/doctor-details.component';
import { PatientVisitComponent } from './pages/patient-visit/patient-visit.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { ChangePasswordRequestComponent } from './pages/change-password-request/change-password-request.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { InstitutionDetailsComponent } from './pages/institution-details/institution-details.component';
import { InstitutionComponent } from './pages/institution/institution.component';
import { InstitutionSelectComponent } from './pages/institution-select/institution-select.component';
import { InvitationLoginComponent } from './pages/invitation-login/invitation-login.component';
import { InvitationRegistrationComponent } from './pages/invitation-registration/invitation-registration.component';
import { InvitationConnectComponent } from './pages/invitation-connect/invitation-connect.component';
import { InstitutionServiceListComponent } from './pages/institution-service-list/institution-service-list.component';
import { InstitutionTemplateListComponent } from './pages/institution-template-list/institution-template-list.component';
import { InstitutionTemplateDetailsComponent } from './pages/institution-template-details/institution-template-details.component';
import { UserResolver } from './resolvers/user.resolver';
import { PromotionListComponent } from './pages/promotion-list/promotion-list.component';
import { PromotionDetailsComponent } from './pages/promotion-details/promotion-details.component';
import { UserListComponent } from './pages/user-list/user-list.component';
import { UserDetailsComponent } from './pages/user-details/user-details.component';
import { RoleGuard } from './guards/role.guard';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    children: []
  },
  {
    path: 'invitation/:token/login',
    component: InvitationLoginComponent,
    children: []
  },
  {
    path: 'invitation/:token/registration',
    component: InvitationRegistrationComponent,
    children: []
  },
  {
    path: 'invitation/:token/connect',
    component: InvitationConnectComponent,
    children: []
  },
  {
    path: 'change-password/request',
    component: ChangePasswordRequestComponent,
    children: []
  },
  {
    path: 'change-password/token/:token',
    component: ChangePasswordComponent,
    children: []
  },
  {

    path: 'institution-select',
    component: InstitutionSelectComponent,
    children: [],
    canActivate: [AuthGuard]
  },
  {
    path: 'institution',
    component: InstitutionComponent,
    resolve: { user: UserResolver },
    children: [
      {
        path: 'schedule',
        component: ScheduleComponent,
        children: [],
        canActivate: [AuthGuard, InstitutionGuard]
      },
      {
        path: 'patient/list',
        component: PatientListComponent,
        children: [],
        canActivate: [AuthGuard, InstitutionGuard]
      },
      {
        path: 'patient/new',
        component: PatientDetailsComponent,
        children: [],
        canActivate: [AuthGuard, InstitutionGuard]
      },
      {
        path: 'patient/:patientId/details',
        component: PatientDetailsComponent,
        children: [],
        canActivate: [AuthGuard, InstitutionGuard]
      },
      {
        path: 'patient/:patientId/visit',
        component: PatientVisitComponent,
        children: [],
        canActivate: [AuthGuard, InstitutionGuard]
      },
      {
        path: 'patient/:patientId/visit/:visitId',
        component: PatientVisitComponent,
        children: [],
        canActivate: [AuthGuard, InstitutionGuard]
      },
      {
        path: 'promotion/list',
        component: PromotionListComponent,
        canActivate: [AuthGuard, InstitutionGuard]
      },
      {
        path: 'promotion/new',
        component: PromotionDetailsComponent,
        canActivate: [AuthGuard, InstitutionGuard]
      },
      {
        path: 'promotion/:promotionId/details',
        component: PromotionDetailsComponent,
        canActivate: [AuthGuard, InstitutionGuard]
      },
      {
        path: 'doctor/list',
        component: DoctorListComponent,
        children: [],
        canActivate: [AuthGuard, InstitutionGuard]
      },
      {
        path: 'doctor/new',
        component: DoctorDetailsComponent,
        children: [],
        canActivate: [AuthGuard, InstitutionGuard]
      },
      {
        path: 'doctor/:doctorId/details',
        component: DoctorDetailsComponent,
        children: [],
        canActivate: [AuthGuard, InstitutionGuard]
      },
      {
        path: 'user/list',
        component: UserListComponent,
        children: [],
        canActivate: [AuthGuard, InstitutionGuard, RoleGuard],
        data: {
          roles: ['Global Super Administrator', 'Institution Owner']
        }
      },
      {
        path: 'user/new',
        component: UserDetailsComponent,
        canActivate: [AuthGuard, InstitutionGuard, RoleGuard],
        data: {
          roles: ['Global Super Administrator', 'Institution Owner']
        }
      },
      {
        path: 'user/:userId/details',
        component: UserDetailsComponent,
        children: [],
        canActivate: [AuthGuard, InstitutionGuard, RoleGuard],
        data: {
          roles: ['Global Super Administrator', 'Institution Owner']
        }
      },
      {
        path: 'service/list',
        component: InstitutionServiceListComponent,
        children: [],
        canActivate: [AuthGuard, InstitutionGuard]
      },
      {
        path: 'template/list',
        component: InstitutionTemplateListComponent,
        children: [],
        canActivate: [AuthGuard, InstitutionGuard]
      },
      {
        path: 'template/new',
        component: InstitutionTemplateDetailsComponent,
        children: [],
        canActivate: [AuthGuard, InstitutionGuard]
      },
      {
        path: 'template/:institutionTemplateId/details',
        component: InstitutionTemplateDetailsComponent,
        children: [],
        canActivate: [AuthGuard, InstitutionGuard]
      },
      {
        path: 'details',
        component: InstitutionDetailsComponent,
        children: [],
        canActivate: [AuthGuard, InstitutionGuard]
      },
      {
        path: 'profile',
        component: ProfileComponent,
        children: [],
        canActivate: [AuthGuard, InstitutionGuard]
      }
    ]
  },
  { path: '', redirectTo: '/institution/schedule', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
