import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { DCCollapseComponent } from './collapse.component';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[dcCollapse]'
})
export class CollapseDirective implements OnInit {

  @Input() dcCollapse: string;

  collapsed: Subscription;

  constructor(
    private element: ElementRef,
    private collapseComponent: DCCollapseComponent
  ) { }

  ngOnInit(): void {
    this.collapseComponent.collapsedSubscription.subscribe(
      (res: any) => {
        this.collapsed = res;
        if (this.collapsed) {
          this.element.nativeElement.classList.add('collapsed');
        } else {
          this.element.nativeElement.classList.remove('collapsed');
        }
      }
    );
  }
}
