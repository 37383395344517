<div class="dc-subheader"  [class.show-nav]="showSubheader">
    <div class="dc-container">
        <div class="dc-row middle">
            <div class="dc-col xs-12">
                <ul class="navigation">
                  <li>
                    <a [routerLink]="['/institution', 'details']" translate>Settings</a>
                  </li>
                  <li>
                    <a [routerLink]="['/institution', 'template', 'list']" routerLinkActive="active" translate>Templates</a>
                  </li>
                  <li>
                    <a [routerLink]="['/institution', 'service', 'list']" translate>Services</a>
                  </li>
                  <li>
                    <a [routerLink]="['/institution', 'promotion', 'list']" translate>Promotions</a>
                  </li>
                  <li>
                    <a [routerLink]="['/institution', 'doctor', 'list']" translate>Doctors</a>
                  </li>
                  <li *ngIf="user.roles.includes('Global Super Administrator') || user.roles.includes('Institution Owner')">
                    <a [routerLink]="['/institution', 'user', 'list']" translate>Users</a>
                  </li>
                </ul>
              <div class="subheader-responsive">
                <span translate>Information</span>
                <span class="nav-toggle" (click)="toggleSubheader()">...</span>
                <span class="dc-icon remove-close" (click)="toggleSubheader()"></span>
              </div>
            </div>
        </div>
    </div>
  </div>
<div class="dc-section dc-content" [perfectScrollbar]="{ suppressScrollX: true}">
    <div [dcLoading]="loadingTemplates"></div>
    <div class="dc-container">
      <div class="dc-row middle">
        <div class="dc-col lg-6">
          <div class="dc-row middle">
            <span class="dc-label__additional-horizontal dc-text-color__gray-medium mr-8">{{'Sort by' | translate}}:</span>
            <div class="dc-col lg-4 mt-0">
              <dc-select [hideLabel]="true" [options]="sortOptions" [showClearButton]="false" (valueChange)="sortDoctors($event)"></dc-select>
            </div>
          </div>
        </div>
        <div class="dc-col sm-6 end">
          <dc-button icon="plus" size="small" [routerLink]="[ '..', 'new' ]">{{ 'Add template' | translate }}</dc-button>
        </div>
      </div>
      <div class="dc-row">
        <div class="dc-col">
          <table class="dc-table">
            <tbody>
              <tr class="dc-clickable" *ngFor="let institutionTemplate of institutionTemplates" [routerLink]="[ '..', institutionTemplate.id, 'details' ]">
                <td>
                  <label class="dc-table__label" translate>Service name</label>
                  <div class="dc-table__value">
                    <span>{{ institutionTemplate.name }}</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
</div>
