<div class="dc-progress__steps" *ngIf="type === 'content' && steps">
  <div class="steps"
       *ngFor="let step of content; let i = index" [hidden]="i === content.length - 1"
       [ngClass]="{'last': i === content.length - 1}">
    <div class="step"
         [ngClass]="{'done': selectedSteps.includes(i + 2), 'selected': currentStep === i + 1}"
         (click)="select(step, i)">
      <span class="step-icon">{{i + 1}}</span>
    </div>
    <div class="progress"
         [ngClass]="{'done': selectedSteps.includes(i + 2), 'selected': currentStep === i + 1}"
         *ngIf="i !== content.length">
    </div>
  </div>
</div>
<div class="dc-progress__bar bar" *ngIf="type === 'bar' && showProgressBar">
  <div class="progress" [style.width]="percent + '%'"></div>
</div>
<div class="dc-progress__bar circle" *ngIf="type === 'circle' && showProgressBar">
  <div class="progress">
    <svg>
      <circle  cx="32" cy="32" r="32"></circle>
      <circle  cx="32" cy="32" r="32" [ngStyle]="calcCircleBarProgress" #progress></circle>
    </svg>
  </div>
</div>
<ng-content></ng-content>
