import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DCDropdownComponent } from './dropdown.component';
import { DCOffClickModule } from '../off-click/off-click.module';
import { DCDropdownMenuComponent } from './dropdown-menu.component';



@NgModule({
  declarations: [
    DCDropdownComponent,
    DCDropdownMenuComponent
  ],
  imports: [
    CommonModule,
    DCOffClickModule
  ],
  exports: [
    DCDropdownComponent,
    DCDropdownMenuComponent
  ],
  providers: [
  ]
})
export class DCDropdownModule { }
