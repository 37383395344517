<div class="dc-datepicker">
  <label>
    <span class="dc-datepicker__label">
    {{ label }}
      <sup class="required" *ngIf="label && required">*</sup>
    </span>
    <span *ngIf="showIcon" class="dc-datepicker__icon dc-icon date" [owlDateTimeTrigger]="dt"></span>
    <input
      (ngModelChange)="emitChange($event)"
      (blur)="checkInputMask($event)"
      [owlDateTime]="dt"
      [owlDateTimeTrigger]="!inputMask ? dt : undefined"
      [min]="min"
      [max]="max"
      [disabled]="disabled"
      [readOnly]="readOnly"
      [required]="required"
      [(ngModel)]="value"
      (keydown.tab)="dt.close()"
      (keydown.shift.tab)="dt.close()"
      (keyup.arrowDown)="dt.open()"
      [placeholder]="placeholder"
      [ngClass]="{
      'requiredFull': requiredType === 'full',
      'error': errors.length > 0
      }"
      #dateTimeInput>
  </label>
  <span class="dc-datepicker__clear" [class.showIcon]="showIcon" *ngIf="value" (click)="clear()"></span>
  <owl-date-time  #dt
                  [disabled]="disabled"
                  [pickerType]="type"
                  [startView]="startView"
                  (afterPickerOpen)="pickerOpen()"
                  (afterPickerClosed)="pickerClosed()">
  </owl-date-time>
</div>
<div *ngIf="errors.length > 0" class="dc-datepicker__error-box">
  <div *ngIf="errors.length === 1" class="dc-datepicker__error single">
    <span>{{ errorMessages[errors[0]] || errors[0] }}</span>
  </div>
  <div *ngIf="errors.length > 1" class="dc-datepicker__error multi">
    <p (click)="toggleErrors()">
      <span>Imate {{ errors.length }} greške</span>
    </p>
    <div *ngIf="showErrors">
      <ul>
        <li *ngFor="let error of errors">
          {{ errorMessages[error] || error }}
        </li>
      </ul>
    </div>
  </div>
</div>
