<div class="dc-modal small patient-document-upload-modal">
  <div class="dc-modal__header">
    <h2 class="dc-modal__title">Dodaj prilog</h2>
    <span class="dc-modal__close" (click)="close()"></span>
  </div>
  <div class="dc-modal__body body-wrapper" [perfectScrollbar]="configScroll">
    <dc-progress-bar [currentStep]="currentStep">
      <dc-progress-content>
        <div class="dc-row" *ngIf="files && !showProgressBar">
          <div class="dc-col file-list">
            <div class="info">
              <div class="info-text">
                <span>Fajl</span>
                <p>{{files[0].file.name}}</p>
                <p class="info-size">{{'File size' | translate}}: {{(fileSize/1000000).toFixed(2)}}mb</p>
              </div>
            </div>
          </div>
          <p class="error-text" *ngIf="fileSize > 16000000">{{'Max file size is' | translate}} 16MB</p>
        </div>
      </dc-progress-content>
      <dc-progress-content [formGroup]="documentInfo">
        <div class="dc-row" [dcLoading]="showLoading">
          <div class="dc-col">
            <dc-select label="{{ 'Type' | translate }}" formControlName="type" [options]="typeOptions"></dc-select>
          </div>

          <div class="dc-col">
            <dc-datepicker *ngIf="!showLoading" label="{{ 'Date' | translate }}" [type]="'calendar'" formControlName="date"></dc-datepicker>
          </div>

          <div class="dc-col">
            <dc-input label="{{ 'Name' | translate }}" formControlName="name"></dc-input>
          </div>

          <div class="dc-col">
            <dc-input *ngIf="!showLoading" label="{{ 'Description' | translate }}" [type]="'textarea'" formControlName="description"></dc-input>
          </div>
        </div>
      </dc-progress-content>
    </dc-progress-bar>
  </div>
  <div class="footer">
    <button class="cancel" [disabled]="showLoading" (click)="close()">{{'Cancel' | translate}}</button>
    <div class="actions">
      <div class="action" *ngIf="currentStep > 1">
        <span class="dc-icon arrow-left"></span>
        <button [disabled]="showLoading" (click)="previousStep()">{{'Back' | translate}}</button>
      </div>
      <div class="action" *ngIf="currentStep > 1">
        <button [disabled]="showLoading || documentInfo.invalid" (click)="upload()">{{'Add' | translate}}</button>
        <span class="dc-icon" [class.disabled]="documentInfo.invalid"></span>
      </div>
      <div class="action" *ngIf="currentStep < 2">
        <button [disabled]="showLoading || fileSize > 16000000" (click)="nextStep()">{{'Next' | translate}}</button>
        <span class="dc-icon arrow-right"></span>
      </div>
    </div>
  </div>
</div>
