<div class="dc-modal small">
  <div class="dc-modal__header">
      <h2 class="dc-modal__title">{{ 'Document' | translate }}</h2>
      <span class="dc-modal__close" (click)="close()"></span>
  </div>
  <div class="dc-modal__body">
    <form #patientDocumentForm="ngForm">
      <div class="dc-row">
        <div class="dc-col md-12">
          <dc-select
            label="{{ 'Type' | translate }}"
            [(ngModel)]="document.type"
            [ngModelOptions]="{ standalone: true }"
            [options]="typeOptions"
          ></dc-select>
        </div>
        <div class="dc-col md-12">
          <label for="file" translate>File</label>
          <input id="file" type="file" class="margin-vertical" name="file" #fileInput required>
        </div>
        <div class="dc-col md-12">
          <dc-datepicker 
            label="{{ 'Date' | translate }}" 
            type="calendar"
            [(ngModel)]="document.date" 
            [ngModelOptions]="{ standalone: true }"
          ></dc-datepicker>
        </div>
        <div class="dc-col md-12">
          <dc-input
            label="{{ 'Name' | translate }}" 
            [(ngModel)]="document.name" 
            [ngModelOptions]="{ standalone: true }"
          ></dc-input>
        </div>
        <div class="dc-col md-12">
          <dc-input
            label="{{ 'Description' | translate }}" 
            type="textarea"
            [(ngModel)]="document.description" 
            [ngModelOptions]="{ standalone: true }"
          ></dc-input>
        </div>
      </div>
    </form>
  </div>
  <div class="dc-modal__footer">
      <dc-button color="outline" (click)="close()">{{ 'Cancel' | translate }}</dc-button>
      &nbsp;
      <dc-button (click)="saveDocument()">{{ 'Save' | translate }}</dc-button>
  </div>
</div>