import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'dc-empty-state',
  templateUrl: './empty-state.component.html'
})
export class DCEmptyStateComponent implements OnInit {

  @Input() type: string
    | 'appointment' | 'institution' | 'doctor' | 'document'
    | 'invitation' | 'medicine' | 'notification' | 'promotion'
    | 'schedule-appointment' | 'visit' | 'logged-in';
  @Input() message: string;

  constructor() { }

  ngOnInit(): void {
  }

}
