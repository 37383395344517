<div class="dc-text-editor" *ngIf="editor">
  <span class="dc-text-editor__label" *ngIf="label">
    {{label}}
    <sup class="required" *ngIf="label && required">*</sup>
  </span>
  <ckeditor [editor]="editor"
            [(ngModel)]="value"
            (ngModelChange)="onChangeValue()"
            (blur)="displayError();toggleOnActive()"
            (focus)="toggleOnActive()"
            [disabled]="disabled"
            [required]="required"
            [config]="config"
            (ready)="onReady($event)"
            [name]="name"
            [ngClass]="{'focused': focused, 'error': errors.length > 0, 'disabled': disabled}"></ckeditor>
  <div *ngIf="errors.length > 0" class="dc-input__error-box">
    <div *ngIf="errors.length === 1" class="dc-input__error single">
      <span>{{ errorMessages[errors[0]] || errors[0] }}</span>
    </div>
    <div *ngIf="errors.length > 1" class="dc-input__error multi">
      <span (click)="toggleErrors()">Imate {{ errors.length }} greške</span>
      <div *ngIf="showErrors">
        <ul>
          <li *ngFor="let error of errors">
            {{ errorMessages[error] || error }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
