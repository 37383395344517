import { Institution } from './institution';

export class Invitation {
  id: string;
  profile_id: number;
  profile_type: string;
  institution_id: number;
  email: string;
  created_at: string;
  expires_at: string;
  token: string;

  profile: any;
  institution: Institution;

  constructor(values: object = {}) {
    Object.assign(this, values);
  }
}
