import { User } from './user';
import { Specialty } from './specialty';

export class Doctor {
  id: number;
  user_id: number;
  title: string;
  first_name: string;
  last_name: string;
  photo_url: string;
  email: string;
  phone_1: string;
  phone_2: string;
  active: boolean = true;

  user: User;
  specialties: Specialty[];

  constructor(values: object = {}) {
    Object.assign(this, values);
  }
}
