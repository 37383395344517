import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { AppointmentService } from "../../services/appointment.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalService } from "../../../../../../libs/ui/src/lib/components/modal/modal.service";
import { Appointment } from "../../classes/appointment";
import { DCToastService } from "../../../../../../libs/ui/src/lib/components/toast/toast.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'doc-appointment-slot-comment-edit',
  templateUrl: './appointment-slot-comment-edit.component.html'
})
export class AppointmentSlotCommentEditComponent implements OnInit {
  @Input() appointment: Appointment;
  appointmentCommentFrom: FormGroup;

  showLoading: boolean = false;

  constructor(
    private appointmentService: AppointmentService,
    private modalService: ModalService,
    private formBuilder: FormBuilder,
    private activeModal: NgbActiveModal,
    private toastService: DCToastService,
    private translateService: TranslateService,
  ) { }

  ngOnInit() {
    this.initForm();
    this.appointmentCommentFrom.patchValue(this.appointment);
  }

  initForm() {
    this.appointmentCommentFrom = this.formBuilder.group({
      id: [null],
      comment: ['']
    });
  }

  editComment() {
    this.showLoading = true;
    this.appointmentService
      .changeAppointment(this.appointmentCommentFrom.value)
      .subscribe(
        () => {
          this.activeModal.close();
          this.toastService.show(
            {
              type: 'success',
              message: this.translateService.instant('Comment successfully updated')
            }
          )
          this.showLoading = false;
        },
        (error) => {
          // this.modalService.error({ errors: error });
          this.toastService.show(
            {
              type: 'error',
              message: this.translateService.instant('Failed to update comment')
            }
          )
          this.showLoading = false;
        }
      );
  }

  close() {
    this.activeModal.dismiss();
  }

}
