<div class="dc-subheader"  [class.show-nav]="showSubheader">
  <div class="dc-container">
      <div class="dc-row middle">
          <div class="dc-col xs-12">
              <ul class="navigation">
                <li class="subheader-info">
                  <a translate>Information</a>
                </li>
                <li>
                  <a [routerLink]="['/institution', 'details']" routerLinkActive="active" translate>Settings</a>
                </li>
                <li>
                  <a [routerLink]="['/institution', 'template', 'list']" translate>Templates</a>
                </li>
                <li>
                  <a [routerLink]="['/institution', 'service', 'list']" translate>Services</a>
                </li>
                <li>
                  <a [routerLink]="['/institution', 'promotion', 'list']" translate>Promotions</a>
                </li>
                <li>
                  <a [routerLink]="['/institution', 'doctor', 'list']" translate>Doctors</a>
                </li>
                <li *ngIf="user.roles.includes('Global Super Administrator') || user.roles.includes('Institution Owner')">
                  <a [routerLink]="['/institution', 'user', 'list']" translate>Users</a>
                </li>
              </ul>
            <div class="subheader-responsive">
              <span translate>Information</span>
              <span class="nav-toggle" (click)="toggleSubheader()">...</span>
              <span class="dc-icon remove-close" (click)="toggleSubheader()"></span>
            </div>
          </div>
      </div>
  </div>
</div>
<section class="dc-section dc-content" [perfectScrollbar]="{ suppressScrollX: true}">
  <div [dcLoading]="loadingInstitution"></div>
  <div class="dc-container">
    <div class="dc-row middle">
      <div class="dc-col sm-12">
        <h1>
          <div class="super-heading" translate>Institution</div>
          <span>{{ institution.name }}</span>
        </h1>
      </div>
    </div>
    <form class="dc-row space-32" [formGroup]="institutionForm">
      <div class="dc-col md-6">
        <div class="dc-row space-16">
          <div class="dc-col md-12">
              <h2 class="dc-text-color-primary" translate>Information</h2>
          </div>
          <div class="dc-col md-3">
            <dc-image
              formControlName="logo_url"
              [label]="'Logo' | translate"
            ></dc-image>
          </div>
          <div class="dc-col md-9">
            <dc-input
                label="{{ 'Institution name' | translate }}"
                formControlName="name"
            ></dc-input>
          </div>
          <div class="dc-col md-12">
            <dc-select
                label="{{ 'Specialties' | translate }}"
                formControlName="specialty_ids"
                [options]="specialties"
                [multi]="true"
            ></dc-select>
          </div>
        </div>
      </div>
      <div class="dc-col md-6">
        <div class="dc-col md-12">
          <h2 class="dc-text-color-primary" translate>Show institution</h2>
        </div>
        <div class="dc-col md-12">
          <p>Prikazivanje institucije na <a href="https://doktorijum.com">Doktorijum.com</a> sajtu ili u Doktorijum mobilnoj aplikaciji omogućuje Doktorijum pacijentima da sami zakazuju termine u instituciji.</p>
        </div>
        <div class="dc-col md-12">
          <dc-checkbox label="Prikaži na Doktorijum.com sajtu" formControlName="show_on_website"></dc-checkbox>
        </div>
        <div class="dc-col md-12">
          <dc-checkbox label="Prikaži u Doktorijum mobilnoj aplikaciji" formControlName="show_in_application"></dc-checkbox>
        </div>
      </div>
      <div class="dc-col md-6">
        <div class="dc-row space-12">
          <div class="dc-col md-12">
            <h2 class="dc-text-color-primary" translate>Location</h2>
          </div>
          <div class="dc-col md-6">
            <dc-select
                label="{{ 'Country' | translate }}"
                formControlName="country_id"
                [options]="countries"
            ></dc-select>
          </div>
          <div class="dc-col md-6">
            <dc-input
                label="{{ 'City' | translate }}"
                formControlName="city"
            ></dc-input>
          </div>
          <div class="dc-col md-12">
            <dc-input
                label="{{ 'Address' | translate }}"
                formControlName="address"
            ></dc-input>
          </div>
          <div class="dc-col md-12 padding-top" *ngIf="institution.longitude && institution.latitude">
            <dc-map
              [center]="[ institution.longitude, institution.latitude ]"
              [markerCenter]="[ institution.longitude, institution.latitude ]"
            ></dc-map>
          </div>
          <div class="dc-col md-12">
              <h2 class="dc-text-color-primary" translate>Transportation</h2>
          </div>
          <div class="dc-col md-6">
            <div class="dc-row">
              <div class="dc-col md-2">
                <span class="dc-icon bus" style="font-size: 32px; color: #c6c6c6; margin: 10px 0px;"></span>
              </div>
              <div class="dc-col md-10">
                <dc-input
                    formControlName="transportation_bus"
                ></dc-input>
              </div>
              <div class="dc-col md-2">
                <span class="dc-icon subway" style="font-size: 32px; color: #c6c6c6; margin: 10px 0px;"></span>
              </div>
              <div class="dc-col md-10">
                <dc-input
                    formControlName="transportation_tram"
                ></dc-input>
              </div>
              <div class="dc-col md-2">
                <span class="dc-icon trolley" style="font-size: 32px; color: #c6c6c6; margin: 10px 0px;"></span>
              </div>
              <div class="dc-col md-10">
                <dc-input
                    formControlName="transportation_trolley"
                ></dc-input>
              </div>
            </div>
          </div>
          <div class="dc-col md-6">
            <div class="dc-row">
              <div class="dc-col md-2">
                <span class="dc-icon parking" style="font-size: 32px; color: #c6c6c6; margin: 10px 0px;"></span>
              </div>
              <div class="dc-col md-10">
                <div class="padding-vertical">
                  <dc-switch
                      style="justify-content: space-between;"
                      label="{{ 'Parking available' | translate }}"
                      formControlName="parking_available"
                  ></dc-switch>
                </div>
              </div>
              <div class="dc-col md-2">
                <span class="dc-icon wheelchair-access" style="font-size: 32px; color: #c6c6c6; margin: 10px 0px;"></span>
              </div>
              <div class="dc-col md-10">
                <div class="padding-vertical">
                  <dc-switch
                      style="justify-content: space-between;"
                      label="{{ 'Wheelchair accessible' | translate }}"
                      formControlName="wheelchair_accessible"
                  ></dc-switch>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="dc-col md-6">
        <div class="dc-row space-12">
          <div class="dc-col md-12">
              <h2 class="dc-text-color-primary" translate>Contact</h2>
          </div>
          <div class="dc-col md-6">
            <dc-input
                label="{{ 'Phone 1' | translate }}"
                formControlName="phone_1"
            ></dc-input>
          </div>
          <div class="dc-col md-6">
            <dc-input
                label="{{ 'Phone 2' | translate }}"
                formControlName="phone_2"
            ></dc-input>
          </div>
          <div class="dc-col md-6">
            <dc-input
                label="{{ 'Email' | translate }}"
                formControlName="email"
            ></dc-input>
          </div>
          <div class="dc-col md-12">
              <h2 class="dc-text-color-primary" translate>Short description</h2>
          </div>
          <div class="dc-col md-12">
            <dc-text-editor
              formControlName="description"
            ></dc-text-editor>
          </div>
        </div>
      </div>
    </form>
  </div>
</section>
<div class="dc-bottom-bar">
  <div class="dc-container flex" style="height: 100%; align-items: center;">
    <dc-button size="small" (click)="save()" [loading]="savingInstitution" [disabled]="institutionForm.invalid">{{ 'Save' | translate }}</dc-button>
  </div>
</div>
