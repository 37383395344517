<div class="dc-modal small filter">
  <div class="dc-modal__header">
    <h2 class="dc-modal__title">{{ 'Filters' | translate }}</h2>
    <span class="dc-modal__close" tabindex="0" (click)="close()"></span>
  </div>
  <div class="dc-modal__body" [dcLoading]="showLoading">
    <div class="dc-row">
      <div class="dc-col">
        <dc-select label="{{'Show appointments' | translate}}" [options]="appointmentType" [(ngModel)]="filters.status"></dc-select>
      </div>
      <div class="dc-col">
        <dc-select label="{{'Specialties' | translate}}" [options]="specialties" [(ngModel)]="filters.specialty" placeholder="{{'Select specialty' | translate}}"></dc-select>
      </div>
      <div class="dc-col">
        <dc-select label="{{'Doctors' | translate}}" [options]="doctors" [(ngModel)]="filters.doctor" placeholder="{{'Select doctor' | translate}}"></dc-select>
      </div>
    </div>
  </div>
  <div class="dc-modal__actions">
    <div class="dc-modal__action-left">
      <dc-button color="outline" (click)="removeFilters()">{{ 'Remove filters' | translate }}</dc-button>
    </div>
    <div class="dc-modal__action-right">
      <dc-button color="outline" (click)="close()">{{ 'Cancel' | translate }}</dc-button>
      <dc-button (click)="apply()">{{ 'Apply' | translate }}</dc-button>
    </div>
  </div>
</div>
