<div class="public">
  <img class="logo" src="assets/images/logo-white.svg" alt="Doktorijum">

  <div *ngIf="!loading">
    <div *ngIf="invitation; else notValidInvitation">
      <div class="institution">
        <img [src]="institution?.logo_url ? institution.logo_url : 'assets/images/institution-placeholder.svg'">
        <h1>{{ institution?.name }}</h1>
      </div>

      <div class="form">
        <form #registrationForm="ngForm">
          <h2 translate>Registration</h2>

          <div class="message error" *ngIf="error">
            <div class="text" translate>{{ error }}</div>
            <div class="icon close" (click)="clearError()"></div>
          </div>

          <div class="dc-row" *ngIf="token">
            <div class="dc-col">
              <dc-input
                [label]="'Email (username)' | translate"
                [(ngModel)]="email"
                [ngModelOptions]="{standalone: true}"
                (ngModelChange)="clearError()"
              ></dc-input>
            </div>
            <div class="dc-col">
              <dc-input
                type="password"
                [label]="'Password' | translate"
                [(ngModel)]="password"
                [ngModelOptions]="{standalone: true}"
                (ngModelChange)="clearError()"
              ></dc-input>
              <div class="text-center" style="margin-top: 4px">
                <small translate>Password should be at least 8 characters long and contain at least one digit.</small>
              </div>
            </div>
            <div class="dc-col">
              <dc-input
                type="password"
                [label]="'Confirm password' | translate"
                [(ngModel)]="confirm"
                [ngModelOptions]="{standalone: true}"
                (ngModelChange)="clearError()"
              ></dc-input>
            </div>
            <div class="dc-col">
              <dc-input
                [label]="'First name' | translate"
                [(ngModel)]="firstName"
                [ngModelOptions]="{standalone: true}"
                (ngModelChange)="clearError()"
              ></dc-input>
            </div>
            <div class="dc-col">
              <dc-input
                [label]="'Last name' | translate"
                [(ngModel)]="lastName"
                [ngModelOptions]="{standalone: true}"
                (ngModelChange)="clearError()"
              ></dc-input>
            </div>
            <div class="dc-col">
              <dc-button size="full" (click)="register()" [disabled]="!registrationForm.form.valid">{{ 'Register' | translate }}</dc-button>
            </div>
          </div>
        </form>

        <div>
          <div class="register">
            <span translate>Registered?</span>
            <a [routerLink]="['/invitation/' + token + '/login']" translate>Login</a>
          </div>
        </div>
      </div>
    </div>

    <ng-template #notValidInvitation>
      <div class="form">
        <h2 translate>Invitation is not valid</h2>
        <p class="text-center" translate>{{ error }}</p>
      </div>
    </ng-template>
  </div>

</div>
