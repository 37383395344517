<div class="public">
    <img class="logo" src="assets/images/logo-white.svg" alt="Doktorijum">

    <div class="form">
        <h2 translate>Select institution</h2>
        <div class="institution-list">
            <div class="institution" *ngFor="let institution of institutions" (click)="selectInstitution(institution)">
                <img [src]="institution.logo_url ? institution.logo_url : 'assets/images/institution-placeholder.svg'">
                <div>{{ institution.name }}</div>
            </div>
        </div>
    </div>
</div>
