import { Component } from '@angular/core';
import { Institution } from '../../classes/institution';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';
import { InvitationService } from '../../services/invitation.service';
import { Invitation } from '../../classes/invitation';
import { User } from '../../classes/user';

@Component({
  selector: 'app-invitation-connect',
  templateUrl: './invitation-connect.component.html'
})
export class InvitationConnectComponent {
  loading: boolean = true;
  user: User = null;
  token: string = null;
  error: string = null;
  invitation: Invitation = null;
  institution: Institution = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    private invitationService: InvitationService,
  ) {
    this.token = this.route.snapshot.paramMap.get('token');
    userService.currentUser
      .subscribe(user => this.user = user );

    if (this.token) {
      this.getInvitation();
    }
    if (localStorage.getItem('jwt')) {
      userService.getCurrentUser();
    }
  }

  getInvitation() {
    this.authService.getInvitation(this.token, {include: 'profile,institution'})
      .subscribe(
        (response) => {
          this.invitation = response.data;

          if (this.invitation.institution) {
            this.institution = this.invitation.institution;
          }
          this.loading = false;
        },
        (error) => {
          this.error = error.error.message;
          this.loading = false;
        }
      );
  }

  connect() {
    this.invitationService.connect(this.token)
      .subscribe(
        () => {
          this.userService.getCurrentUserInstitutions()
            .subscribe(
              (response) => {
                const institutions: Institution[] = response.data;

                if (institutions.length > 1) {
                  this.router.navigate(['/institution-select']);
                } else if (institutions.length === 1) {
                  localStorage.setItem('currentInstitutionKey', institutions[0].institution_guid);
                  this.router.navigate(['/institution/schedule']);
                } else if (institutions.length === 0) {
                  this.router.navigate(['/profile']);
                }
              },
              () => { }
            );
        },
        (error) => {
          this.error = error.error.message;
        }
      );
  }

  logout() {
    this.authService.logout()
      .subscribe(
        () => {
          this.router.navigate([ '/invitation/' + this.token + '/login' ]);
        }
      );
  }

  clearError() {
    this.error = null;
  }

}
