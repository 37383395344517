<div class="dc-modal small">
    <div class="dc-modal__header">
        <h2 class="dc-modal__title">{{ 'Send invitation' | translate }}</h2>
        <span class="dc-modal__close" (click)="close()"></span>
    </div>
    <div class="dc-modal__body">
        <div class="dc-row">
            <div class="dc-col md-12">
                <div class="margin-bottom" translate>Send invitaton to the following email address</div>
            </div>
            <div class="dc-col md-12">
                <form [formGroup]="invitationForm">
                    <dc-input
                        label="{{ 'Email' | translate }}"
                        formControlName="email"
                        type="email"
                    ></dc-input>
                </form>
            </div>
        </div>
    </div>
    <div class="dc-modal__actions">
        <div class="dc-modal__action-left">
            <dc-button color="outline" (click)="close()">{{ 'Cancel' | translate }}</dc-button>
        </div>
        <div class="dc-modal__action-right">
            <dc-button (click)="invite()" [disabled]="invitationForm.invalid">{{ 'Send' | translate }}</dc-button>
        </div>
    </div>
</div>