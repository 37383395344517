import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: '[dcLoading]',
  templateUrl: './loading.component.html'
})
export class DCLoadingComponent implements OnInit {

  // tslint:disable-next-line:no-input-rename
  @Input('dcLoading') showLoading: boolean = false;
  @Input() backgroundColor: string;
  @Input() spinnerColor: string | 'default' | 'white' = 'default';
  @Input() fixed: boolean = false;

  constructor() { }

  ngOnInit() {
  }

}
