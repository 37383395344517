import { Injectable } from '@angular/core';
import { Appointment } from '../classes/appointment';
import { AppointmentSlot } from '../classes/appointment-slot';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class AppointmentService {

  constructor(
    private apiService: ApiService
  ) { }

  getAppointments(query: any = {}) {
    return this.apiService.get(`appointment`, query);
  }

  getAppointment(appointmentId: number, query: any = {}) {
    return this.apiService.get(`appointment/${appointmentId}`, query);
  }

  createAppointment(appointment: Appointment) {
    return this.apiService.post(`appointment`, appointment);
  }

  changeAppointment(appointment: Appointment) {
    return this.apiService.put(`appointment/${appointment.id}`, appointment );
  }

  cancelAppointment(appointmentId: number, params: any) {
    return this.apiService.put(`appointment/${appointmentId}/cancel`, params);
  }

  getAppointmentSlots(query: any = {}) {
    return this.apiService.get(`appointment_slot`, query);
  }

  getAppointmentSlot(appointmentSlotId: number, query: any = {}) {
    return this.apiService.get(`appointment_slot/${appointmentSlotId}`, query);
  }

  createAppointmentSlot(appointmentSlot: AppointmentSlot) {
    return this.apiService.post(`appointment_slot`, appointmentSlot );
  }

  changeAppointmentSlot(appointmentSlot: AppointmentSlot) {
    return this.apiService.put(`appointment_slot/${appointmentSlot.id}`, appointmentSlot );
  }

  deleteAppointmentSlot(appointmentSlotId: number, query: any = {}) {
    return this.apiService.delete(`appointment_slot/${appointmentSlotId}`, query );
  }

  deleteAppointmentSlotSeries(guid: string) {
    return this.apiService.delete(`appointment_slot/${guid}/group`);
  }

  generateAppointmentSlots(query: any) {
    return this.apiService.post(`appointment_slot/generate`, query);
  }

}
