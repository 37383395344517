import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Promotion } from '../classes/promotion';

@Injectable({
  providedIn: 'root'
})
export class PromotionService {

  constructor(
    private apiService: ApiService
  ) { }

  getPromotion(promotionId: number, query: any = {}) {
    return this.apiService.get(`institution/current/promotions/${promotionId}`, query);
  }

  getPromotions(query: any = {}) {
    return this.apiService.get(`institution/current/promotions`, query);
  }

  createPromotion(promotion: Promotion) {
    return this.apiService.post(`institution/current/promotions`, promotion );
  }

  updatePromotion(promotionId: number, query: any = {}) {
    return this.apiService.put(`institution/current/promotions/${promotionId}`, query);
  }

  deletePromotion(promotionId: number) {
    return this.apiService.delete(`institution/current/promotions/${promotionId}`);
  }
}
