import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Therapies } from "../../classes/therapies";
import { TherapyService } from "../../services/therapy.service";

@Component({
  selector: 'doc-add-therapies',
  templateUrl: './add-therapies.component.html'
})
export class AddTherapiesComponent implements OnInit {

  selectedTherapy: any;
  therapiesSearchTerm: string;
  therapiesSearchResults: Therapies[] = [];
  description: string;
  constructor(
    private activeModal: NgbActiveModal,
    private therapieService: TherapyService,
  ) { }

  ngOnInit() {
  }

  searchTherapies() {
    if (this.therapiesSearchTerm.length > 2) {
      this.therapieService.getTherapies({ search_term: this.therapiesSearchTerm })
        .subscribe(
          (response) => {
            this.therapiesSearchResults = response.data;
          },
          () => { }
        );
    } else {
      this.therapiesSearchResults = [];
    }
  }

  selectTherapie(therapy) {
    this.selectedTherapy = therapy;
  }

  save() {
    this.activeModal.close(
      {therapy: this.selectedTherapy, description: this.description}
    )
  }

  close() {
    this.activeModal.dismiss();
  }

}
