import { Injectable } from '@angular/core';
import { Doctor } from '../classes/doctor';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class DoctorService {

  constructor(
    private apiService: ApiService
  ) { }

  getDoctor(doctorId: number, query: any = {}) {
    return this.apiService.get(`doctor/${doctorId}`, query);
  }

  getDoctors(query: any = {}) {
    return this.apiService.get(`doctor`, query);
  }

  createDoctor(doctor: Doctor) {
    return this.apiService.post(`doctor`, doctor );
  }

  updateDoctor(doctorId: number, doctor: Doctor) {
    return this.apiService.put(`doctor/${doctorId}`, doctor );
  }

  deleteDoctor(doctorId: number) {
    return this.apiService.delete(`doctor/${doctorId}` );
  }

  getDoctorInvitations(doctorId: number, query: any = { }) {
    return this.apiService.get(`doctor/${doctorId}/invitation`, query);
  }

  getSpecialties(doctorId: number, query: any = {}) {
    return this.apiService.get(`doctor/${doctorId}/specialty`, query);
  }

  addSpecialty(doctorId: number, specialtyId: number) {
    return this.apiService.post(`doctor/${doctorId}/specialty`, { specialty_id: specialtyId });
  }

  removeSpecialty(doctorId: number, specialtyId: number) {
    return this.apiService.delete(`doctor/${doctorId}/specialty/${specialtyId}`);
  }

  uploadPhoto(doctorId: number, formData: FormData) {
    return this.apiService.post(`doctor/${doctorId}/photo`, formData );
  }

  removePhoto(doctorId: number) {
    return this.apiService.delete(`doctor/${doctorId}/photo` );
  }

}
