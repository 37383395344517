import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { PatientService } from "../../services/patient.service";
import { Router } from "@angular/router";

@Component({
  selector: 'doc-telemedicine-connect',
  templateUrl: './telemedicine-connect.component.html'
})
export class TelemedicineConnectComponent implements OnInit {

  @Input() appointmentSlot: any;
  showLoading: boolean = false;

  constructor(
    private activeModal: NgbActiveModal,
    private patientService: PatientService,
    private router: Router,
  ) { }

  ngOnInit() {}

  startVisit() {
    const params = {
      appointment_id: this.appointmentSlot.appointment_id,
      specialty_id: this.appointmentSlot.specialty_id,
      doctor_id: this.appointmentSlot.doctor_id,
    };
    this.patientService.createPatientVisit(this.appointmentSlot.appointment.patient_id, params)
      .subscribe(
        (newVisit) => {
          this.router.navigate(['/institution', 'patient', this.appointmentSlot.appointment.patient_id, 'visit', newVisit.data.id])
          this.activeModal.dismiss();
        },
        () => {}
      )
  }

  close() {
    this.activeModal.dismiss();
  }

}
