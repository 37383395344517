<div class="dc-image"
     [ngClass]="{'readyToDrop': readyToDrop, 'disabled': disabled, 'large': type === 'large'}"  (dragenter)="dragEnter($event)"
     (dragover)="dragEnter($event)"
     (dragleave)="dragLeave($event)"
     (drop)="onDrop($event)">
  <span class="dc-image__icon {{icon}}" *ngIf="icon" [ngClass]="{'dc-icon': icon}"></span>
  <div class="dc-image__label">{{label}}</div>
  <label>
    <input
      [type]="'file'"
      [accept]="'.png,.jpg,.jpeg'"
      [hidden]="true"
      [name]="name"
      [disabled]="disabled"
      [required]="required"
      [multiple]="multiple"
      (input)="emitChange($event)"
      #inputFile>
  </label>
  <label>
    <input type="text" [(ngModel)]="value" hidden>
  </label>
  <div class="dc-image__preview"
       *ngIf="value"
       [style.backgroundImage]="'url(' + value + ')'"></div>
  <div class="dc-image__actions">
    <span class="dc-icon ehr" (click)="choseFile($event)"></span>
    <span class="dc-icon delete" (click)="delete()"></span>
  </div>
</div>

<div class="dc-image__cropper-wrapper" [class.show]="showCropper">
  <div class="dc-image__cropper">
    <div class="dc-image__cropper__title">
      <h2>Isecanje slike</h2>
      <span class="dc-image__cropper__close" (click)="closeCropper()"></span>
    </div>
    <image-cropper
      [imageChangedEvent]="imageChangedEvent"
      [imageBase64]="imageBase64"
      [maintainAspectRatio]="true"
      [aspectRatio]="cropperAspectRation"
      format="png"
      (imageCropped)="imageCropped($event)"
      (imageLoaded)="imageLoaded($event)"
      [backgroundColor]="'#fff'"
      [resizeToHeight]="cropperHeight"
      [resizeToWidth]="cropperWidth"
    ></image-cropper>
    <div class="dc-image__cropper-actions">
      <dc-button color="outline cancel" (click)="closeCropper()">Odustani</dc-button>
      <dc-button color="primary" (click)="setCroppedImage()">Primeni</dc-button>
    </div>
  </div>
</div>

