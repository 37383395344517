<div class="dc-section dc-content mb-80" [perfectScrollbar]="{ suppressScrollX: true}">
  <div [dcLoading]="loadingVisit"></div>
  <div class="dc-container" *ngIf="!loadingVisit">
    <div class="dc-row middle">
      <div class="dc-col sm-6">
        <h1>
          <div class="super-heading" translate>Medical record</div>
          <span *ngIf="patient.id">{{ patient.first_name + ' ' + patient.last_name }}</span>
        </h1>
      </div>
      <div class="dc-col sm-6 flex padding-bottom" style="flex-direction: row; justify-content: flex-end;">
        <dc-button size="small" color="secondary" (click)="print()">{{ 'Print' | translate }}</dc-button>
        &nbsp;
        &nbsp;
        <dc-button *ngIf="visitId" color="outline" icon="delete" size="small" [loading]="deletingVisit" (click)="delete()">{{ 'Delete patient visit' | translate }}</dc-button>
      </div>
    </div>
    <form [formGroup]="patientVisitForm">
      <div class="dc-row space-16">
        <div class="dc-col md-8">
          <div class="dc-row">
            <div class="dc-col md-12">
              <h2 class="dc-text-color-primary" translate>Visit</h2>
            </div>
          </div>
          <div class="dc-row" *ngIf="patient.anamnesis_personal">
            <div class="dc-col md-12">
              <label translate>Personal anamnesis</label>
              <div [innerHtml]="patient.anamnesis_personal"></div>
            </div>
          </div>
          <div class="dc-row" *ngIf="patient.anamnesis_doctor">
            <div class="dc-col md-12">
              <label translate>Anamnesis</label>
              <div [innerHtml]="patient.anamnesis_doctor"></div>
            </div>
          </div>
          <div class="dc-row space-12 bottom">
            <div class="dc-col md-6">
              <dc-select
                [options]="institutionTemplates"
                [label]="'Templates' | translate"
                [placeholder]="'Select template' | translate"
                [(ngModel)]="selectedTemplateId"
                [ngModelOptions]="{ standalone: true }"
              ></dc-select>
            </div>
            <div class="dc-col md-6">
              <dc-button (click)="applyTemplate()" [disabled]="!selectedTemplateId">{{ 'Apply template' | translate }}</dc-button>
            </div>
          </div>
          <div class="dc-row end">
            <div class="dc-col md-12">
              <ng-container *ngIf="restrictedEdit">
                <dc-text-editor
                  [label]="'Findings and opinion' | translate"
                  type="restricted-edit"
                  formControlName="description"
                ></dc-text-editor>
              </ng-container>
              <ng-container *ngIf="!restrictedEdit">
                <dc-text-editor
                  [label]="'Findings and opinion' | translate"
                  [placeholder]="'Enter findings and opinion' | translate"
                  type="standard-edit"
                  formControlName="description"
                ></dc-text-editor>
              </ng-container>
            </div>
            <!-- <div class="dc-col md-12 end">
              <dc-switch
                [(ngModel)]="restrictedEdit"
                [ngModelOptions]="{ standalone: true }"
                [label]="'Restricted mode' | translate"
              ></dc-switch>
            </div> -->
          </div>
          <div class="dc-row">
            <div class="dc-col md-12">
              <label translate>Diagnosis</label>
              <table class="dc-table margin-bottom m-small">
                <tbody>
                <tr *ngFor="let diagnosis of patientVisitForm.get('diagnoses').value">
                  <td>
                    <div class="dc-table__value">
                      <div class="margin-bottom small">{{ diagnosis.disease.code }} {{ diagnosis.disease.name_serbian }}</div>
                      <div style="font-size: 13px; font-style: italic;">{{ diagnosis.disease.name_latin }}</div>
                    </div>
                  </td>
                  <td style="width: 50px;">
                        <span
                          class="dc-icon delete hover dc-clickable"
                          style="font-size: 24px; color:#757575;"
                          (click)="removePatientDiagnosis(diagnosis)"
                          title="{{ 'Remove' | translate }}"
                        ></span>
                  </td>
                </tr>
                </tbody>
              </table>
              <dc-button icon="plus" size="small" (click)="addPatientDiagnosis()">{{'Add diagnosis' | translate}}</dc-button>
            </div>
            <div class="dc-col md-12">
              <label translate>Therapies</label>
              <table class="dc-table margin-bottom m-small">
                <tbody>
                <tr *ngFor="let therapy of patientVisitForm.get('therapies').value">
                  <td>
                    <div class="dc-table__value">
                      <div class="margin-bottom small">{{ therapy.therapy.name }} {{ therapy.therapy.description }}</div>
                      <div class="margin-bottom small" style="font-size: 13px; font-style: italic;">{{ therapy.therapy.inn}}</div>
                      <div class="dc-text-color-primary">{{ therapy.description}}</div>
                    </div>
                  </td>
                  <td style="width: 50px;">
                        <span
                          class="dc-icon delete hover dc-clickable"
                          style="font-size: 24px; color:#757575;"
                          (click)="removePatientTherapy(therapy)"
                          title="{{ 'Remove' | translate }}"
                        ></span>
                  </td>
                </tr>
                </tbody>
              </table>
              <dc-button icon="plus" size="small" (click)="addPatientTherapies()">{{'Add therapies' | translate}}</dc-button>
            </div>
          </div>
        </div>
        <div class="dc-col md-4">
          <dc-collapse [collapsed]="visitId">
            <div class="dc-row">
              <div class="dc-col md-12" style="margin: 0;">
                <h2 class="dc-text-color-primary" translate>Information</h2>
              </div>
            </div>
            <div class="dc-row" dcCollapse>
              <div class="dc-col md-12">
                <dc-select
                  [showColor]="true"
                  [options]="specialties"
                  formControlName="specialty_id"
                  [label]="'Specialty' | translate"
                  [placeholder]="'Select specialty' | translate"
                ></dc-select>
              </div>
            </div>
            <div class="dc-row" dcCollapse>
              <div class="dc-col md-12">
                <dc-select
                  [showImage]="true"
                  [options]="doctors"
                  formControlName="doctor_id"
                  [label]="'Doctor' | translate"
                  [placeholder]="'Select doctor' | translate"
                ></dc-select>
              </div>
            </div>
            <div class="dc-row padding-bottom" dcCollapse>
              <div class="dc-col md-12">
                <dc-datepicker
                  formControlName="datetime"
                  [label]="'Date and time' | translate"
                ></dc-datepicker>
              </div>
            </div>
          </dc-collapse>
          <div class="dc-row space-12">
            <div class="dc-col md-12">
              <h2 class="dc-text-color-primary" translate>Attachments</h2>
            </div>
            <div class="dc-col md-12">
               <dc-file
                [icon]="'documents-add'"
                [label]="visitId ? ('Add attachment' | translate) : ('Create a visit so you can add file' | translate)"
                [uploaderConfig]="uploaderConfig"
                [uploaderModalContent]="uploadDocumentsModal"
                [modalConfig]="{patientId: this.patientId, visitId: this.visitId}"
                [disabled]="!visitId"
                (onComplete)="getPatientDocuments()"></dc-file> 

              <table class="dc-table marging-top">
                <tbody>
                  <tr *ngFor="let document of patientDocuments">
                    <td style="width: 80px;">
                      <span class="dc-icon {{ document.type === 'document' ? 'ehr' : document.type }}" style="font-size: 32px; color: #757575;"></span>
                    </td>
                    <td>
                      <div class="dc-table__value">
                        <div>{{ document.date | amDateFormat:'DD.MM.YYYY' }}</div>
                        <h4 class="dc-text-color-primary" style="margin: 8px 0;">{{ document.name }}</h4>
                        <div class="margin-vertical">{{ document.description }}</div>
                        <div style="display: flex;">
                          <dc-button size="small" icon="download" (click)="downloadPatientDocument(document)">{{ 'Download' | translate }}</dc-button>
                          &nbsp;&nbsp;
                          <dc-button size="small" icon="delete" color="outline" (click)="deletePatientDocument(document)">{{ 'Delete' | translate }}</dc-button>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="dc-bottom-bar">
  <div class="dc-container flex" style="height: 100%; align-items: center;">
    <dc-button size="small" color="secondary" (click)="close()">{{ 'Close' | translate }}</dc-button>
    &nbsp;&nbsp;
    <dc-button size="small" (click)="save(false)" [loading]="savingVisit" [disabled]="patientVisitForm.invalid">{{ 'Save' | translate }}</dc-button>
    &nbsp;&nbsp;
    <dc-button size="small" (click)="save(true)" [loading]="savingVisit" [disabled]="patientVisitForm.invalid">{{ 'Save & Close' | translate }}</dc-button>
  </div>
</div>

<dc-call *ngIf="patientVisit.room_id" [roomId]="patientVisit.room_id" [userName]="currentUser.first_name + ' ' + currentUser.last_name" [userImage]="currentUser.photo_url"></dc-call>
