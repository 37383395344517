<div class="dc-subheader" [class.show-nav]="showSubheader">
  <div class="dc-container">
    <div class="dc-row middle">
      <div class="dc-col xs-12">
        <ul class="navigation">
          <li>
            <a [routerLink]="['/institution', 'details']" translate>Settings</a>
          </li>
          <li>
            <a [routerLink]="['/institution', 'template', 'list']" translate>Templates</a>
          </li>
          <li>
            <a [routerLink]="['/institution', 'service', 'list']" translate>Services</a>
          </li>
          <li>
            <a [routerLink]="['/institution', 'promotion', 'list']" translate>Promotions</a>
          </li>
          <li>
            <a [routerLink]="['/institution', 'doctor', 'list']" translate>Doctors</a>
          </li>
          <li>
            <a [routerLink]="['/institution', 'user', 'list']" translate>Users</a>
          </li>
        </ul>
        <div class="subheader-responsive">
        <span translate>Information</span>
        <span class="nav-toggle" (click)="toggleSubheader()">...</span>
        <span class="dc-icon remove-close" (click)="toggleSubheader()"></span>
      </div>
      </div>
    </div>
  </div>
</div>
<section class="dc-section dc-content mb-80" [perfectScrollbar]="{ suppressScrollX: true}">
    <div [dcLoading]="loadingDoctor"></div>
    <div class="dc-container" *ngIf="!loadingDoctor">
        <div class="dc-row middle">
          <div class="dc-col lg-6">
            <h1>
                <div class="super-heading" translate>Doctor</div>
                <span *ngIf="doctor.id">{{ (doctor.title ? doctor.title + ' ' : '') + doctor.first_name + ' ' + doctor.last_name }}</span>
                <span *ngIf="!doctor.id">{{ 'New doctor' | translate }}</span>
            </h1>
          </div>
          <div class="dc-col lg-6 flex padding-bottom" style="flex-direction: row; justify-content: flex-end;">
            <dc-button *ngIf="doctorId" color="outline" icon="delete" size="small" [loading]="deletingDoctor" (click)="delete()">{{ 'Delete doctor' | translate }}</dc-button>
          </div>
        </div>
        <form [formGroup]="doctorForm">
            <div class="dc-row space-16">
                <div class="dc-col md-8">
                    <div class="dc-row space-12">
                        <div class="dc-col md-12">
                            <h2 class="dc-text-color-primary" translate>Information</h2>
                        </div>
                    </div>
                    <div class="dc-row space-12">
                        <div class="dc-col md-4">
                            <dc-input
                                label="{{ 'Title' | translate }}"
                                formControlName="title"
                            ></dc-input>
                        </div>
                        <div class="dc-col md-4">
                            <dc-input
                                label="{{ 'First name' | translate }}"
                                formControlName="first_name"
                            ></dc-input>
                        </div>
                        <div class="dc-col md-4">
                            <dc-input
                                label="{{ 'Last name' | translate }}"
                                formControlName="last_name"
                            ></dc-input>
                        </div>
                    </div>
                    <div class="dc-row space-12">
                        <div class="dc-col md-12">
                            <dc-select
                                label="{{ 'Specialties' | translate }}"
                                formControlName="specialty_ids"
                                placeholder="{{ 'Select specialties' | translate }}"
                                [multi]="true"
                                [options]="specialties"
                                [showSelectAll]="true"
                            ></dc-select>
                        </div>
                    </div>
                    <div class="dc-row space-12">
                        <div class="dc-col md-12">
                            <dc-text-editor
                                label="{{ 'Biography' | translate }}"
                                formControlName="biography"
                            ></dc-text-editor>
                        </div>
                    </div>
                    <div class="dc-row space-12">
                        <div class="dc-col md-12">
                            <h2 class="dc-text-color-primary" translate>Contact</h2>
                        </div>
                    </div>
                    <div class="dc-row space-12">
                        <div class="dc-col md-4">
                            <dc-input
                                label="{{ 'Email' | translate }}"
                                formControlName="email"
                            ></dc-input>
                        </div>
                        <div class="dc-col md-4">
                            <dc-input
                                label="{{ 'Phone 1' | translate }}"
                                formControlName="phone_1"
                            ></dc-input>
                        </div>
                        <div class="dc-col md-4">
                            <dc-input
                                label="{{ 'Phone 2' | translate }}"
                                formControlName="phone_2"
                            ></dc-input>
                        </div>
                    </div>
                    <div class="dc-row space-12" *ngIf="!doctorId">
                        <div class="dc-col md-12">
                            <dc-checkbox
                                [label]="'Send invitation to Doktorijum' | translate"
                                [(ngModel)]="sendInvitationAutomatically"
                                [ngModelOptions]="{ standalone: true }"
                            ></dc-checkbox>
                        </div>
                    </div>
                </div>
                <div class="dc-col md-4">
                    <div class="dc-row">
                        <div class="dc-col md-12">
                            <h2 class="dc-text-color-primary" translate>Photo</h2>
                        </div>
                        <div class="dc-col md-12">
                            <dc-image
                                icon="profile"
                                formControlName="photo_url"
                                [label]="'Photo' | translate"
                            ></dc-image>
                        </div>
                        <div class="dc-col md-12" *ngIf="doctorId">
                            <h2 class="dc-text-color-primary" translate>Doktorijum user</h2>
                        </div>
                        <div class="dc-col md-12" *ngIf="doctorId">
                            <div *ngIf="doctor.user">
                                <div translate>Doctor is registered user of Doktorijum.</div>
                                <div class="margin-top">
                                    <label translate>User email</label>
                                    <div><span class="dot green"></span>&nbsp;&nbsp;{{ doctor.user.email }}</div>
                                </div>
                            </div>
                            <div *ngIf="!doctor.user">
                                <div *ngIf="invitations.length === 0">
                                    <div class="margin-bottom" translate>Doctor is not Doktorijum user.</div>
                                    <dc-button size="full" (click)="inviteDoctor()">{{ 'Invite doctor to register' | translate }}</dc-button>
                                </div>
                                <div *ngIf="invitations.length > 0">
                                    <div translate>Invitation to register sent.</div>
                                    <div>
                                        <div class="invitation" *ngFor="let invitation of invitations">
                                            <div class="dot yellow"></div>
                                            <div style="flex: 1">
                                                <div>
                                                    <span translate>Sent</span>: {{ invitation.created_at | amDateFormat:'DD.MM.YYYY.' }} | <span translate>Expires</span>: {{ invitation.expires_at | amDateFormat:'DD.MM.YYYY. HH:mm' }}
                                                </div>
                                                <div>{{ invitation.email }}</div>
                                            </div>
                                            <div>
                                                <span class="dc-icon copy margin-right m-small dc-clickable" *ngIf="invitationLink" (click)="copyToClipboard()"></span>
                                                <span class="dc-icon delete dc-clickable" (click)="revokeInvitation(invitation)"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <dc-button size="full" (click)="inviteDoctor()">{{ 'Send new invitation to register' | translate }}</dc-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
  <label style="height: 0">
    <input style="height: 0; padding: 0; border: none" type="text" [value]="invitationLink" #invitationLinkInput>
  </label>
</section>
<div class="dc-bottom-bar">
    <div class="dc-container flex" style="height: 100%; align-items: center;">
        <dc-button size="small" color="secondary" (click)="close()">{{ 'Close' | translate }}</dc-button>
        &nbsp;&nbsp;
        <dc-button size="small" (click)="save(false)" [loading]="savingDoctor" [disabled]="doctorForm.invalid">{{ 'Save' | translate }}</dc-button>
        &nbsp;&nbsp;
        <dc-button size="small" (click)="save(true)" [loading]="savingDoctor" [disabled]="doctorForm.invalid">{{ 'Save & Close' | translate }}</dc-button>
    </div>
</div>
