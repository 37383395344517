<div class="dc-subheader">
    <div class="dc-container">
        <div class="dc-row middle">
            <div class="dc-col xs-6">
                <h1 translate>Patients</h1>
            </div>
            <div class="dc-col xs-6 end">
                <dc-button size="small" color="white" icon="plus" [routerLink]="[ '..', 'new' ]">{{ 'Add' | translate }}</dc-button>
            </div>
        </div>
    </div>
</div>
<div class="dc-section">
    <div class="dc-container">
      <div class="dc-row middle">
        <div class="dc-col md-6 lg-4" style="margin: 4px 0 8px;">
          <dc-input
            [(ngModel)]="searchTerm"
            (ngModelChange)="getPatients()"
            [placeholder]="'Search' | translate"
          ></dc-input>
        </div>
        <!-- <div class="dc-col md-2">
          <dc-button color="secondary">{{ 'Filter' | translate }}</dc-button>
        </div> -->
      </div>
    </div>
</div>
<div class="dc-section dc-content" [perfectScrollbar]="{ suppressScrollX: true}" dcInfiniteScroll (scrolled)="getPatients(true)">
    <div [dcLoading]="loadingPatients"></div>
    <div class="dc-container">
        <table class="dc-table break-phone">
            <tr class="dc-clickable" *ngFor="let patient of patients" [routerLink]="[ '..', patient.id, 'details' ]">
                <td style="width: 90px;">
                <label class="dc-table__label" translate>ID</label>
                <div class="dc-table__value">
                  <span *ngIf="patient.id">{{ patient.id }}</span>
                </div>
              </td>
                <td style="width: 180px;">
                    <label class="dc-table__label" translate>Patient number</label>
                    <div class="dc-table__value">
                        <span *ngIf="patient.patient_number">{{ patient.patient_number }}</span>
                        <span *ngIf="!patient.patient_number">-</span>
                    </div>
                </td>
                <td>
                    <label class="dc-table__label" translate>Patient name</label>
                    <div class="dc-table__value">
                        <span>{{ patient.first_name }} {{ patient.last_name }} </span>
                        <span *ngIf="patient.parent_name">({{ patient.parent_name }})</span>
                    </div>
                </td>
                <td style="width: 180px;">
                    <label class="dc-table__label" translate>Last visit</label>
                    <div class="dc-table__value">
                        <span *ngIf="patient.last_visit">{{ patient.last_visit | amDateFormat:'DD.MM.YYYY.' }}</span>
                        <span *ngIf="!patient.last_visit">-</span>
                    </div>
                </td>
            </tr>
        </table>
    </div>
</div>
