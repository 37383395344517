import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { UserService } from './user.service';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private apiService: ApiService,
    private userService: UserService
  ) { }

  login(email: string, password: string, token: string = null) {
    const query: any = {
      email: email,
      password: password
    };
    if (token) {
      query.token = token;
    }

    return this.apiService.post(`login`, query)
      .pipe(
        map((response: any) => {
          if (response && response.access_token) {
            localStorage.setItem('jwt', response.access_token);
            this.userService.getCurrentUser();
          }

          return response;
        })
      );
  }

  register(email: string, password: string, firstName: string, lastName: string, token: string = null) {
    const query: any = {
      email: email,
      password: password,
      first_name: firstName,
      last_name: lastName
    };
    if (token) {
      query.token = token;
    }

    return this.apiService.post(`registration`, query)
      .pipe(
        map((response: any) => {
          if (response && response.access_token) {
            localStorage.setItem('jwt', response.access_token);
          }

          return response;
        })
      );
  }

  getInvitation(token: string, query: any = {}) {
    return this.apiService.get(`invitation/${ token }`, query);
  }

  logout() {
    if (localStorage.getItem('jwt')) {
      return this.apiService.post(`logout`)
        .pipe(
          map((response: any) => {
            localStorage.removeItem('jwt');
            localStorage.removeItem('currentInstitutionKey');
          })
        );
    }
  }
}
