import { Component, Input, OnInit } from '@angular/core';
import { FileUploader } from "ng2-file-upload";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PatientService } from "../../services/patient.service";
import { DCToastService } from "../../../../../../libs/ui/src/lib/components/toast/toast.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'doc-patient-document-upload',
  templateUrl: './patient-document-upload.component.html'
})
export class PatientDocumentUploadComponent implements OnInit {

  @Input() uploader: FileUploader;
  @Input() modalConfig: any;

  documentInfo: FormGroup;

  configScroll: PerfectScrollbarConfigInterface = { };
  showProgressBar: boolean;
  percent: number = 1;
  currentStep = 1;
  files: any[];
  typeOptions = [
    // { id: 'image', name: 'Image' },
    // { id: 'video', name: 'Video' },
    { id: 'document', name: 'Document' }
  ];

  fileSize: number;

  showLoading: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private patientService: PatientService,
    private toastService: DCToastService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.files = this.uploader.queue;
    this.documentInfo = this.formBuilder.group({
      type: ['document', Validators.required],
      file: null,
      date: [new Date().toJSON().slice(0,10), Validators.required],
      name: ['', Validators.required],
      description: ''
    })
    if (this.files[0].file.size) {
      this.fileSize = this.files[0].file.size;
    }
    if (this.files[0].file.name) {
      this.documentInfo.get('name').setValue(this.files[0].file.name);
    }
  }

  close() {
    this.uploader.clearQueue();
    this.activeModal.close();
  }

  nextStep() {
    this.currentStep ++;
  }

  previousStep() {
    this.currentStep --;
  }

  upload() {
    this.showLoading = true;
    const formData = new FormData;

    formData.append('patient_id', this.modalConfig.patientId.toString());
    formData.append('patient_visit_id', this.modalConfig.visitId.toString());

    if (this.documentInfo.value.type) {
      formData.append('type', this.documentInfo.value.type)
    }
    if (this.documentInfo.value.type) {
      formData.append('file', this.files[0].file.rawFile)
    }
    if (this.documentInfo.value.type) {
      formData.append('date', this.documentInfo.value.date)
    }
    if (this.documentInfo.value.type) {
      formData.append('name', this.documentInfo.value.name)
    }
    if (this.documentInfo.value.type) {
      formData.append('description', this.documentInfo.value.description)
    }

    this.patientService.createPatientDocument(this.modalConfig.patientId, formData)
      .subscribe(
        () => {
            this.activeModal.close();
            this.showLoading = false;
            this.uploader.clearQueue();
            this.toastService.show({
              type: 'success',
              message: this.translateService.instant('File successfully added'),
            })
        },
        () => {
          this.showLoading = false;
        }
      )
  }

}
