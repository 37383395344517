import { Component, OnInit, ViewChild } from '@angular/core';
import { Doctor } from '../../classes/doctor';
import { DoctorService } from '../../services/doctor.service';
import { PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { UserService } from '../../services/user.service';
import { User } from '../../classes/user';

@Component({
  selector: 'main [dc-doctor-list]',
  templateUrl: './doctor-list.component.html'
})
export class DoctorListComponent implements OnInit {
  @ViewChild(PerfectScrollbarDirective, { static: false }) perfectScrollbarDirectiveRef?: PerfectScrollbarDirective;
  user: User;
  doctors: Doctor[] = [];
  searchTerm: string = '';
  sort: any = {
    field: 'id',
    direction: 'asc'
  };
  pagination: any = {
    total: 0,
    current_page: 1,
    per_page: 15
  };

  sortOptions = [
    {
      id: 'first_name/asc',
      name: 'Imenu rastuće'
    },
    {
      id: 'first_name/desc',
      name: 'Imenu opadajuće'
    }
  ];

  showSubheader: boolean = false;

  loadingDoctors: boolean = false;

  constructor(
    private doctorService: DoctorService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.getDoctors();
    this.getUser();
  }

  toggleSubheader() {
    this.showSubheader = !this.showSubheader;
  }

  sortDoctors(sort: string) {
    this.sort.field = sort.split('/')[0];
    this.sort.direction = sort.split('/')[1];
    this.getDoctors();
  }

  getDoctors(append: boolean = false) {
    if (append && this.pagination.total && this.doctors.length >= this.pagination.total) { return; }

    this.pagination.current_page = append ? this.pagination.current_page + 1 : 1;

    const params: any = {
      current_page: this.pagination.current_page,
      per_page: this.pagination.per_page,
      sort: this.sort.field,
      direction: this.sort.direction
    };
    if (this.searchTerm.length > 0) {
      params.search_term = this.searchTerm;
    }
    this.loadingDoctors = !append;
    this.doctorService
      .getDoctors(params)
      .subscribe(
        (response: { data: Doctor[], total: number }) => {
          if (append) {
            this.doctors = this.doctors.concat(response.data);
          } else {
            this.perfectScrollbarDirectiveRef.scrollToTop();
            this.doctors = response.data;
            this.pagination.total = response.total;
          }
          this.loadingDoctors = false;
        },
        (error) => {
          this.loadingDoctors = false;
        }
      );
  }

  getUser() {
    this.userService.currentUser
      .subscribe(
        (response) => {
          this.user = response;
        },
        () => {},
      );
  }

}
