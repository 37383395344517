import { Component, OnInit, ViewChild } from '@angular/core';
import { PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { PromotionService } from '../../services/promotion.service';
import { Promotion } from '../../classes/promotion';
import { UserService } from "../../services/user.service";
import { User } from "../../classes/user";

@Component({
  selector: 'main[dc-promotion-list]',
  templateUrl: './promotion-list.component.html'
})
export class PromotionListComponent implements OnInit {
  @ViewChild(PerfectScrollbarDirective, { static: false }) perfectScrollbarDirectiveRef?: PerfectScrollbarDirective;
  promotions: Promotion[] = [];
  user: User;
  searchTerm: string = '';
  sort: any = {
    field: 'id',
    direction: 'asc'
  };
  pagination: any = {
    total: 0,
    current_page: 1,
    per_page: 15
  };

  showSubheader: boolean = false;

  loadingPromotions: boolean = false;

  constructor(
    private promotionService: PromotionService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.getPromotions();
    this.getUser();
  }

  toggleSubheader() {
    this.showSubheader = !this.showSubheader;
  }

  sortPromotions(field: string) {
    if (field === this.sort.field) {
      this.sort.direction = this.sort.direction === 'asc' ? 'desc' : 'asc';
    } else {
      this.sort.field = field;
      this.sort.direction = 'asc';
    }
    this.getPromotions();
  }

  getPromotions(append: boolean = false) {
    if (append && this.pagination.total && this.promotions.length >= this.pagination.total) { return; }

    this.pagination.current_page = append ? this.pagination.current_page + 1 : 1;

    const params: any = {
      current_page: this.pagination.current_page,
      per_page: this.pagination.per_page,
      // sort: this.sort.field,
      // direction: this.sort.direction
    };
    if (this.searchTerm.length > 0) {
      params.search_term = this.searchTerm;
    }
    this.loadingPromotions = !append;
    this.promotionService
      .getPromotions(params)
      .subscribe(
        (response: { data: Promotion[], total: number }) => {
          if(append) {
            this.promotions = this.promotions.concat(response.data);
          } else {
            this.perfectScrollbarDirectiveRef.scrollToTop();
            this.promotions = response.data;
            this.pagination.total = response.total;
          }
          this.loadingPromotions = false;
        },
        (error) => {
          this.loadingPromotions = false;
        }
      );
  }

  getUser() {
    this.userService.currentUser
      .subscribe(
        (response) => {
          this.user = response;
        },
        () => {},
      );
  }
}
