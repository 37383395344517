<div class="dc-modal small">
    <div class="dc-modal__header">
        <h2 class="dc-modal__title">{{ 'Edit service' | translate }}</h2>
        <span class="dc-modal__close" (click)="close()"></span>
    </div>
    <div class="dc-modal__body paddTop" perfectScrollbar>
        <form [formGroup]="institutionServiceForm">
            <div [dcLoading]="loadingService"></div>
            <div class="dc-row space-16">
                <div class="dc-col md-12">
                  <dc-input
                      label="{{ 'Service name' | translate }}"
                      formControlName="name"
                  ></dc-input>
                </div>
            </div>
            <div class="dc-row space-16">
                <div class="dc-col md-12">
                  <dc-input
                      type="number"
                      label="{{ 'Price' | translate }}"
                      formControlName="price"
                  ></dc-input>
                </div>
            </div>
            <div class="dc-row space-16">
                <div class="dc-col md-12">
                    <dc-select
                        label="{{ 'Specialty' | translate }}"
                        formControlName="specialty_id"
                        [options]="specialties"
                    ></dc-select>
                </div>
            </div>
        </form>
    </div>
    <div class="dc-modal__actions">
        <div class="dc-modal__action-left">
            <dc-button color="outline" (click)="close()">{{ 'Cancel' | translate }}</dc-button>
            <dc-button color="secondary" (click)="delete()">{{ 'Delete' | translate }}</dc-button>
        </div>
        <div class="dc-modal__action-right">
            <dc-button [loading]="savingInstitutionService" (click)="save()">{{ 'Save' | translate }}</dc-button>
        </div>
    </div>
</div>
