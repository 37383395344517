<div class="public">
    <img class="logo" src="assets/images/logo-white.svg" alt="Doktorijum">

    <div class="form">
        <form [formGroup]="changePasswordForm">
            <h2 translate>Change password</h2>
            <div class="dc-row">
                <div class="dc-col">
                    <dc-input
                        type="password"
                        formControlName="password"
                        [label]="'New password' | translate"
                    ></dc-input>             
                </div>
            </div>
            <div class="dc-row">
                <div class="dc-col">
                    <dc-input
                        type="password"
                        formControlName="confirm"
                        [label]="'Confirm new password' | translate"
                    ></dc-input>             
                </div>
            </div>
            <div class="dc-row">
                <div class="dc-col">
                    <div class="text-center">
                        <small translate>Password should be at least 8 characters long and contain at least one digit.</small>
                    </div>
                </div>
            </div>
            <div class="dc-row">
                <div class="dc-col sm-12">
                    <dc-button size="full" (click)="changePassword()" [loading]="changingPassword" [disabled]="changePasswordForm.invalid">{{ 'Change password' | translate }}</dc-button>
                </div>
            </div>
        </form>
    </div>
</div>
