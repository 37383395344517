import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OnInit, OnDestroy, Component, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppointmentSlot } from '../../classes/appointment-slot';
import { Patient } from '../../classes/patient';
import { PatientService } from '../../services/patient.service';
import { AppointmentService } from '../../services/appointment.service';
import { ModalService } from 'libs/ui/src/public-api';
import { PatientCreateModalComponent } from '../patient-create/patient-create-modal.component';
import { InstitutionService } from '../../services/institution.service';

@Component({
  selector: 'app-appointment-create-modal',
  templateUrl: './appointment-create-modal.component.html',
})
export class AppointmentCreateModalComponent implements OnInit, OnDestroy {
  @Input() appointmentSlot: AppointmentSlot;
  patientId: number;
  patients: Patient[] = [];
  selectedPatient: Patient;
  comment: string = '';
  institutionServiceId: number;
  institutionServices: any[] = [];
  currentStep: number = 1;

  loadingServices: boolean = false;

  subscriptions: Subscription[] = [];

  constructor(
    private patientService: PatientService,
    private appointmentService: AppointmentService,
    private institutionService: InstitutionService,
    private modalService: ModalService,
    private activeModal: NgbActiveModal
  ) {}

  ngOnInit() {
    this.getPatients();
    this.getInstitutionServices();
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  getSelectedPatient() {
    this.selectedPatient = this.patients.find(patient => patient.id === this.patientId);
  }

  getPatients(search_term?: string) {
    const params: any = {
      sort: 'created_at',
      direction: 'desc'
    };
    if (search_term) {
      params.search_term = search_term;
    }
    this.patientService
        .getPatients(params)
        .subscribe(
            (response) => {
                this.patients = response.data;
            },
            () => {}
        );
  }

  getInstitutionServices(search_term?: string) {
    const params: any = {
      specialty_id: this.appointmentSlot.specialty_id
    };
    if (search_term) {
      params.search_term = search_term;
    }
    this.loadingServices = true;
    this.institutionService
      .getInstitutionServices(params)
      .subscribe(
        (response: any) => {
          this.institutionServices = response.data;
          this.loadingServices = false;
        },
        () => {
          this.loadingServices = false;
        }
      );
  }

  addPatient(name?: string) {
    const modalRef = this.modalService.open(PatientCreateModalComponent);
    modalRef.componentInstance.name = name;
    modalRef.result.then(
      (patient) => {
        this.patients = this.patients.concat([patient]);
        this.patientId = patient.id;
        this.selectedPatient = patient;
      },
      () => { }
    );
  }

  save() {
    const params: any = {
        appointment_slot_id: this.appointmentSlot.id,
        patient_id: this.patientId,
        comment: this.comment,
        institution_service_id: this.institutionServiceId
    };
    this.appointmentService
        .createAppointment(params)
        .subscribe(
            () => {
              this.activeModal.close();
            },
            (error) => {
              this.modalService.error({ errors: error });
            }
        );
  }

  back() {
    this.currentStep--;
  }

  next() {
    this.currentStep++;
  }

  close() {
    this.activeModal.dismiss();
  }
}
