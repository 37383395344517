import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';
import { Institution } from '../../classes/institution';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit, OnDestroy {
  loginForm: FormGroup;
  email: string = '';
  password: string = '';
  error: string = null;
  showRegistrationInstructions: boolean = false;

  loggingIn: boolean = false;

  subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private userService: UserService,
  ) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ['', [ Validators.required, Validators.email ]],
      password: ['', [ Validators.required ]]
    });

    const formSubscription = this.loginForm.valueChanges
      .subscribe(() => {
        this.error = null;
      });
    this.subscriptions.push(formSubscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  login() {
    if (this.loginForm.invalid) { return; }

    this.loggingIn = true;
    this.authService
      .login(this.loginForm.value.email, this.loginForm.value.password)
      .subscribe(
        () => {
          this.userService
            .getCurrentUserInstitutions()
            .subscribe(
              (response: { data: Institution[], meta: any }) => {
                const institutions: Institution[] = response.data;

                if (institutions.length > 1) {
                  this.router.navigate(['/institution-select']);
                } else if (institutions.length === 1) {
                  localStorage.setItem('currentInstitutionKey', institutions[0].institution_guid);
                  this.router.navigate(['/institution/schedule']);
                } else if (institutions.length === 0) {
                  this.router.navigate(['/profile']);
                }
                this.loggingIn = false;
              },
              () => {
                this.loggingIn = false;
              }
            );
        },
        (error) => {
          this.error = error.error.message;
          this.loggingIn = false;
        }
      );
  }

  clearError() {
    this.error = null;
  }

}
