import { Component, OnInit } from '@angular/core';
import { User } from '../../classes/user';
import { Institution } from '../../classes/institution';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { InstitutionService } from '../../services/institution.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-institution',
  templateUrl: './institution.component.html'
})
export class InstitutionComponent implements OnInit {
  user: User = null;
  institution: Institution = null;
  assetsRoot: string = environment.assetsRoot;

  showNav: boolean = false;
  showProfileNav: boolean = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    userService: UserService,
    institutionService: InstitutionService
  ) {
    userService.currentUser
      .subscribe(user => this.user = user );
    institutionService.currentInstitution
      .subscribe(institution => this.institution = institution );

    if (localStorage.getItem('jwt')) {
      userService.getCurrentUser();
    }
    institutionService.getCurrentInstitution();
  }

  logout() {
    this.authService.logout()
      .subscribe(
        () => {
          this.router.navigate(['./login']);
        }
      );
  }

  ngOnInit() {
  }

  toggleNav() {
    if (this.showProfileNav) { this.showProfileNav = false; }
    this.showNav = !this.showNav;
  }

  toggleProfileNav() {
    if (this.showNav) { this.showNav = false; }
    this.showProfileNav = !this.showProfileNav;
  }

  goToInstitutionSelect() {
    if (this.user.institutions.length > 1) {
      this.router.navigate(['/institution-select']);
    }
  }

}
