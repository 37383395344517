import {Directive, ElementRef, OnDestroy, Output, EventEmitter, Input} from '@angular/core';
import { Subscription, Observable, fromEvent} from 'rxjs';

@Directive({selector: '[dcOffClick]'})

export class DCOffClickDirective implements OnDestroy {
  @Input() disabled: boolean = false;
  @Output() offClick: EventEmitter<Event> = new EventEmitter<Event>();

  subscription: Subscription;
  documentBodyElement: HTMLElement;
  baseElement: HTMLElement;

  constructor(private el: ElementRef) {
    this.baseElement = this.el.nativeElement;
    this.documentBodyElement = document.body;
    if (Observable) {
      this.subscription = fromEvent(document, 'click')
        .subscribe(
          (event: Event) => this.clickHandler(event.target as Element, event)
        );
    }
  }

  private clickHandler(currentElement: HTMLElement | Element, event: any): void {
    if(!this.disabled) {
      if (
        event.target.className?.includes && (
        event.target.className?.includes('ps__thumb-y') ||
        event.target.className?.includes('ps__rail-y') ||
        event.target.className?.includes('ps__thumb-x') ||
        event.target.className?.includes('ps__rail-x')
        )) {
        return;
      }
      if (currentElement === this.documentBodyElement || currentElement == null) {
        this.offClick.emit(event);
        return;
      }
      if (currentElement === this.baseElement) {
        return;
      }
      if(currentElement.parentElement) {
        this.clickHandler(currentElement.parentElement, event);
      }
    }
  }


  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
