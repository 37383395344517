import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class SpecialtyService {

  constructor(
    private apiService: ApiService
  ) { }

  /**
   * Gets medical specialties.
   * @param query Parameters to filter specialties
   * @returns Observable
   */
  getSpecialties(query: any = {}) {
    return this.apiService.get(`specialty`, query);
  }

  /**
   * Gets medical specialties.
   * @param query Parameters to filter specialties
   * @returns Observable
   */
  getInstitutionSpecialties(query: any = {}) {
    return this.apiService.get(`institution/current/specialty`, query);
  }
}
