import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DCMapComponent } from './map.component';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { FormsModule } from "@angular/forms";



@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    DCMapComponent
  ],
  imports: [
    CommonModule,
    NgxMapboxGLModule.withConfig({
      accessToken: 'pk.eyJ1IjoiZmlsaXBsdWtpYyIsImEiOiJjamtsY3Q5bnUwMnJtM3FwZHFwYWc2NWc4In0.O4dnWS-GD631pfqt-dR-TA'
    }),
    FormsModule,
  ],
  exports: [
    DCMapComponent
  ]
})
export class DCMapModule { }
