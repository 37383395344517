import { Component, EventEmitter, HostListener, Output } from '@angular/core';

@Component({
  selector: '[dcInfiniteScroll]',
  template: '<ng-content></ng-content>'
})

export class DCInfiniteScrollComponent {
  @Output() scrolled = new EventEmitter();

  @HostListener('scroll', ['$event']) public onScroll(event) {
    const offsetHeight = event.target.offsetHeight;
    const scrollTop = event.target.scrollTop;
    const scrollHeight = event.target.scrollHeight;

    if (offsetHeight + scrollTop >= scrollHeight) {
      this.scrolled.emit();
    }
  }

}
