import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConfig } from '../../app.config';
import { UserService } from '../../services/user.service';
import { ModalService } from 'libs/ui/src/public-api';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html'
})
export class ChangePasswordComponent implements OnInit {
  changePasswordForm: FormGroup;

  changingPassword: boolean = false;

  constructor(
    private modalService: ModalService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.changePasswordForm = this.formBuilder.group({
      password: ['', [Validators.required]],
      confirm: ['', [Validators.required]],
      token: ['', [Validators.required]],
    });
    this.changePasswordForm.get('token')
      .setValue(this.route.snapshot.paramMap.get('token'));
  }

  changePassword() {
    const password = this.changePasswordForm.get('password').value;
    const confirm = this.changePasswordForm.get('confirm').value;

    if (password !== confirm) {
      this.modalService.alert({
        title: 'Error',
        body: 'Passwords do not match.',
        size: 'small',
        type: 'danger',
        button: {
          text: 'Okay',
          role: 'cancel'
        }
      });
      return;
    }

    if (!AppConfig.PASSWORD_REGEXP.test(password)) {
      this.modalService.alert({
        title: 'Error',
        body: 'Password should be at least 8 characters long and contain at least one digit.',
        size: 'small',
        type: 'danger',
        button: {
          text: 'Okay',
          role: 'cancel'
        }
      });
      return;
    }

    this.changingPassword = true;
    this.userService
      .changePassword(this.changePasswordForm.value)
      .subscribe(
        () => {
          this.modalService.alert({
            title: 'Success',
            body: 'Password changed.',
            size: 'small',
            type: 'default',
            button: {
              text: 'Okay',
              handler: () => {
                this.router.navigate(['/login']);
              }
            }
          });
          this.changingPassword = false;
        },
        () => {
          this.modalService.alert({
            title: 'Error',
            body: 'Password not changed.',
            size: 'small',
            type: 'danger',
            button: {
              text: 'Okay',
              role: 'cancel'
            }
          });
          this.changingPassword = false;
        }
      );
  }

}
