import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { PerfectScrollbarDirective } from "ngx-perfect-scrollbar";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { DCToastService } from "@dc/ui/components/toast/toast.service";
import { TranslateService } from "@ngx-translate/core";
import { ModalService } from "@dc/ui/components/modal/modal.service";
import { InstitutionService } from "../../services/institution.service";

@Component({
  selector: 'main[dc-user-details]',
  templateUrl: './user-details.component.html'
})
export class UserDetailsComponent implements OnInit, OnDestroy {
  @ViewChild(PerfectScrollbarDirective, { static: false }) perfectScrollbarDirectiveRef?: PerfectScrollbarDirective;
  user: any;
  userForm: FormGroup;
  userId: number;

  roles: any;
  selectedRoles: number[] = [];

  loadingUser: boolean = false;
  savingUser: boolean = false;
  deletingUser: boolean = false;

  showSubheader: boolean = false;

  subscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private institutionService: InstitutionService,
    private toasterService: DCToastService,
    private translateService: TranslateService,
    private modalService: ModalService
  ) { }

  ngOnInit() {
    this.initForm();
    this.getRoles();
    const routeSubscription = this.route.params.subscribe(
      (data) => {
        if (data.userId) {
          this.userId = data.userId;
          this.getInstitutionUser();
        }
      }
    );
    this.subscriptions.push(routeSubscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  toggleSubheader() {
    this.showSubheader = !this.showSubheader;
  }

  initForm() {
    this.userForm = this.formBuilder.group({
      first_name: [null, Validators.required],
      last_name: [null, Validators.required],
      email: [null, Validators.required],
      roles: [null]
    });
  }

  getInstitutionUser() {
    this.loadingUser = true;
    const params = {
      include: 'roles'
    }
    this.institutionService.getInstitutionUser(this.userId, params)
      .subscribe(
        (response) => {
          this.userForm.patchValue(response.data);
          this.user = response.data;
          this.selectedRoles = [];
          response.data.roles.map((role) => {
            this.selectedRoles.push(role.id);
          })
          this.userForm.get('roles').setValue(this.selectedRoles);
          this.loadingUser = false;
        },
        () => {
          this.toasterService.show({
            message: this.translateService.instant('Failed to load user.'),
            type: 'error'
          });
          this.loadingUser = false;
          this.close();
        }
      );
  }

  getRoles() {
    this.loadingUser = true;
    this.institutionService.getInstitutionRoles()
      .subscribe(
        (response) => {
          this.roles = response.data;
          this.loadingUser = false;
        },
        () => {
          this.loadingUser = false;
        }
      )
  }

  selectRoles(roleId) {
    if (this.selectedRoles.includes(roleId)) {
      this.selectedRoles.splice(this.selectedRoles.indexOf(roleId), 1);
    } else {
      this.selectedRoles.push(roleId)
    }
    this.userForm.get('roles').setValue(this.selectedRoles);
  }

  save(close: boolean = false) {
    if (this.userForm.invalid) { return; }

    if (this.userId) {
      this.savingUser = true;
      this.institutionService
        .updateInstitutionUser(this.userId, this.userForm.value)
        .subscribe(
          (response) => {
            this.toasterService.show({
              message: this.translateService.instant('User was successfully updated.'),
              type: 'success'
            });

            this.userForm.patchValue(response.data);
            this.savingUser = false;
            if (close) { this.close(); } else { this.getInstitutionUser(); }
          },
          () => {
            this.toasterService.show({
              message: this.translateService.instant('There was an error while saving the user.'),
              type: 'error'
            });
            this.savingUser = false;
          }
        );
    } else {
      this.savingUser = true;
      this.institutionService
        .createInstitutionUser(this.userForm.value)
        .subscribe(
          (response) => {
            this.toasterService.show({
              message: this.translateService.instant('User was successfully created.'),
              type: 'success'
            });

            this.userId = response.data.id;
            this.userForm.patchValue(response.data);
            this.savingUser = false;

            if (close) {
              this.close();
            } else {
              this.router.navigate([ './user/' + this.userId ]);
            }
          },
          () => {
            this.toasterService.show({
              message: this.translateService.instant('There was an error while saving the user.'),
              type: 'error'
            });
            this.savingUser = false;
          }
        );
    }
  }

  delete() {
    if (!this.userId) { return; }

    this.modalService.confirm({
      title: this.translateService.instant('Delete'),
      body: this.translateService.instant('Are you sure you want to delete this promotion?'),
      size: 'small',
      buttons: [
        {
          text: this.translateService.instant('No'),
          role: 'cancel'
        },
        {
          text: this.translateService.instant('Yes'),
          handler: () => {
            this.deletingUser = true;
            this.institutionService
              .deleteInstitutionUser(this.userId)
              .subscribe(
                () => {
                  this.toasterService.show({
                    message: this.translateService.instant('Promotion successfully deleted.'),
                    type: 'success'
                  });
                  this.deletingUser = false;
                  this.close();
                },
                () => {
                  this.toasterService.show({
                    message: this.translateService.instant('Failed to delete the promotion.'),
                    type: 'error'
                  });
                  this.deletingUser = false;
                }
              );
          }
        }
      ]
    });
  }

  close() {
    this.router.navigate(['/institution', 'user', 'list']);
  }

}
