import { AppointmentSlot } from './appointment-slot';
import * as moment from 'moment';

export class ScheduleDay {
  date: any;
  today: boolean;
  past: boolean;
  loading: boolean;
  appointmentSlots: AppointmentSlot[] = [];

  constructor(date = null) {
    this.date = moment(date);
    this.today = this.date.isSame(moment(), 'day');
    this.past = this.date.isBefore(moment(), 'day');
    this.loading = true;
  }
}
