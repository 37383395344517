import { Patient } from './patient';
import { Doctor } from './doctor';
import { Specialty } from './specialty';
import { Diagnosis } from './diagnosis';

export class PatientVisit {
  id: number;
  patient_id: number;
  doctor_id: number;
  specialty_id: number;
  datetime: string;
  description: string = '';
  room_id: string;

  patient: Patient;
  doctor: Doctor;
  specialty: Specialty;
  diagnoses?: Diagnosis[] | any[];

  constructor(values: object = {}) {
    Object.assign(this, values);
  }
}
