import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from './api.service';
import { User } from '../classes/user';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private currentUserSubject = new BehaviorSubject<User>(null as User);
  currentUser = this.currentUserSubject.asObservable();

  constructor(
    private apiService: ApiService
  ) { }

  getUser(userId: number|'current', query: any = {}) {
    return this.apiService.get(`user/${userId}`, query);
  }

  updateUser(userId: number|'current', user: User) {
    return this.apiService.put(`user/${userId}`, user);
  }

  updateCurrentUser(user: User) {
    return this.updateUser('current', user);
  }

  sendPasswordChangeRequest(email: string) {
    return this.apiService.post(`user/change_password_request`, { email });
  }

  changePassword(params: any) {
    return this.apiService.post(`user/change_password`, params);
  }

  getCurrentUser() {
    return this.getUser('current', { include: 'permissions,roles,institutions' })
      .pipe(
        map(
          (response) => {
            this.currentUserSubject.next(response.data);
            return response.data;
          }
        )
      );
  }

  getCurrentUserInstitutions(query: any = {}) {
    return this.apiService.get(`user/current/institution`, query);
  }

  uploadPhoto(formData: any) {
    return this.apiService.post(`user/current/photo`, formData);
  }

  removePhoto() {
    return this.apiService.delete(`user/current/photo`);
  }

  getUserData(storageName: string, key: string) {
    const userKey = 'user_' + this.currentUserSubject.value.id;
    if (localStorage.getItem(storageName)) {
      const data = JSON.parse(localStorage.getItem(storageName));
      if (data[userKey]) {
        if (key) {
          return data[userKey][key];
        } else {
          return data[userKey];
        }
      }
    } else { return null; }
  }

  setUserData(storageName: string, key: string,  params: any) {
    const userKey = 'user_' + this.currentUserSubject.value.id;
    const data = localStorage.getItem(storageName) ? JSON.parse(localStorage.getItem(storageName)) : {};
    if (!data[userKey]) {
      data[userKey] = {};
      data[userKey][key] = params;
    } else {
      data[userKey][key] = params;
    }
    return localStorage.setItem(storageName, JSON.stringify(data));
  }

  deleteUserData(storageName: string, key: string) {
    const userKey = 'user_' + this.currentUserSubject.value.id;
    if (localStorage.getItem(storageName)) {
      const data = JSON.parse(localStorage.getItem(storageName));
      if (data[userKey][key]) {
        delete data[userKey][key];
        return localStorage.setItem(storageName, JSON.stringify(data));
      }
    } else { return null; }
  }

}
