import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PatientDocument } from '../../classes/patient-document';
import { PatientService } from '../../services/patient.service';
import { PatientVisit } from '../../classes/patient-visit';
import { Patient } from '../../classes/patient';

@Component({
  selector: 'app-patient-document-modal',
  templateUrl: './patient-document-modal.component.html'
})
export class PatientDocumentModalComponent {
  @Input() patient: Patient;
  @Input() visit: PatientVisit;
  @ViewChild('fileInput', {static: false}) fileInput: ElementRef;
  document: PatientDocument = new PatientDocument();
  file;
  typeOptions = [
    { id: 'image', name: 'Image' },
    { id: 'video', name: 'Video' },
    { id: 'document', name: 'Document' },
  ];

  constructor(
    public activeModal: NgbActiveModal,
    private patientService: PatientService
  ) { }

  saveDocument() {
    const formData = new FormData();

    formData.append('patient_id', this.patient.id.toString());
    formData.append('patient_visit_id', this.visit.id.toString());
    if (this.document.type) {
      formData.append('type', this.document.type);
    }
    if (this.fileInput.nativeElement.files.length > 0) {
      formData.append('file', this.fileInput.nativeElement.files[0]);
    }
    if (this.document.date) {
      formData.append('date', this.document.date);
    }
    if (this.document.name) {
      formData.append('name', this.document.name);
    }
    if (this.document.description) {
      formData.append('description', this.document.description);
    }

    this.patientService
      .createPatientDocument(this.patient.id, formData)
      .subscribe(
        response => {
          this.activeModal.close();
        }
      );
  }

  close() {
    this.activeModal.close();
  }

}
