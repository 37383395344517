import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DCSelectComponent } from './select.component';
import { FormsModule } from '@angular/forms';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { DCOffClickModule } from '../off-click/off-click.module';
import { DCInfiniteScrollModule } from "../../components/infinite-scroll/infinite-scroll.module";



@NgModule({
  declarations: [
    DCSelectComponent
  ],
    imports: [
        CommonModule,
        FormsModule,
        PerfectScrollbarModule,
        DCOffClickModule,
        DCInfiniteScrollModule
    ],
  exports: [
    DCSelectComponent
  ]
})
export class DCSelectModule { }
