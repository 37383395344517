<div class="dc-subheader"  [class.show-nav]="showSubheader">
    <div class="dc-container">
        <div class="dc-row middle">
            <div class="dc-col xs-12">
                <ul class="navigation">
                  <li>
                    <a [routerLink]="['/institution', 'details']" translate>Settings</a>
                  </li>
                  <li>
                    <a [routerLink]="['/institution', 'template', 'list']" translate>Templates</a>
                  </li>
                  <li>
                    <a [routerLink]="['/institution', 'service', 'list']" translate>Services</a>
                  </li>
                  <li>
                    <a [routerLink]="['/institution', 'promotion', 'list']" translate>Promotions</a>
                  </li>
                  <li>
                    <a [routerLink]="['/institution', 'doctor', 'list']" translate>Doctors</a>
                  </li>
                  <li>
                    <a [routerLink]="['/institution', 'user', 'list']" translate>Users</a>
                  </li>
                </ul>
              <div class="subheader-responsive">
              <span translate>Information</span>
              <span class="nav-toggle" (click)="toggleSubheader()">...</span>
              <span class="dc-icon remove-close" (click)="toggleSubheader()"></span>
            </div>
            </div>
        </div>
    </div>
  </div>
<div class="dc-section dc-content mb-80" [perfectScrollbar]="{ suppressScrollX: true}">
    <div [dcLoading]="loadingTemplate"></div>
    <div class="dc-container">
      <div class="dc-row middle">
        <div class="dc-col sm-6">
          <h1>
            <div class="super-heading" translate>Institution</div>
            <span *ngIf="institution">{{ institution.name }}</span>
          </h1>
        </div>
        <div class="dc-col sm-6 flex padding-bottom" style="flex-direction: row; justify-content: flex-end;">
          <dc-button *ngIf="institutionTemplateId" color="outline" icon="delete" size="small" [loading]="deletingTemplate" (click)="delete()">{{ 'Delete template' | translate }}</dc-button>
        </div>
      </div>
      <form [formGroup]="institutionTemplateForm">
        <div class="dc-row">
          <div class="dc-col md-6 sm-12">
            <dc-input
                label="{{ 'Name' | translate }}"
                formControlName="name"
            ></dc-input>
          </div>
        </div>
        <div class="dc-row">
          <div class="dc-col">
            <dc-text-editor
              [label]="'Content' | translate"
              type="standard-edit"
              formControlName="content"
            ></dc-text-editor>
          </div>
        </div>
      </form>
      <br>
    </div>
</div>
<div class="dc-bottom-bar">
  <div class="dc-container flex" style="height: 100%; align-items: center;">
      <dc-button size="small" color="secondary" (click)="close()">{{ 'Close' | translate }}</dc-button>
      &nbsp;&nbsp;
      <dc-button size="small" (click)="save(false)" [loading]="savingTemplate" [disabled]="institutionTemplateForm.invalid">{{ 'Save' | translate }}</dc-button>
      &nbsp;&nbsp;
      <dc-button size="small" (click)="save(true)" [loading]="savingTemplate" [disabled]="institutionTemplateForm.invalid">{{ 'Save & Close' | translate }}</dc-button>
  </div>
</div>
