import {
  AfterViewInit,
  Component,
  EventEmitter,
  forwardRef,
  Host,
  Input,
  OnInit,
  Optional,
  Output,
  SkipSelf,
  ViewChild
} from '@angular/core';
import { AbstractControl, ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'dc-input',
  templateUrl: './input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DCInputComponent),
      multi: true
    }
  ]
})
export class DCInputComponent implements OnInit, ControlValueAccessor, AfterViewInit {
  @ViewChild('input', {static: false}) input;
  @ViewChild('inputSearch', {static: false}) inputSearch;

  @Input() value: any = null;
  @Input() autoFocus: boolean = false;
  @Input() disabled: boolean = false;
  @Input() required: boolean = false;
  @Input() requiredType: 'default' | 'full' = 'default' ;
  @Input() readOnly: boolean = false;
  @Input() max: number = null;
  @Input() min: number = null;
  @Input() autoComplete: string = 'off';
  @Input() type: 'text' | 'number' | 'email' | 'password' | 'textarea' | 'search' = 'text';
  @Input() rows: string;
  @Input() cols: string;
  @Input() label: string;
  @Input() showLabel: boolean = true;
  @Input() placeholder: string = '';
  @Input() info: string = null;
  @Input() formControlName: string;
  @Input() errorMessages: any = {};
  @Input() showPassword: boolean = false;
  @Input() size: 'normal' | 'large' = null;
  @Input() platform: 'mobile' | 'website' = null;
  @Input() buttonText: string = null;
  @Input() resize: boolean = false;

  @Output() searchTerm = new EventEmitter();
  @Output() emitValue = new EventEmitter();
  @Output() blur =  new EventEmitter();
  @Output() focus =  new EventEmitter();

  onChange: (fn: any) => void;
  onTouched: (fn: any) => void;

  errors: any[] = [];
  showErrors: boolean = false;
  hidePassword: boolean = true;

  constructor(
      @Optional() @Host() @SkipSelf() private controlContainer: ControlContainer,
  ) { }

  ngOnInit(): void {
    if (this.autoFocus !== false) { this.autoFocus = true; }
    if (this.required !== false) { this.required = true; }
    if (this.disabled !== false) { this.disabled = true; }
    if (this.readOnly !== false) { this.readOnly = true; }
    if (this.showPassword !== false && this.type === 'password') { this.showPassword = true; }
    if (!this.label) { this.label = ''; }
    this.checkRequired();
  }

  ngAfterViewInit() {
    if (this.autoFocus) {
      if (this.input) { this.input.nativeElement.focus(); }
      if (this.inputSearch) { this.inputSearch.nativeElement.focus(); }
    }
  }

  writeValue(value: any): void {
    this.value = value ? value : '';
    if (this.value === '') {
      this.errors = [];
    }
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  checkRequired() {
    if (!this.formControlName) { return; }

    const formControl = this.controlContainer.control.get(this.formControlName);

    if (formControl.validator) {
      const validators = formControl.validator({}as AbstractControl);
      if (validators && validators.required) {
        this.required = true;
      }
    }
  }

  displayError() {
    if (!this.formControlName) { return; }

    const formControl = this.controlContainer.control.get(this.formControlName);

    if (formControl.errors) {
      const errors = [];

      for (const [key, value] of Object.entries(formControl.errors)) {
        errors.push(key);
      }

      this.errors = errors;
    } else {
      this.errors = [];
    }
  }

  onChangeValue() {
    if (this.onChange) {
      this.onChange(this.value);
      this.displayError();
    }
    this.valueEmit();
  }

  toggleErrors() {
    this.showErrors = !this.showErrors;
  }

  togglePassword() {
    if (this.hidePassword) {
      this.hidePassword = false;
      this.type = 'text';
    } else {
      this.hidePassword = true;
      this.type = 'password';
    }
  }

  emitSearchTerm() {
    if (this.value) {
      this.searchTerm.emit({ value: this.value });
    }
  }
  valueEmit() {
    this.emitValue.emit({ value: this.value });
  }

  clearSearchTerm() {
    this.value = '';
    this.searchTerm.emit({ value: '' });
    this.onChange(this.value);
  }
}
