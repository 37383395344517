import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DCSliderModule } from "../carousel/slider.module";
import { DCDatepickerMobileComponent } from "./datepicker-mobile.component";



@NgModule({
  declarations: [
    DCDatepickerMobileComponent
  ],
  imports: [
    CommonModule,
    DCSliderModule
  ],
  exports: [
    DCDatepickerMobileComponent
  ]
})
export class DCDatepickerMobileModule { }
