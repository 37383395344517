import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { AppointmentService } from '../../services/appointment.service';
import { ScheduleTemplate } from '../../classes/schedule-template';
import { ScheduleDay } from '../../classes/schedule-day';
import { AppointmentSlotCreateModalComponent } from '../../modals/appointment-slot-create/appointment-slot-create-modal.component';

import { ModalService } from '@dc/ui/components/modal/modal.service';
import { AppointmentCreateModalComponent } from '../../modals/appointment-create/appointment-create-modal.component';
import { AppointmentSlot } from '../../classes/appointment-slot';

import { DCToastService } from '@dc/ui/components/toast/toast.service';
import { Appointment } from '../../classes/appointment';
import { AppointmentCancelModalComponent } from '../../modals/appointment-cancel/appointment-cancel-modal.component';
import { TelemedicineConnectComponent } from '../../modals/telemedicine-connect/telemedicine-connect.component';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterScheduleModalComponent } from '../../modals/filter-schedule/filter-schedule.component';
import { TranslateService } from '@ngx-translate/core';
import { AppointmentSlotCommentEditComponent } from '../../modals/appointment-slot-comment-edit/appointment-slot-comment-edit.component';
import { User } from '../../classes/user';
import { UserService } from '../../services/user.service';
import { AppointmentSlotDeleteComponent } from '../../modals/appointment-slot-delete/appointment-slot-delete.component';
import { AppointmentSlotDetailsComponent } from '../../modals/appointment-slot-details/appointment-slot-details.component';

@Component({
  selector: 'main [dc-schedule]',
  templateUrl: './schedule.component.html'
})
export class ScheduleComponent implements OnInit, OnDestroy {
  @ViewChild('weekPicker') weekPicker;

  currentUser: User;
  today: any;
  mode: 'today'|'3days'|'5days'|'currentWeek';
  startDate: any;
  endDate: any;
  scheduleTemplates: ScheduleTemplate[] = [];
  createScheduleTemplateMode = false;
  templateName = '';
  filters: {
    status: any,
    specialty: any,
    doctor: any
  } = {
    status: 'all',
    specialty: null,
    doctor: null
  };
  filterCounter: number = 0;

  refreshInterval: any;
  days: ScheduleDay[] = [];
  datepickerValue: any;

  constructor(
    private appointmentService: AppointmentService,
    private toastService: DCToastService,
    private modalService: ModalService,
    private router: Router,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private userService: UserService,
  ) { }

  ngOnInit() {
    moment.locale('sr');
    this.currentUser = this.route.snapshot.parent.data.user;
    this.route.queryParams.subscribe((queryParams) => {
      this.filterCounter = 0;
      if (queryParams.status && queryParams.status !== 'all') {
        this.filters.status = queryParams.status; this.filterCounter = this.filterCounter + 1; }
      if (queryParams.specialty) { this.filters.specialty = queryParams.specialty; this.filterCounter = this.filterCounter + 1; }
      if (queryParams.doctor) { this.filters.doctor = queryParams.doctor; this.filterCounter = this.filterCounter + 1; }
      if (queryParams.selectedDate) {
        this.setMode(window.innerWidth, queryParams.selectedDate);
        this.datepickerValue = queryParams.selectedDate;
      } else {
        if (!sessionStorage.getItem('selected_dates')) {
          this.setMode(window.innerWidth);
        }
      }
    });
    this.getFiltersFromStorage();
    this.getDateFromSessionStorage();
    this.refreshInterval = setInterval(
      () => this.getAppointmentSlots(this.startDate, this.endDate),
      15 * 1000
    );
  }

  ngOnDestroy() {
    clearInterval(this.refreshInterval);
  }

  @HostListener('window:resize', ['$event'])

  onResize(event: any) {
    this.setMode(event.target.innerWidth, this.datepickerValue, true);
  }

  setMode(width: number, selectedDate?: any, resize: boolean = false) {
    let mode: any;

    // Calcualte mode
    if (width < 550) {
      mode = 'today';
    } else if (550 <= width && width <= 1150 ) {
      mode = '3days';
    } else {
      mode = 'currentWeek';
    }
    if (resize) {
      if (mode !== this.mode) {
        this.setDay(selectedDate, mode);
      }
    } else {
      this.setDay(selectedDate, mode);
    }
  }

  setDay(selectedDate?: any, mode?: any) {
    this.mode = mode;
    this.days = [];
    if (this.mode === 'currentWeek') {
      if (selectedDate) {
        this.startDate = moment(selectedDate).startOf('isoWeek');
        this.endDate = moment(selectedDate).endOf('isoWeek');
      } else {
        this.startDate = moment().startOf('isoWeek');
        this.endDate = moment().endOf('isoWeek');
      }
    }
    if (this.mode === '3days') {
      if (selectedDate) {
        this.startDate = moment(selectedDate).startOf('day').subtract(1, 'day');
        this.endDate = moment(selectedDate).endOf('day').add(1, 'day');
      } else {
        this.startDate = moment().startOf('day').subtract(1, 'day');
        this.endDate = moment().endOf('day').add(1, 'day');
      }
    }
    if (this.mode === '5days') {
      this.startDate = moment().startOf('day').subtract(2, 'day');
      this.endDate = moment().endOf('day').add(2, 'day');
    }
    if (this.mode === 'today') {
      if (selectedDate) {
        this.startDate = moment(selectedDate).startOf('day');
        this.endDate = moment(selectedDate).endOf('day');
      } else {
        this.startDate = moment().startOf('day');
        this.endDate = moment().endOf('day');
      }
    }
    this.getAppointmentSlots(this.startDate, this.endDate);
  }

  getAppointmentSlots(dateFrom: any, dateTo: any) {
    dateFrom = moment(dateFrom);
    dateTo = moment(dateTo);

    // make containers for the data
    let date = dateFrom;
    while (date <= dateTo) {
        this.addOneDay(date);
        date = date.clone().add(1, 'day');
    }

    // get fresh data
    const query: any = {
        date_from: dateFrom.format('YYYY-MM-DD'),
        date_to: dateTo.format('YYYY-MM-DD'),
        include: 'specialty,appointment,doctor,appointment.patient,appointment.service',
        pagination: false
    };
    if (this.filters) {
      if (this.filters.status) { query.status = this.filters.status; }
      if (this.filters.specialty) { query.specialty_id = this.filters.specialty; }
      if (this.filters.doctor) { query.doctor_id = this.filters.doctor; }
    }

    this.appointmentService.getAppointmentSlots(query)
      .subscribe(
        (response: any) => {
          this.fillDays(response.data);
        }
      );
  }

  addOneDay(date: any) {
    let index = this.days.length;
    let exists = false;
    for ( let i = 0; i < this.days.length; i++) {
      if (moment(this.days[i].date).isSame(date, 'day')) {
        exists = true;
        break;
      } else if (moment(this.days[i].date).isAfter(date)) {
        index = i;
        break;
      }
    }
    if (!exists) {
      this.days.splice(index, 0, new ScheduleDay(date));
    }
  }

  fillDays(appointmentSlots: AppointmentSlot[]) {
    for (const day of this.days) {
      day.appointmentSlots = [];
      for (const appointmentSlot of appointmentSlots) {
        if (moment(appointmentSlot.starts_at).format('L') === day.date.format('L')) {
          let index = -1;

          for ( let i = 0; i < day.appointmentSlots.length; i++ ) {
            if ( day.appointmentSlots[i].id === appointmentSlot.id ) {
              index = i;
            }
          }

          if (index > -1) {
            // exists
            if (appointmentSlot.deleted_at) {
              // exists, delete
              // console.log('delete');
              day.appointmentSlots.splice(index, 1);
            } else {
              // exists, update
              // console.log('update');
              day.appointmentSlots.splice(index, 1, appointmentSlot);
            }
          } else {
            // !exists, add
            // console.log('add');
            day.appointmentSlots.push(appointmentSlot);
          }

        }
      }
      day.loading = false;
    }
  }

  previous() {
    if (this.mode === 'currentWeek') {
      this.startDate = moment(this.startDate).subtract(1, 'week');
      this.endDate = moment(this.endDate).subtract(1, 'week');
      this.datepickerValue = this.startDate;
    }
    if (this.mode === '3days') {
      this.startDate = moment(this.startDate).subtract(3, 'day');
      this.endDate = moment(this.endDate).subtract(3, 'day');
      this.datepickerValue = this.startDate;
    }
    if (this.mode === '5days') {
      this.startDate = moment(this.startDate).subtract(5, 'day');
      this.endDate = moment(this.endDate).subtract(5, 'day');
    }
    if (this.mode === 'today') {
      this.startDate = moment(this.startDate).subtract(1, 'day');
      this.endDate = moment(this.endDate).subtract(1, 'day');
      this.datepickerValue = this.startDate;
    }
    this.days = [];
    this.setSelectedDateInSessionStorage();
  }

  next() {
    if (this.mode === 'currentWeek') {
      this.startDate = moment(this.startDate).add(1, 'week');
      this.endDate = moment(this.endDate).add(1, 'week');
      this.datepickerValue = this.startDate;
    }
    if (this.mode === '3days') {
      this.startDate = moment(this.startDate).add(3, 'day');
      this.endDate = moment(this.endDate).add(3, 'day');
      this.datepickerValue = this.startDate;
    }
    if (this.mode === '5days') {
      this.startDate = moment(this.startDate).add(5, 'day');
      this.endDate = moment(this.endDate).add(5, 'day');
    }
    if (this.mode === 'today') {
      this.startDate = moment(this.startDate).add(1, 'day');
      this.endDate = moment(this.endDate).add(1, 'day');
      this.datepickerValue = this.startDate;
    }
    this.days = [];
    this.setSelectedDateInSessionStorage();
  }

  showAppointmentSlot(appointmentSlot: AppointmentSlot) {
    const modalRef = this.modalService.open(AppointmentSlotDetailsComponent);
    modalRef.componentInstance.appointmentSlot = appointmentSlot;
    modalRef.result.then(
      () => {
        this.getAppointmentSlots(this.startDate, this.endDate);
      },
      () => { }
    );
  }

  showCreateAppointmentSlot(multi: boolean, date?: moment.Moment) {
    const modalRef = this.modalService.open(AppointmentSlotCreateModalComponent);
    modalRef.componentInstance.multi = multi;
    if (date) {
      modalRef.componentInstance.date = date.format('YYYY-MM-DD');
    }
    modalRef.result.then(
      (result) => {
        if (result.showAppointmentCreate) {
          const params = {
            include: 'specialty,appointment,doctor,appointment.patient,appointment.service'
          };
          this.appointmentService.getAppointmentSlot(result.appointmentSlot.id, params)
            .subscribe(
              (appointmentSlot) => {
                this.showCreateAppointment(appointmentSlot.data);
              },
              () => {},
            );
        }
        this.getAppointmentSlots(this.startDate, this.endDate);
      },
      () => { }
    );
  }

  showCreateAppointment(appointmentSlot: AppointmentSlot) {
    if (appointmentSlot.appointment) { return; }

    const modalRef = this.modalService.open(AppointmentCreateModalComponent);
    modalRef.componentInstance.appointmentSlot = appointmentSlot;
    modalRef.result.then(
      () => {
        this.getAppointmentSlots(this.startDate, this.endDate);
      },
      () => { }
    );
  }

  showAppointmentNote(appointment: Appointment) {
    const modalRef = this.modalService.open(AppointmentSlotCommentEditComponent);
    modalRef.componentInstance.appointment = appointment;
    modalRef.result.then(
      () => {
        this.getAppointmentSlots(this.startDate, this.endDate);
      },
      () => { }
    );
  }

  teleConnect(event, appointmentSlot) {
    event.stopPropagation();
    if (appointmentSlot && appointmentSlot.appointment.patient_visit_id) {
      this.router.navigate(
        ['/institution', 'patient', appointmentSlot.appointment.patient_id, 'visit', appointmentSlot.appointment.patient_visit_id]
      );
    } else {
      const modalRef = this.modalService.open(TelemedicineConnectComponent);
      modalRef.componentInstance.appointmentSlot = appointmentSlot;
      modalRef.result.then(
        () => {},
        () => {}
      );
    }
  }

  // Filters

  showScheduleFilterModal() {
    this.checkFilters();
    const modalRef = this.modalService.open(FilterScheduleModalComponent);
    modalRef.componentInstance.filters = this.filters;
    modalRef.result.then(
      (filters) => {
        this.filters = filters;
        this.userService.setUserData('dok-storage', 'calendar-filters', filters);
        this.router.navigate([], {queryParams: this.filters, queryParamsHandling: 'merge', relativeTo: this.route});
        this.getAppointmentSlots(this.startDate, this.endDate);
      },
      () => {}
    );
  }

  checkFilters() {
    if (this.route.snapshot.queryParams.status) {
      this.filters.status = this.route.snapshot.queryParams.status; } else { this.filters.status = 'all'; }
    if (this.route.snapshot.queryParams.specialty) { this.filters.specialty = this.route.snapshot.queryParams.specialty; }
    if (this.route.snapshot.queryParams.doctor) { this.filters.doctor = this.route.snapshot.queryParams.doctor; }
  }

  getFiltersFromStorage() {
    if (this.currentUser) {
      const data = this.userService.getUserData('dok-storage', 'calendar-filters');
      if (data) {
        this.filters = data;
      } else {
        this.resetFilters();
      }
      this.router.navigate([], {queryParams: this.filters, queryParamsHandling: 'merge', relativeTo: this.route});
    }
  }

  resetFilters() {
    this.filters = {
      status: null,
      specialty: null,
      doctor: null
    };
  }

  // Select date

  setWeek(event) {
    this.setSelectedDateInSessionStorage();
  }

  // Need to find solution to add this.selectWeekToggle() before open in datepicker component!
  openWeekPicker() {
    this.weekPicker.dateTimeInput.nativeElement.click();
    setTimeout(() => {this.selectWeekToggle(); });
  }

  getDateFromSessionStorage() {
    if (sessionStorage.getItem('selected_dates')) {
      const date = JSON.parse(sessionStorage.getItem('selected_dates'));
      this.router.navigate([], {queryParams: date, queryParamsHandling: 'merge', relativeTo: this.route});
    }
  }

  setSelectedDateInSessionStorage() {
    const selectedDate: any = {};
    selectedDate.selectedDate = moment(this.datepickerValue).format('YYYY-MM-DD');
    this.router.navigate([], {queryParams: selectedDate, queryParamsHandling: 'merge', relativeTo: this.route});
    sessionStorage.setItem('selected_dates', JSON.stringify(selectedDate));
  }

  selectWeekToggle() {
    if (this.mode !== '3days' && this.mode !== 'today' && document.querySelectorAll('.owl-dt-calendar-cell-selected')[0]) {
      document.querySelectorAll('.owl-dt-calendar-cell-selected')[0].parentElement.parentElement.classList.add('active-week');
    }
  }

}
