import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { ModalService } from 'libs/ui/src/public-api';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-change-password-request',
  templateUrl: './change-password-request.component.html'
})
export class ChangePasswordRequestComponent implements OnInit {
  changePasswordRequestForm: FormGroup;

  sendingRequest: boolean = false;

  constructor(
    private modalService: ModalService,
    private formBuilder: FormBuilder,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.changePasswordRequestForm = this.formBuilder.group({
      email: ['', [Validators.email, Validators.required]],
    });
  }

  sendRequest() {
    this.sendingRequest = true;
    this.userService.sendPasswordChangeRequest(this.changePasswordRequestForm.value)
      .subscribe(
        () => {
          this.modalService.alert({
            title: 'Success',
            body: 'Password change request sent. Please check your email.',
            size: 'small',
            type: 'default',
            button: {
              text: 'Okay',
              role: 'cancel'
            }
          });
          this.sendingRequest = false;
        },
        () => {
          this.modalService.alert({
            title: 'Error',
            body: 'Wrong email address.',
            size: 'small',
            type: 'danger',
            button: {
              text: 'Okay',
              role: 'cancel'
            }
          });
          this.sendingRequest = false;
        }
      );
  }

}
