import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OnInit, OnDestroy, Component, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppointmentSlot } from '../../classes/appointment-slot';
import { Patient } from '../../classes/patient';
import { PatientService } from '../../services/patient.service';
import { AppointmentService } from '../../services/appointment.service';
import { ModalService } from 'libs/ui/src/public-api';
import { PatientCreateModalComponent } from '../patient-create/patient-create-modal.component';
import { InstitutionService } from '../../services/institution.service';
import { AppointmentSlotDeleteComponent } from '../appointment-slot-delete/appointment-slot-delete.component';
import { AppointmentCancelModalComponent } from '../appointment-cancel/appointment-cancel-modal.component';
import { DCToastService } from '@dc/ui/components/toast/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-appointment-slot-details',
  templateUrl: './appointment-slot-details.component.html',
})
export class AppointmentSlotDetailsComponent implements OnInit, OnDestroy {
  @Input() appointmentSlot: AppointmentSlot;
  patients: Patient[] = [];
  patientId: number;
  selectedPatient: Patient;
  institutionServices: any[] = [];
  institutionServicesFiltered: any[] = [];
  institutionServiceId: number;
  comment: string = '';

  loadingServices: boolean = false;

  subscriptions: Subscription[] = [];

  constructor(
    private patientService: PatientService,
    private appointmentService: AppointmentService,
    private institutionService: InstitutionService,
    private modalService: ModalService,
    private toastService: DCToastService,
    private translateService: TranslateService,
    private router: Router,
    private activeModal: NgbActiveModal
  ) {}

  ngOnInit() {
    this.getPatients();
    this.getInstitutionServices();
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  getSelectedPatient() {
    this.selectedPatient = this.patients.find(patient => patient.id === this.patientId);
  }

  getPatients(search_term?: string) {
    const params: any = {
      sort: 'created_at',
      direction: 'desc'
    };
    if (search_term) {
      params.search_term = search_term;
    }
    this.patientService
        .getPatients(params)
        .subscribe(
            (response) => {
                this.patients = response.data;
            },
            () => {}
        );
  }

  getInstitutionServices() {
    const params: any = {
      specialty_id: this.appointmentSlot.specialty_id,
      pagination: false,
    };
    this.loadingServices = true;
    this.institutionService
      .getInstitutionServices(params)
      .subscribe(
        (response: any) => {
          this.institutionServices = response.data;
          this.institutionServicesFiltered = response.data;
          this.loadingServices = false;
        },
        () => {
          this.loadingServices = false;
        }
      );
  }

  filterInstitutionServices(searchTerm: string) {
    this.loadingServices = true;
    if (searchTerm.length > 0) {
      this.institutionServicesFiltered = this.institutionServices
        .filter(is => is.name.toLowerCase().includes(searchTerm.toLowerCase()));
      this.loadingServices = false;
    } else {
      this.institutionServicesFiltered = this.institutionServices;
      this.loadingServices = false;
    }
  }

  addPatient(name?: string) {
    const modalRef = this.modalService.open(PatientCreateModalComponent);
    modalRef.componentInstance.name = name;
    modalRef.result.then(
      (patient) => {
        this.patients = this.patients.concat([patient]);
        this.patientId = patient.id;
        this.selectedPatient = patient;
      },
      () => { }
    );
  }

  save() {
    const params: any = {
        appointment_slot_id: this.appointmentSlot.id,
        patient_id: this.patientId,
        comment: this.comment,
        institution_service_id: this.institutionServiceId
    };
    this.appointmentService
        .createAppointment(params)
        .subscribe(
            () => {
              this.activeModal.close();
            },
            (error) => {
              this.modalService.error({ errors: error });
            }
        );
  }

  close() {
    this.activeModal.dismiss();
  }

  delete() {
    if (this.appointmentSlot.appointment) { return; }

    const modalRef = this.modalService.open(AppointmentSlotDeleteComponent);
    modalRef.componentInstance.appointmentSlotId = this.appointmentSlot.id;
    modalRef.componentInstance.appointmentSlotGuid = this.appointmentSlot.guid;
    modalRef.result
      .then(() => {
        this.activeModal.close();
      })
      .catch(() => {});
  }

  cancel() {
    const modalRef = this.modalService.open(AppointmentCancelModalComponent);
    modalRef.componentInstance.appointment = this.appointmentSlot.appointment;
    modalRef.result.then(
      () => {
        this.activeModal.close();
      },
      () => { }
    );
  }

  showPatientRecord() {
    this.router.navigate(['/institution', 'patient', this.appointmentSlot.appointment.patient_id, 'details']);
    this.activeModal.dismiss();
  }

  patientNotShow() {
    this.appointmentSlot.appointment.no_show = !this.appointmentSlot.appointment.no_show;
    this.appointmentService.changeAppointment(this.appointmentSlot.appointment)
      .subscribe(
        () => {
          this.toastService.show(
            {
              type: 'success',
              message: this.translateService.instant('Appointment successfully updated.')
            }
          );
          this.activeModal.close();
        },
        () => {
          this.toastService.show(
            {
              type: 'error',
              message: this.translateService.instant('Failed to update appointment.')
            }
          );
        }
      );
  }
}
