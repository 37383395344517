<div class="dc-modal medium">
  <div class="dc-modal__header">
    <h2 class="dc-modal__title">{{ 'Add therapies' | translate }}</h2>
    <span class="dc-modal__close" (click)="close()"></span>
  </div>
  <div class="dc-modal__body">
    <div class="dc-row">
      <div class="dc-col md-12 no-margin">
        <div class="dc-row">
          <div class="dc-col no-margin">
            <h2 class="dc-text-color-primary">Lek</h2>
            <dc-input
              type="search"
              [label]="'Therapy' | translate"
              [(ngModel)]="therapiesSearchTerm"
              [ngModelOptions]="{standalone: true}"
              (ngModelChange)="searchTherapies()"
            ></dc-input>
            <div style="position: relative;">
              <div class="therapy-search-results" [class.empty]="therapiesSearchResults.length === 0">
                <div class="therapy" *ngFor="let therapy of therapiesSearchResults" [class.selected]="therapy.id === selectedTherapy?.id" (click)="selectTherapie(therapy)">
                  <div>{{ therapy.name }} {{therapy.description}}</div>
                  <div class="inn">{{ therapy.inn }}</div>
                </div>
              </div>
            </div>
          </div>
          <hr style="margin: 0">
          <div class="dc-col">
            <dc-input label="Prepisana doza" type="textarea" required [(ngModel)]="description"></dc-input>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="dc-modal__actions">
    <div class="dc-modal__action-left"></div>
    <div class="dc-modal__action-right">
      <dc-button color="outline" (click)="close()">{{ 'Cancel' | translate }}</dc-button>
      <dc-button [disabled]="!selectedTherapy || !description" (click)="save()">{{ 'Apply' | translate }}</dc-button>
    </div>
  </div>
</div>
