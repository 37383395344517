<div class="dc-checkbox">
  <label [ngClass]="{'disabled': disabled}">
    <input
      type="checkbox"
      [ngClass]="{ 'checked': value, 'mobile': platform === 'mobile' }"
      (change)="onChangeStatus($event);"
      [name]="name"
      [checked]="value"
      [disabled]="disabled">
    <span class="dc-checkbox__label">{{ label }}</span>
  </label>
</div>

