import { Injectable } from '@angular/core';
import { Invitation } from '../classes/invitation';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class InvitationService {

  constructor(
    private apiService: ApiService
  ) { }

  invite(invitation: Invitation) {
    return this.apiService.post(`invitation`, invitation);
  }

  revoke(invitationId: string) {
    return this.apiService.delete(`invitation/${invitationId}`);
  }

  connect(invitationId: string, params: any = {}) {
    return this.apiService.post(`invitation/${invitationId}/connect`, params);
  }

}
