<div class="dc-subheader"  [class.show-nav]="showSubheader">
    <div class="dc-container">
        <div class="dc-row middle">
            <div class="dc-col xs-12">
                <ul class="navigation">
                  <li>
                    <a [routerLink]="['/institution', 'details']" translate>Settings</a>
                  </li>
                  <li>
                    <a [routerLink]="['/institution', 'template', 'list']" translate>Templates</a>
                  </li>
                  <li>
                    <a [routerLink]="['/institution', 'service', 'list']" routerLinkActive="active" translate>Services</a>
                  </li>
                  <li>
                    <a [routerLink]="['/institution', 'promotion', 'list']" translate>Promotions</a>
                  </li>
                  <li>
                    <a [routerLink]="['/institution', 'doctor', 'list']" translate>Doctors</a>
                  </li>
                  <li *ngIf="user.roles.includes('Global Super Administrator') || user.roles.includes('Institution Owner')">
                    <a [routerLink]="['/institution', 'user', 'list']" translate>Users</a>
                  </li>
                </ul>
                <div class="subheader-responsive">
                  <span translate>Information</span>
                  <span class="nav-toggle" (click)="toggleSubheader()">...</span>
                  <span class="dc-icon remove-close" (click)="toggleSubheader()"></span>
                </div>
            </div>
        </div>
    </div>
  </div>
<div class="dc-section dc-content" [perfectScrollbar]="{ suppressScrollX: true}">
    <div [dcLoading]="loadingServices"></div>
    <div class="dc-container">
      <div class="dc-row middle">
        <div class="dc-col sm-12">
          <h1>
            <div class="super-heading" translate>Institution</div>
            <span *ngIf="institution">{{ institution.name }}</span>
          </h1>
        </div>
      </div>
      <div class="dc-row">
        <div class="dc-col sm-12">
          <dc-accordions *ngIf="specialties.length > 0">
            <dc-accordion *ngFor="let specialty of specialties" [title]="specialty.name">
              <div class="dc-row">
                <div class="dc-col sm-12">
                  <table class="dc-table">
                    <tbody>
                      <tr class="dc-clickable" *ngFor="let institutionService of specialty.institutionServices" (click)="editInstitutionService(institutionService.id)">
                        <td>
                          <label class="dc-table__label" translate>Service name</label>
                          <div class="dc-table__value">
                            <span>{{ institutionService.name }}</span>
                          </div>
                        </td>
                        <td style="width: 100px;">
                          <label class="dc-table__label" translate>Price</label>
                          <div class="dc-table__value">
                            <span>{{ institutionService.price }}</span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="dc-row">
                <div class="dc-col sm-12">
                  <dc-button icon="plus" (click)="createInstitutionService(specialty.id)">{{ 'Add service' | translate }}</dc-button>
                </div>
              </div>
            </dc-accordion>
          </dc-accordions>
        </div>
      </div>
    </div>
</div>
