import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { UserService } from "../services/user.service";

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return new Observable<boolean>(obs => {
      const user = next.parent?.data?.user;
      if (user) {
        const foundRole = user.roles.some(r=> next.data.roles.indexOf(r) >= 0);
        if (foundRole) {
          obs.next(true);
        } else {
          this.router.navigate(['/unauthorized']);
          obs.next(false);
        }
      } else {
        if (localStorage.getItem('jwt')) {
          this.userService.getCurrentUser()
            .subscribe(
              (response) => {
                const foundRole = response.roles.some(r=> next.data.roles.indexOf(r) >= 0);
                if (foundRole) {
                  obs.next(true);
                } else {
                  this.router.navigate(['/unauthorized']);
                  obs.next(false);
                }
              },
              () => {}
            )
        } else {
          this.router.navigate(['/login']);
        }
      }
    });
  }

}
