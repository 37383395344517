import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from "@angular/forms";
import { DCOffClickModule } from "@dc/ui/components/off-click/off-click.module";
import { DCButtonModule } from "@dc/ui/components/button/button.module";
import { DCTimepickerComponent } from "@dc/ui/components/timepicker/timepicker.component";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";



@NgModule({
  declarations: [
    DCTimepickerComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    DCOffClickModule,
    DCButtonModule,
    PerfectScrollbarModule
  ],
  exports: [
    DCTimepickerComponent
  ]
})
export class DCTimepickerModule { }
