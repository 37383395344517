<div class="dc-modal confirm small danger">
  <div class="dc-modal__header">
    <h2 class="dc-modal__title">{{ 'Delete' | translate }}</h2>
    <span class="dc-modal__close" (click)="close()"></span>
  </div>
  <div class="dc-modal__body">
    {{'Are you sure you want to delete this appointment slot?' | translate}}
    <br><br>
    <dc-checkbox *ngIf="appointmentSlotGuid" [label]="'Delete all terms from this series.' | translate" [(ngModel)]="deleteAllFromSeries"></dc-checkbox>
  </div>
  <div class="dc-modal__footer">
    <button class="cancel" (click)="close()">{{'No' | translate}}</button>
    <button class="primary" (click)="delete()">{{'Yes' | translate}}</button>
  </div>
</div>
