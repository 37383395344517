<div
  class="dc-file__upload-wrapper"
  (click)="inputFile.click()"
  [ngClass]="{'readyToDrop': readyToDrop, 'disabled': disabled, 'readOnly': readOnly}"
  (dragenter)="dragEnter($event)"
  (dragover)="dragEnter($event)"
  (dragleave)="dragLeave($event)"
  (drop)="onDrop($event)">
  <span class="dc-file__icon {{icon}}" *ngIf="icon" [ngClass]="{'dc-icon': icon}"></span>
  <p class="dc-file__label">{{label}}</p>
  <label>
    <input
      ng2FileSelect
      type="file"
      [uploader]="uploader"
      [hidden]="true"
      [name]="name"
      [disabled]="disabled"
      [readOnly]="readOnly"
      [required]="required"
      [(ngModel)]="value"
      [multiple]="multiple"
      (change)="emitChange()"
      #inputFile>
  </label>
</div>
