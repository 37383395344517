import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DCSwitchComponent } from './switch.component';



@NgModule({
  declarations: [
    DCSwitchComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    DCSwitchComponent
  ]
})
export class DCSwitchModule { }
