import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'dc-tab',
  templateUrl: './tab.component.html'
})
export class DCTabComponent implements OnInit {
  @Input() title: string;
  @Input() active: boolean = false;

  ngOnInit() {
    if (this.active !== false) { this.active = true; }
  }
}
