import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Doctor } from '../../classes/doctor';
import { Patient } from '../../classes/patient';
import { InvitationService } from '../../services/invitation.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-invitation-modal',
  templateUrl: './invitation-modal.component.html'
})
export class InvitationModalComponent implements OnInit {
  @Input() patient: Patient;
  @Input() doctor: Doctor;
  invitationForm: FormGroup;

  constructor(
    private activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private invitationService: InvitationService
  ) { }

  ngOnInit() {
    if (this.patient) {
      this.invitationForm = this.formBuilder.group({
        email: [this.patient.email, [Validators.required, Validators.email]],
        profile_id: [this.patient.id, [Validators.required]],
        profile_type: ['patient', [Validators.required]]
      });
    }
    if (this.doctor) {
      this.invitationForm = this.formBuilder.group({
        email: [this.doctor.email, [Validators.required, Validators.email]],
        profile_id: [this.doctor.id, [Validators.required]],
        profile_type: ['doctor', [Validators.required]]
      });
    }
  }

  invite() {
    this.invitationService
      .invite(this.invitationForm.value)
      .subscribe(
        () => {
          this.activeModal.close();
        },
        () => { }
      );
  }

  close() {
    this.activeModal.dismiss();
  }

}
