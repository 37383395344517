<div class="public">
  <img class="logo" src="assets/images/logo-white.svg" alt="Doktorijum">

  <div *ngIf="!loading">
    <div *ngIf="invitation; else notValidInvitation">
      <div class="institution">
        <img [src]="institution?.logo_url ? institution.logo_url : 'assets/images/institution-placeholder.svg'">
        <h1>{{ institution?.name }}</h1>
      </div>

      <div class="form">
        <h2 translate>Connect</h2>

        <div class="message error" *ngIf="error">
          <div class="text" translate>{{ error }}</div>
          <div class="icon close" (click)="clearError()"></div>
        </div>

        <div class="dc-row">
          <div class="dc-col">
            <p>
              Do you want to connect your account ({{ user?.first_name }} {{ user?.last_name }})
              to institution {{ institution?.name }}
            </p>

          </div>
        </div>
          <div class="dc-row">
            <div class="dc-col">
            <dc-button size="full" (click)="connect()">{{ 'Connect' | translate }}</dc-button>
          </div>
        </div>

        <div class="logout">
            <span translate>Not your account?</span>
            <a (click)="logout()" translate>Logout</a>
        </div>
      </div>
    </div>

    <ng-template #notValidInvitation>
      <div class="form">
        <h2 translate>Invitation is not valid</h2>
        <p class="text-center" translate>{{ error }}</p>
      </div>
    </ng-template>
  </div>

</div>
