<ng-container>
  <div class="header__actions" [class.not-minimize]="!minimize">
    <div class="header__actions-right" *ngIf="minimize">
      <div class="action-icon video" [class.hide]="!video" (click)="toggleVideo()"></div>
      <div class="action-icon audio" [class.hide]="!audio" (click)="toggleAudio()"></div>
      <div class="action-icon full-screen" (click)="fullScreenToggle()" *ngIf="!mobile && !minimize"></div>
    </div>
    <div>
      <div class="action-icon margin-right" [class.minimize]="!minimize" [class.full-screen]="minimize" (click)="minimizeCall()" *ngIf="!mobile && !fullScreen"></div>
    </div>
  </div>
  <div id="call" class="dc-call" [dcLoading]="showLoading" [hidden]="minimize"></div>
  <div class="footer__actions" *ngIf="!minimize">
    <div class="action-icon video" [class.hide]="!video" (click)="toggleVideo()"></div>
    <div class="action-icon audio" [class.hide]="!audio" (click)="toggleAudio()"></div>
    <div class="action-icon" [class.minimize]="fullScreen" [class.full-screen]="!fullScreen" (click)="fullScreenToggle()" *ngIf="!mobile"></div>
  </div>
</ng-container>
