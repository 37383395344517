import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DCAlertComponent } from './alert/alert.component';
import { DCErrorComponent } from './error/error.component';
import { DCConfirmComponent } from './confirm/confirm.component';
import { ModalService } from './modal.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';



@NgModule({
  declarations: [
    DCAlertComponent,
    DCConfirmComponent,
    DCErrorComponent
  ],
  imports: [
    CommonModule
  ],
  providers: [
    ModalService,
    NgbModal,
    NgbActiveModal
  ],
  exports: [
    DCAlertComponent,
    DCConfirmComponent,
    DCErrorComponent
  ]
})
export class DCModalModule { }
