import { Component, OnInit, OnDestroy, Input, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AppointmentService } from '../../services/appointment.service';
import { SpecialtyService } from '../../services/specialty.service';
import { Specialty } from '../../classes/specialty';
import { DoctorService } from '../../services/doctor.service';
import { Doctor } from '../../classes/doctor';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from '@dc/ui/components/modal/modal.service';

@Component({
    selector: 'app-appointment-slot-create-modal',
    templateUrl: './appointment-slot-create-modal.component.html'
})
export class AppointmentSlotCreateModalComponent implements OnInit, OnDestroy {
    @ViewChild('search') searchElement: ElementRef;
    @Input() multi: boolean = false;
    @Input() date: string;
    now = new Date();
    appointmentSlotFrom: FormGroup;
    currentStep: number = 1;
    specialties: Specialty[] = [];
    doctors: Doctor[] = [];
    specialtyOptions: Specialty[] = [];
    doctorOptions: Doctor[] = [];
    durationOptions = [
        { id: 5, name: '5 min' },
        { id: 10, name: '10 min' },
        { id: 15, name: '15 min' },
        { id: 20, name: '20 min' },
        { id: 25, name: '25 min' },
        { id: 30, name: '30 min' },
        { id: 40, name: '40 min' },
        { id: 45, name: '45 min' },
        { id: 60, name: '1 ' + this.translate.instant('hour') },
        { id: 70, name: '1 ' + this.translate.instant('hour') + ' 10 min' },
        { id: 90, name: '1 ' + this.translate.instant('hour') + ' 30 min' }
    ];
    dayOptions = [
        { id: 'Monday', name: this.translate.instant('Monday') },
        { id: 'Tuesday', name: this.translate.instant('Tuesday') },
        { id: 'Wednesday', name: this.translate.instant('Wednesday') },
        { id: 'Thursday', name: this.translate.instant('Thursday') },
        { id: 'Friday', name: this.translate.instant('Friday') },
        { id: 'Saturday', name: this.translate.instant('Saturday') },
        { id: 'Sunday', name: this.translate.instant('Sunday') },
    ];
    showAppointmentCreate: boolean = true;
    loadingSpecialties: boolean = false;
    loadingDoctors: boolean = false;
    selectedSpecialty: Specialty = null;
    selectedDoctor: Doctor = null;
    subscriptions: Subscription[] = [];

    get step_1() { return this.appointmentSlotFrom.get('step_1'); }
    get step_2_single() { return this.appointmentSlotFrom.get('step_2_single'); }
    get step_2_multiple() { return this.appointmentSlotFrom.get('step_2_multiple'); }

    /// TODO AI calculate settings

    constructor(
        private appointmentService: AppointmentService,
        private specialtyService: SpecialtyService,
        private doctorService: DoctorService,
        private modalService: ModalService,
        private translate: TranslateService,
        private formBuilder: FormBuilder,
        private activeModal: NgbActiveModal
    ) { }

    ngOnInit() {
        this.initForm();
        this.getSpecialties();
        this.getDoctors();
    }

    ngOnDestroy() {
        for (const subscription of this.subscriptions) {
          subscription.unsubscribe();
        }
    }

    initForm() {
        this.appointmentSlotFrom = this.formBuilder.group({
            step_1: this.formBuilder.group({
                specialty_id: [null, [Validators.required]],
                doctor_id: [null, [Validators.required]],
                type: ['default', [Validators.required]],
            }),
            step_2_single: this.formBuilder.group({
                generate: ['single', [Validators.required]],
                date: ['', [Validators.required]],
                time: ['', [Validators.required]],
                duration: [30, [Validators.required]]
            }),
            step_2_multiple: this.formBuilder.group({
                generate: ['multiple', [Validators.required]],
                date_from: ['', [Validators.required]],
                date_to: ['', [Validators.required]],
                time_from: ['', [Validators.required]],
                time_to: ['', [Validators.required]],
                week_days: ['', [Validators.required]],
                duration: [30, [Validators.required]]
            })
        });

        if (this.date) {
            this.appointmentSlotFrom.get('step_2_single.date')
                .setValue(this.date);
        }

        this.appointmentSlotFrom.get('step_1.specialty_id')
            .valueChanges
            .subscribe(
                (value) => {
                    this.selectedSpecialty = this.specialties.find(specialty => specialty.id === value);
                    if (value) {
                        this.doctorOptions = this.doctors.filter(
                            doctor => doctor.specialties.some(specialty => specialty.id === value)
                        );
                    } else {
                        this.doctorOptions = this.doctors;
                    }

                    // if (this.doctorOptions.length === 1) {
                    //     this.appointmentSlotFrom.get('step_1.doctor_id')
                    //         .setValue(this.doctorOptions[0].id);
                    // }
                }
            );

        this.appointmentSlotFrom.get('step_1.doctor_id')
            .valueChanges
            .subscribe(
                (value) => {
                    this.selectedDoctor = this.doctors.find(doctor => doctor.id === value);
                    if (value) {
                        this.specialtyOptions = this.specialties.filter(
                            specialty => specialty.doctors.some(doctor => doctor.id === value)
                        );
                    } else {
                        this.specialtyOptions = this.specialties;
                    }

                    // if (this.specialtyOptions.length === 1) {
                    //     this.appointmentSlotFrom.get('step_1.specialty_id')
                    //         .setValue(this.specialtyOptions[0].id);
                    // }
                }
            );
    }

    getSpecialties() {
        const params = {
            pagination: false,
            include: 'doctors'
        };
        this.loadingSpecialties = true;
        this.specialtyService
            .getInstitutionSpecialties(params)
            .subscribe(
                (response: any) => {
                    this.specialties = response.data;
                    this.specialtyOptions = response.data;
                    this.loadingSpecialties = false;

                    if (this.specialties.length === 1) {
                        this.appointmentSlotFrom.get('step_1.specialty_id')
                            .setValue(this.specialties[0].id);
                    }
                },
                () => {
                    this.loadingSpecialties = false;
                }
            );
    }

    getDoctors() {
        const params = {
            pagination: false,
            include: 'specialties'
        };
        this.loadingDoctors = true;
        this.doctorService
            .getDoctors(params)
            .subscribe(
                (response: any) => {
                    this.doctors = response.data;
                    this.doctorOptions = response.data;
                    this.loadingDoctors = false;

                    if (this.doctors.length === 1) {
                        this.appointmentSlotFrom.get('step_1.doctor_id')
                            .setValue(this.doctors[0].id);
                    }
                },
                () => {
                    this.loadingDoctors = false;
                }
            );
    }

    save() {
        let params: any;
        if (this.multi) {
            params = {
                ...this.appointmentSlotFrom.value.step_1,
                ...this.appointmentSlotFrom.value.step_2_multiple
            };
        } else {
            params = {
                ...this.appointmentSlotFrom.value.step_1,
                ...this.appointmentSlotFrom.value.step_2_single
            };
        }
        this.appointmentService
            .generateAppointmentSlots(params)
            .subscribe(
                (appointmentSlot) => {
                    this.activeModal.close({
                      showAppointmentCreate: this.showAppointmentCreate,
                      appointmentSlot: appointmentSlot.data
                    });
                },
                (error) => {
                    this.modalService.error({ errors: error });
                }
            );
    }

    back() {
        this.currentStep--;
    }

    next() {
        this.currentStep++;
    }

    close() {
        this.activeModal.dismiss();
    }

}
