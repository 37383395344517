import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OnInit, Component, Input } from '@angular/core';
import { PatientService } from '../../services/patient.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-patient-create-modal',
  templateUrl: './patient-create-modal.component.html',
})
export class PatientCreateModalComponent implements OnInit {
  @Input() name: string;
  patientForm: FormGroup;

  constructor(
    private patientService: PatientService,
    private formBuilder: FormBuilder,
    private activeModal: NgbActiveModal
  ) {}

  ngOnInit() {
    this.initForm();
    if (this.name) {
      const nameSegments = this.name.split(' ');
      this.patientForm.get('first_name').setValue(nameSegments[0]);
      if (nameSegments.length > 1) {
        this.patientForm.get('last_name').setValue(nameSegments[1]);
      }
    }
  }

  initForm() {
    this.patientForm = this.formBuilder.group({
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      parent_name: [''],
      date_of_birth: [''],
      phone_1: [''],
      phone_2: [''],
      email: ['', [Validators.email]],
  });
  }

  save() {
    this.patientService
        .createPatient(this.patientForm.value)
        .subscribe(
            (response) => {
                this.activeModal.close(response.data);
            },
            () => { }
        );
  }

  close() {
    this.activeModal.dismiss();
  }
}
