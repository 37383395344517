export class Therapies {
  id: number;
  therapy_id: number;
  code: string;
  name: string;
  description: string;
  inn: string;
  therapy: Therapies;

  constructor(values: object = {}) {
    Object.assign(this, values);
  }
}
