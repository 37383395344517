import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DCOffClickDirective } from './off-click.directive';



@NgModule({
  declarations: [
    DCOffClickDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    DCOffClickDirective
  ]
})
export class DCOffClickModule { }
