import { Injectable } from '@angular/core';
import { ApiService } from "./api.service";

@Injectable({
  providedIn: 'root'
})
export class TherapyService {

  constructor(
    private apiService: ApiService
  ) { }

  getTherapies(query: any = {}) {
    return this.apiService.get(`therapy`, query);
  }
}
