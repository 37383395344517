import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { ModalButton } from '../_classes/button';
import { ModalErrors } from '../_classes/modal-errors';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'dc-error',
  templateUrl: './error.component.html'
})
export class DCErrorComponent implements OnInit {
  @ViewChild('focusElement', {static: true}) focusElement: ElementRef;
  errorMessage: string;
  formattedErrors: Array<string> = [];

  @Input() title: string = 'Error';
  @Input() buttons: ModalButton[];
  @Input() showTitle: boolean = true;
  @Input() size: 'small' | 'medium' | 'large' | 'extra-large' = 'small';

  @Input()
  set errors(errorList: ModalErrors) {
    this.errorMessage = errorList.error.message;
    this.formattedErrors = Object.keys(errorList.error.errors || {})
        .map(key => `${errorList.error.errors[key]}`);
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key && event.key.toLowerCase() === 'enter') {
      event.preventDefault();
      return false;
    }
  }

  constructor(
      public activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    if (!this.buttons) {
      this.buttons = [
        {
          text: 'Close',
          role: 'cancel'
        }
      ];
    }

    setTimeout(() => {
      this.focusElement.nativeElement.focus();
    }, 500);
  }

  close() {
    this.activeModal.dismiss();
  }

  onClick(button) {
    if (button.role === 'cancel') {
      this.activeModal.dismiss();
    }
    if (button.handler) {
      button.handler();
      this.activeModal.close();
    }
  }

}
