<div class="dc-modal medium">
  <div class="dc-modal__header">
    <h2 class="dc-modal__title">{{ 'Edit comment' | translate }}</h2>
    <span class="dc-modal__close" (click)="close()"></span>
  </div>
  <div class="dc-modal__body" [dcLoading]="showLoading">
    <form [formGroup]="appointmentCommentFrom">
      <div class="dc-row">
        <div class="dc-col">
          <dc-input
            type="textarea"
            formControlName="comment"
            [label]="'Comment' | translate"
          ></dc-input>
        </div>
      </div>
    </form>
  </div>
  <div class="dc-modal__actions">
    <div class="dc-modal__action-left">
      <dc-button color="outline" (click)="close()">{{ 'Cancel' | translate }}</dc-button>
    </div>
    <div class="dc-modal__action-right">
      <dc-button (click)="editComment()" [disabled]="appointmentCommentFrom.invalid">{{ 'Edit comment' | translate }}</dc-button>
    </div>
  </div>
</div>
